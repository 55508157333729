import React, {useState} from 'react';

// RTK queries
import {skipToken} from '@reduxjs/toolkit/dist/query';
import {useGetSessionQuery} from '@compt/app/services/api/api-slice';
import {useGetCompanyQuery} from '@compt/app/services/api/company-slice';
import {useGetEmployeeFaqQuery} from '@compt/app/services/api/employee-faq-slice';

// Hooks and methods
import {useHasRequiredRole} from '@compt/utils/permission-helpers';

// Types
import {USER_ROLES} from '@compt/utils/user-roles-helper';

// Components
import {ComptPage} from '@compt/common/compt-page/compt-page';
import {FaqItem} from '@compt/pages/employee-faq-page/faq-item';
import {ComptLoadingIndicator} from '@compt/common/compt-loading/compt-loading';
import {Error404Page} from '@compt/pages/404-error-page/error-404-page';

export const EmployeeFaqPage = () => {
  const [openIndex, setOpenIndex] = useState<number | null>(null);

  const hasRequiredRole = useHasRequiredRole([
    USER_ROLES.stipendEligible,
    USER_ROLES.businessExpenseEligible,
  ]);

  const session = useGetSessionQuery();
  const userId = session.data?.user_id;

  const companyQuery = useGetCompanyQuery(userId ?? skipToken);
  const customReimbursementInfo = companyQuery.data?.reimbursement_info;

  const employeeFaqQuery = useGetEmployeeFaqQuery();
  const employeeFaqs = employeeFaqQuery.data;

  const DEFAULT_REIMBURSEMENT_ANSWER = `Once your expenses are reviewed and processed by your company,
    you'll be reimbursed directly in an upcoming paycheck. You can typically expect to be
    reimbursed for an expense that you submit in the month following your submission.`;

  const reimbursementInfo = {
    id: -1,
    question: 'When can I expect to get reimbursed?',
    answer: customReimbursementInfo || DEFAULT_REIMBURSEMENT_ANSWER,
    order: 0,
  };

  if (!hasRequiredRole) {
    return <Error404Page />;
  }

  function handleAccordionChange(state: number) {
    if (state === openIndex) {
      return setOpenIndex(null);
    }
    setOpenIndex(state);
  }

  return (
    <ComptPage
      title="FAQs"
      subtitle="Answers to frequently asked questions"
      className="h-full"
      includeBottomHR={true}
      bottomHRClassName="mb-0 mt-400 sm:mt-000"
    >
      <ComptLoadingIndicator
        isLoading={session.isLoading || companyQuery.isLoading || employeeFaqQuery.isLoading}
      >
        <FaqItem
          id={0}
          faq={reimbursementInfo}
          openIndex={openIndex}
          handleAccordionChange={handleAccordionChange}
        />
        {companyQuery.data &&
          employeeFaqQuery.data?.length !== 0 &&
          employeeFaqs?.map((faq) => (
            <FaqItem
              key={faq.id}
              id={faq.id}
              faq={faq}
              openIndex={openIndex}
              handleAccordionChange={handleAccordionChange}
            />
          ))}
      </ComptLoadingIndicator>
    </ComptPage>
  );
};

import React, {Dispatch, ReactNode, SetStateAction} from 'react';

// Hooks and Methods
import {useFilterContext} from './filter-context';

// Components
import {ComptButton, ComptButtonSize, ComptButtonType} from '../compt-button/compt-button';
import {ComptSvgIcon} from '../compt-svg-icon/compt-svg-icon';

export type FiltersState = Record<string, string[] | string>;

interface FilterSidebarProps {
  isCollapsed?: boolean;
  setIsCollapsed?: Dispatch<SetStateAction<boolean>>;
  disableCollapse?: boolean;
  doNotClear?: string[];
  children: ReactNode;
}

export const FilterSideBar = ({doNotClear = [], ...props}: FilterSidebarProps) => {
  const {filters, setFilters, showRefreshButton, setShowRefreshButton} = useFilterContext();

  // Omit filters we don't want to clear
  function getClearableFilters<T extends FiltersState>(filters: T) {
    const doNotClearFilters = [...doNotClear, 'search'];

    return Object.fromEntries(
      Object.entries(filters).filter(([key]) => !doNotClearFilters?.includes(key)),
    );
  }

  function clearAllFilters<T extends FiltersState>(
    setFilters: (updater: (prevState: T) => T) => void,
  ) {
    setFilters((prevState) => {
      const clearableFilters = getClearableFilters(prevState);

      const clearedFilters = Object.keys(clearableFilters).reduce((acc, key) => {
        const currentValue = clearableFilters[key];
        if (Array.isArray(currentValue)) {
          (acc as FiltersState)[key] = [];
        } else if (typeof currentValue === 'string') {
          (acc as FiltersState)[key] = '';
        }
        return acc;
      }, {} as T);

      return {...prevState, ...clearedFilters};
    });
  }

  function showClearAllButton<T extends FiltersState>(filters: T): boolean {
    const clearableFilters = getClearableFilters(filters);
    return Object.values(clearableFilters).some((values) => values.length > 0);
  }

  if (props.isCollapsed && !props.disableCollapse)
    return (
      <div
        className={`bg-white
          h-full border-l border-stroke-divider1 flex flex-col overflow-y-auto transition-all
          w-[60px] min-w-[60px] cursor-pointer
        `}
        onClick={() => {
          if (props.setIsCollapsed) {
            props.setIsCollapsed(false);
          }
        }}
      >
        <div className="flex justify-center items-start h-full mt-4 bg-white">
          <ComptSvgIcon iconName="filter-lines-blue-icon" />
        </div>
      </div>
    );

  return (
    <div
      className="h-full border-l border-stroke-divider1 flex bg-white
            flex-col overflow-y-auto transition-all w-[280px] min-w-[280px]
            justify-between"
    >
      <div className="px-4 pt-4">
        <div className="flex flex-col bg-white">
          <div className="flex-col">
            <div className="flex justify-between items-center">
              <p className="text-md text-color-body1 font-bold">Filter</p>
              {showClearAllButton(filters) && (
                <ComptButton
                  buttonType={ComptButtonType.OUTLINED}
                  onClick={() => {
                    clearAllFilters(setFilters);
                    setShowRefreshButton && setShowRefreshButton(false);
                  }}
                  size={ComptButtonSize.SMALL}
                  className="py-1 px-0 min-w-[70px]"
                >
                  Clear all
                </ComptButton>
              )}
            </div>
            {showRefreshButton && (
              <>
                <p className="text-sm text-color-body2 mt-4 mb-2">
                  Refresh your page to reapply filters.
                </p>
                <ComptButton
                  size={ComptButtonSize.SMALL}
                  buttonType={ComptButtonType.SECONDARY}
                  onClick={async () => {
                    await window.location.reload();
                    setShowRefreshButton && setShowRefreshButton(false);
                  }}
                  className="min-w-[80px]"
                >
                  Refresh
                </ComptButton>
              </>
            )}
          </div>
        </div>
        <div className="flex flex-col mt-4 space-y-2">{props.children}</div>
      </div>
      {!props.disableCollapse && (
        <div className="sticky w-full bottom-0 bg-white border-t px-4 py-2">
          <button
            className="mt-auto py-2 text-md text-left font-medium text-primary"
            onClick={() => {
              if (props.setIsCollapsed) {
                props.setIsCollapsed(true);
              }
            }}
          >
            Hide filters
          </button>
        </div>
      )}
    </div>
  );
};

export function handleSingleOptionSelection<T extends FiltersState>(
  filterKey: keyof T,
  isChecked: boolean,
  setFilters: (updater: (prevState: T) => T) => void,
  filterValue = 'true',
) {
  setFilters((prevState) => {
    if (typeof prevState[filterKey] !== 'string') return {...prevState};

    const updatedFilterValues = isChecked ? filterValue : '';

    return {...prevState, [filterKey]: updatedFilterValues};
  });
}

export enum STATUS_KEYS {
  'Open' = 'Open',
  'Approved' = 'Approved',
  'Rejected' = 'Rejected',
  'In_Review' = 'In review',
  'Processed' = 'Processed',
}

type StatusFormatItems = {
  statusName: string;
  statusStyle: string;
};

export const StatusFormats: Record<STATUS_KEYS, StatusFormatItems> = {
  Open: {
    statusName: 'Open',
    statusStyle: 'stipend-expense-open',
  },
  Approved: {
    statusName: 'Approved',
    statusStyle: 'stipend-expense-approved',
  },
  Rejected: {
    statusName: 'Rejected',
    statusStyle: 'stipend-expense-rejected',
  },
  'In review': {
    statusName: 'In review',
    statusStyle: 'stipend-expense-in-review',
  },
  Processed: {
    statusName: 'Processed',
    statusStyle: 'stipend-expense-processed',
  },
};

export const stipendExpenseStatusStrings: {[key: number]: STATUS_KEYS} = {
  1: STATUS_KEYS.Open,
  2: STATUS_KEYS.Approved,
  3: STATUS_KEYS.Rejected,
  4: STATUS_KEYS.In_Review,
  6: STATUS_KEYS.Processed,
};

export function getStatusNumber(status: STATUS_KEYS): number | undefined {
  const entry = Object.entries(stipendExpenseStatusStrings).find(([, value]) => value === status);
  return entry ? Number(entry[0]) : undefined;
}

export const statusMap = (statusCode: number): STATUS_KEYS => {
  const code = statusCode as keyof typeof stipendExpenseStatusStrings;

  return stipendExpenseStatusStrings[code];
};

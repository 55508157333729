import React, {useState} from 'react';

// Hooks and methods
import {useFilterContext} from '@compt/common/compt-filter-sidebar/filter-context';

// Types
import {
  BusinessExpenseReportStatus,
  expenseReportStatusFormats,
} from '@compt/types/business-expenses/business-expense-report-statuses';

// Components
import {MultiOptionCollapsible} from '@compt/common/compt-filter-sidebar/multi-option-collapsible';
import {FilterSideBar} from '@compt/common/compt-filter-sidebar/compt-filter-sidebar';
import {ComptPortal, PortalRoot} from '@compt/common/compt-portal/compt-portal';
import {ComptDateRangePicker} from '@compt/common/compt-date-range-picker/compt-date-range-picker';

export enum BusinessExpenseReportFilter {
  SEARCH = 'title__icontains',
  STATUS = 'status__in',
  SUBMITTED_START_DATE = 'submitted_date_after',
  SUBMITTED_END_DATE = 'submitted_date_before',
}

export const ReviewBusinessExpenseReportSidebar = () => {
  const {filters, setFilters} = useFilterContext();

  const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(false);

  const statusOptions = Object.keys(BusinessExpenseReportStatus)
    .filter((key) => key !== BusinessExpenseReportStatus.DRAFT) // Hide draft filter from admins
    .map((key) => {
      const id = BusinessExpenseReportStatus[key as keyof typeof BusinessExpenseReportStatus];
      const name = expenseReportStatusFormats[id].statusName;
      return {id, name};
    });

  return (
    <>
      {/* Date filter next to review tabs */}
      <ComptPortal portalNode={PortalRoot.DATE_RANGE}>
        <ComptDateRangePicker
          label="Date range:"
          initialStartDate={filters[BusinessExpenseReportFilter.SUBMITTED_START_DATE] as string}
          initialEndDate={filters[BusinessExpenseReportFilter.SUBMITTED_END_DATE] as string}
          handleChange={(start, end) => {
            setFilters((prevState) => {
              const newFilterValues = {
                [BusinessExpenseReportFilter.SUBMITTED_START_DATE]: start,
                [BusinessExpenseReportFilter.SUBMITTED_END_DATE]: end,
              };

              return {...prevState, ...newFilterValues};
            });
          }}
        />
      </ComptPortal>
      <FilterSideBar
        isCollapsed={isSidebarCollapsed}
        setIsCollapsed={setIsSidebarCollapsed}
        doNotClear={[
          BusinessExpenseReportFilter.SUBMITTED_START_DATE,
          BusinessExpenseReportFilter.SUBMITTED_END_DATE,
          BusinessExpenseReportFilter.SEARCH,
        ]}
      >
        <MultiOptionCollapsible
          id="review-business-expense-report-status"
          label="Status"
          filterKey={BusinessExpenseReportFilter.STATUS}
          options={statusOptions}
          getOptionKey={(option) => `${option.id}`}
          getOptionLabel={(option) => option.name}
        />
      </FilterSideBar>
    </>
  );
};

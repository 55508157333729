/**
 * Helpers for the learning forms.
 *
 * Keywords: L&D, Learning, PD, Professional Development
 */
import {EmployeePreApprovalRequestPayload} from '@compt/types/learning-development/pre-approval-request';
import {
  cleanSupportingDocumentFileKeys,
  isObjectWithSupportingDocument,
} from '@compt/utils/file-helpers';
import {
  DEFAULT_SUPPORTING_DOCS_ALLOWED,
  RequestDataFieldKeys,
} from '@compt/types/learning-development/learning-type-consts';
import {LearningRequestStatus} from '@compt/types/learning-development/learning-request-status';

import {RequestFormFieldValues} from '@compt/pages/learning-page/components/request-form-side-panel/request-form-side-panel';

export const cleanSubmission = (
  programId: number,
  formValues: RequestFormFieldValues,
  status: LearningRequestStatus = LearningRequestStatus.PRE_APPROVAL_REQUESTED,
): EmployeePreApprovalRequestPayload => {
  if (!formValues['Expected completion date']) {
    throw Error('Expected completion date is required.');
  }

  const {receipt_key, ...restOfFields} = formValues;

  let supportingDocumentsFileKeys: string[] = [];
  let cleanedRestOfFields: typeof restOfFields | null = null;

  if (isObjectWithSupportingDocument(restOfFields)) {
    const result = cleanSupportingDocumentFileKeys(restOfFields, DEFAULT_SUPPORTING_DOCS_ALLOWED);
    supportingDocumentsFileKeys = result.fileKeys;
    cleanedRestOfFields = result.cleanedObject as typeof restOfFields;
  }

  return {
    program_id: programId,
    status: status,
    request_data: {
      ...(cleanedRestOfFields ? cleanedRestOfFields : restOfFields),
      'Upload document(s)': receipt_key,
      'Expected completion date': formValues['Expected completion date'],
      [RequestDataFieldKeys.SupportingDocuments]: supportingDocumentsFileKeys,
    },
  };
};

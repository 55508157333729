import {useCallback, useEffect, useMemo, useRef, useState} from 'react';

// RTK queries
import {
  useBulkUpdateExpenseReviewMutation,
  useGetExpenseReviewsQuery,
} from '@compt/app/services/api/admin-stipends-slice';
import {useGetSessionQuery} from '@compt/app/services/api/api-slice';
import {useGetCompanyQuery} from '@compt/app/services/api/company-slice';
import {skipToken} from '@reduxjs/toolkit/dist/query';

// Hooks and methods
import {useFilterContext} from '@compt/common/compt-filter-sidebar/filter-context';
import {useNoDataText} from '@compt/common/compt-filter-sidebar/no-data-helper';
import {triggerCustomToast} from '@compt/common/compt-toaster/compt-toaster';
import {useLeanComptTableContext} from '@compt/common/lean-compt-table/lean-compt-table-context';
import {useIsMobileView} from '@compt/utils/mobile-helpers';
import {useDebounce} from '@uidotdev/usehooks';
import {useSearchParams} from 'react-router-dom';
import {useFormatFilterQuery, usePaginationQuery} from '../../format-query-helper';
import {ReviewStipendTableController as controller} from './review-stipend-table.controller';

// Types
import {MAX_50_PAGE_LIMIT} from '@compt/constants';
import comptColors from '@compt/styles/compt-colors';
import {ExpenseStatus} from '@compt/types/stipend-expense';
import {ExpenseReview} from '@compt/types/stipend-expenses/expense-review';
import {getStatusNumber, STATUS_KEYS} from '@compt/types/stipend-expenses/stipend-expenses';
import {StipendFilter} from './review-stipend-table-sidebar';

// Components
import {ColumnSelectSearchFilter} from '@compt/common/compt-filter-bar/compt-column-select-search-filter';
import {ComptTableBar} from '@compt/common/compt-table/compt-table-bar';
import {LeanComptTable} from '@compt/common/lean-compt-table/lean-compt-table';
import {ReviewStipendSidePanel} from './review-stipend-side-panel';
import {TableFloatingButton} from './table-floating-button';

export const ReviewStipendTable = () => {
  const isMobileView = useIsMobileView();

  const tableRef = useRef(null);
  const mobilePaginationRef = useRef<HTMLDivElement>(null);
  const desktopPaginationRef = useRef<HTMLDivElement>(null);

  const session = useGetSessionQuery();
  const companyQuery = useGetCompanyQuery(session.data?.user_id ?? skipToken);

  const [searchParams, setSearchParams] = useSearchParams();
  const {setCurrentPage} = useLeanComptTableContext();
  const formatFilterQuery = useFormatFilterQuery(setCurrentPage);
  const paginationQuery = usePaginationQuery(MAX_50_PAGE_LIMIT);
  const debouncedQueryValues = useDebounce(formatFilterQuery, 300);
  const {setShowRefreshButton, filters} = useFilterContext();
  const [bulkUpdateExpenseReview] = useBulkUpdateExpenseReviewMutation();
  const expenseReviewsQuery = useGetExpenseReviewsQuery(
    {
      companyId: companyQuery.data?.id,
      filter: {
        ...debouncedQueryValues,
        ...paginationQuery,
        limit: MAX_50_PAGE_LIMIT,
        ordering: 'created_on',
      },
    },
    {skip: !companyQuery?.data?.id || !formatFilterQuery},
  );

  const [selectedRows, setSelectedRows] = useState<ExpenseReview[]>([]);
  const [selectionKey, setSelectionKey] = useState(0);

  const noDataText = useNoDataText(
    'No expense reviews exist yet.',
    'No expense reviews were found with the current filters.',
  );

  const statusFiltersApplied = useMemo(() => filters[StipendFilter.STATUS]?.length > 0, [filters]);

  const expenseReviewIdList = expenseReviewsQuery.data?.results.map((result) => result.id);

  const columnOptions = controller.getColumnOptions();

  const conditionalRowStyles = [
    {
      when: (expense: ExpenseReview) =>
        expense.status === ExpenseStatus.Approved ||
        expense.status === ExpenseStatus.Processed ||
        expense.status === ExpenseStatus.Rejected,
      style: {
        backgroundColor: comptColors.gray['100'],
        color: 'black',
      },
    },
  ];

  const handleRowSelected = useCallback(({selectedRows}: {selectedRows: unknown}) => {
    setSelectedRows(selectedRows as ExpenseReview[]);
  }, []);

  const handleApproveSelected = useCallback(() => {
    const rowsToApprove = [...selectedRows];
    if (rowsToApprove.length === 0) return;

    const stipendExpenses: Record<string, number> = {};
    rowsToApprove.forEach((expense) => {
      stipendExpenses[expense.id.toString()] = expense.allotment_id;
    });

    const payload = {
      status: getStatusNumber(STATUS_KEYS.Approved),
      stipend_expenses: stipendExpenses,
    };

    bulkUpdateExpenseReview({
      companyId: companyQuery.data?.id,
      body: payload,
      filter: {
        ...debouncedQueryValues,
        ...paginationQuery,
        limit: MAX_50_PAGE_LIMIT,
        ordering: 'created_on',
      },
    }).then((results) => {
      if ('error' in results) {
        if ('data' in results.error) {
          triggerCustomToast(
            'error',
            'There was a problem updating the expenses',
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            results.error.data?.errors.join(' '),
          );
        } else {
          triggerCustomToast('error', 'There was a problem updating the expenses');
        }
        console.error('Error updating the expenses', results.error);
        return;
      }

      if (statusFiltersApplied) setShowRefreshButton(true);
      triggerCustomToast('success', 'Successfully updated the expenses');

      setSelectedRows([]);
      setSelectionKey((prev) => prev + 1);
    });
  }, [
    bulkUpdateExpenseReview,
    companyQuery.data?.id,
    debouncedQueryValues,
    paginationQuery,
    setShowRefreshButton,
    selectedRows,
  ]);

  useEffect(() => {
    if (expenseReviewsQuery.isSuccess && expenseReviewsQuery.data) {
      const currentIds = new Set(expenseReviewsQuery.data.results.map((row) => row.id));
      const stillExist = selectedRows.filter((row) => currentIds.has(row.id));
      if (stillExist.length !== selectedRows.length) {
        setSelectedRows(stillExist);
      }
    }
  }, [expenseReviewsQuery.data, expenseReviewsQuery.isSuccess]);

  return (
    <>
      <ReviewStipendSidePanel
        expenseId={searchParams.get('expense_id')}
        expenseReviewIdList={expenseReviewIdList ?? []}
      />
      <div ref={tableRef}>
        <LeanComptTable
          key={`table-${selectionKey}`} // Force re-render when selection is cleared
          data={expenseReviewsQuery.data?.results || []}
          isDataLoading={expenseReviewsQuery.isFetching || expenseReviewsQuery.isLoading}
          totalCount={expenseReviewsQuery.data?.count ?? 0}
          itemsPerPage={MAX_50_PAGE_LIMIT}
          noDataTitleText={noDataText.title}
          onRowClicked={(row: ExpenseReview) => {
            setSearchParams({expense_id: `${row.id}`});
          }}
          conditionalRowStyles={conditionalRowStyles}
          showPagination
          selectableRows
          selectableRowDisabled={(row: ExpenseReview) =>
            row.status === ExpenseStatus.Approved || row.status === ExpenseStatus.Rejected
          }
          onSelectedRowsChange={handleRowSelected}
          clearSelectedRows={expenseReviewsQuery.isFetching || expenseReviewsQuery.isLoading}
          mobilePaginationRef={mobilePaginationRef}
          desktopPaginationRef={desktopPaginationRef}
        >
          <ComptTableBar showColumnFilter>
            {!isMobileView && (
              <ColumnSelectSearchFilter
                searchFilterKey={StipendFilter.SEARCH}
                columnFilterKey={StipendFilter.COLUMN_FILTER}
                options={columnOptions}
                getKey={(option) => option?.id}
                getDisplayText={(option) => option?.label}
              />
            )}
          </ComptTableBar>
        </LeanComptTable>
      </div>
      <TableFloatingButton
        selectedCount={selectedRows.length}
        onApprove={handleApproveSelected}
        tableRef={tableRef}
        mobilePaginationRef={mobilePaginationRef}
        desktopPaginationRef={desktopPaginationRef}
      />
    </>
  );
};

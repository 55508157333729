import {useHasRequiredRole} from './permission-helpers';
import {USER_ROLES} from './user-roles-helper';

export function useGetHomePath() {
  const isStipendEligible = useHasRequiredRole([USER_ROLES.stipendEligible]);
  const isBusinessExpenseEligible = useHasRequiredRole([USER_ROLES.businessExpenseEligible]);

  // Prioritize stipend eligible module when rerouting
  if (isStipendEligible) {
    return '/';
  }

  if (isBusinessExpenseEligible) {
    return '/business-expenses';
  }

  // Default to stipend eligible
  return '/';
}

import React from 'react';

// Hooks and methods
import {twMerge} from 'tailwind-merge';

// Types
import {ReviewTabType} from '@compt/types/stipend-expenses/expense-review';

interface ReviewCardProps {
  activeTab: ReviewTabType;
  setActiveTab?: React.Dispatch<React.SetStateAction<ReviewTabType>>;
  showActiveTab: boolean;
}

export const ReviewScoreButton = ({activeTab, setActiveTab, showActiveTab}: ReviewCardProps) => (
  <button
    onClick={setActiveTab ? () => setActiveTab(activeTab) : () => {}}
    className={twMerge(
      'px-2 py-1 rounded-[100px] border border-stroke-divider-2 mr-1',
      showActiveTab ? 'bg-gray-200 shadow-sm' : 'text-color-body2',
    )}
  >
    <p className="label4 text-color-body1 font-weight-bold">{activeTab}</p>
  </button>
);

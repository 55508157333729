import {Dispatch, SetStateAction, useMemo} from 'react';
import {useFilterContext} from '@compt/common/compt-filter-sidebar/filter-context';
import {useLeanComptTableContext} from '@compt/common/lean-compt-table/lean-compt-table-context';

/**
 * Converts filter values into query parameters.
 *
 * Retrieves the filters from context and creates an object where:
 * - Array values are joined with commas.
 * - Only non-empty strings are included.
 *
 * Also resets the current page to 1 whenever filters change.
 *
 * @param setCurrentPage - Function to update the current page (typically from useState).
 * @returns An object with filter names as keys and their stringified values.
 *
 * @example
 * const queryParams = useFormatFilterQuery(setCurrentPage);
 * // For filters { category: ['books', 'music'], search: 'novel' },
 * // returns { category: 'books,music', search: 'novel' }
 */
export const useFormatFilterQuery = (
  setCurrentPage?: Dispatch<SetStateAction<number>>,
): Record<string, string> => {
  const {filters} = useFilterContext();

  return useMemo(() => {
    const filterToSend: {
      [key: string]: string;
    } = {};

    Object.keys(filters).forEach((key) => {
      const currentValue = filters[key];
      if (Array.isArray(currentValue) && currentValue.length > 0) {
        filterToSend[key] = currentValue.join(',');
      }

      if (typeof currentValue === 'string' && currentValue.trim() !== '') {
        filterToSend[key] = currentValue;
      }

      if (setCurrentPage) setCurrentPage(1);
    });

    return filterToSend;
  }, [filters]);
};

/**
 * Computes pagination query parameters based on the current page and the provided offset limit.
 *
 * @param {number} offsetLimit - The multiplier used to calculate the offset, usually number of items per page.
 * @returns {object} An object containing:
 *   - `offset`: The computed offset for the query, calculated as `offsetLimit * Math.max(currentPage - 1, 0)`.
 *   - `page`: The current page number obtained from the context.
 */
export const usePaginationQuery = (offsetLimit: number): object => {
  const {currentPage} = useLeanComptTableContext();

  return useMemo(
    () => ({offset: offsetLimit * Math.max(currentPage - 1, 0), page: currentPage}),
    [currentPage],
  );
};

import React, {ReactNode} from 'react';

// Hooks and methods
import {useDateRangePickerContext} from './date-range-picker-context';

enum QuickSelect {
  'TODAY',
  'YESTERDAY',
  'THIS_WEEK',
  'LAST_WEEK',
  'THIS_MONTH',
  'LAST_MONTH',
  'THIS_YEAR',
  'LAST_YEAR',
  'ALL_TIME',
}

export const DateQuickSelect = () => {
  const {setStartDate, setEndDate} = useDateRangePickerContext();

  function handleQuickSelect(selection: QuickSelect) {
    const now = new Date();
    switch (selection) {
      case QuickSelect.TODAY:
        setStartDate(now);
        setEndDate(now);
        break;
      case QuickSelect.YESTERDAY: {
        const yesterday = new Date(now);
        yesterday.setDate(yesterday.getDate() - 1);
        setStartDate(yesterday);
        setEndDate(yesterday);
        break;
      }
      case QuickSelect.THIS_WEEK: {
        const dayOfWeek = now.getDay();
        const start = new Date(now);
        start.setDate(now.getDate() - dayOfWeek);
        const end = new Date(start);
        end.setDate(start.getDate() + 6);
        setStartDate(start);
        setEndDate(end);
        break;
      }
      case QuickSelect.LAST_WEEK: {
        const dayOfWeek = now.getDay();
        const currentWeekStart = new Date(now);
        currentWeekStart.setDate(now.getDate() - dayOfWeek);
        const lastWeekStart = new Date(currentWeekStart);
        lastWeekStart.setDate(currentWeekStart.getDate() - 7);
        const lastWeekEnd = new Date(lastWeekStart);
        lastWeekEnd.setDate(lastWeekStart.getDate() + 6);
        setStartDate(lastWeekStart);
        setEndDate(lastWeekEnd);
        break;
      }
      case QuickSelect.THIS_MONTH: {
        const start = new Date(now.getFullYear(), now.getMonth(), 1);
        const end = new Date(now.getFullYear(), now.getMonth() + 1, 0);
        setStartDate(start);
        setEndDate(end);
        break;
      }
      case QuickSelect.LAST_MONTH: {
        const start = new Date(now.getFullYear(), now.getMonth() - 1, 1);
        const end = new Date(now.getFullYear(), now.getMonth(), 0);
        setStartDate(start);
        setEndDate(end);
        break;
      }
      case QuickSelect.THIS_YEAR: {
        const start = new Date(now.getFullYear(), 0, 1);
        const end = new Date(now.getFullYear(), 11, 31);
        setStartDate(start);
        setEndDate(end);
        break;
      }
      case QuickSelect.LAST_YEAR: {
        const lastYear = now.getFullYear() - 1;
        const start = new Date(lastYear, 0, 1);
        const end = new Date(lastYear, 11, 31);
        setStartDate(start);
        setEndDate(end);
        break;
      }
      case QuickSelect.ALL_TIME: {
        setStartDate(null);
        setEndDate(null);
        break;
      }
      default:
        break;
    }
  }
  return (
    <div>
      <h1 className="heading6 text-color-tertiary mb-1">QUICK SELECT</h1>
      <div className="flex flex-col gap-y-2">
        <QuickSelectButton onClick={() => handleQuickSelect(QuickSelect.TODAY)}>
          Today
        </QuickSelectButton>
        <QuickSelectButton onClick={() => handleQuickSelect(QuickSelect.YESTERDAY)}>
          Yesterday
        </QuickSelectButton>
        <QuickSelectButton onClick={() => handleQuickSelect(QuickSelect.THIS_WEEK)}>
          This week
        </QuickSelectButton>
        <QuickSelectButton onClick={() => handleQuickSelect(QuickSelect.LAST_WEEK)}>
          Last week
        </QuickSelectButton>
        <QuickSelectButton onClick={() => handleQuickSelect(QuickSelect.THIS_MONTH)}>
          This month
        </QuickSelectButton>
        <QuickSelectButton onClick={() => handleQuickSelect(QuickSelect.LAST_MONTH)}>
          Last month
        </QuickSelectButton>
        <QuickSelectButton onClick={() => handleQuickSelect(QuickSelect.THIS_YEAR)}>
          This year
        </QuickSelectButton>
        <QuickSelectButton onClick={() => handleQuickSelect(QuickSelect.LAST_YEAR)}>
          Last year
        </QuickSelectButton>
        <QuickSelectButton onClick={() => handleQuickSelect(QuickSelect.ALL_TIME)}>
          All time
        </QuickSelectButton>
      </div>
    </div>
  );
};

interface ButtonProps {
  onClick: () => void;
  children: ReactNode;
}

const QuickSelectButton = (props: ButtonProps) => (
  <button
    className="label4 text-color-body1 flex py-2 hover:text-color-tertiary"
    onClick={props.onClick}
  >
    {props.children}
  </button>
);

import React, {useState} from 'react';

// Hooks and methods
import {
  Control,
  Controller,
  ControllerRenderProps,
  FieldPathByValue,
  FieldValues,
  UseFormClearErrors,
  UseFormGetValues,
  UseFormSetError,
} from 'react-hook-form';
import {twMerge} from 'tailwind-merge';

// Types
import {ComptFormControlFieldBaseProps} from '@compt/types/form/compt-forms';
import {ComptTagInputType} from '@compt/utils/compt-tag-input-helper';

// Components
import {ComptSvgIcon} from '@compt/common/compt-svg-icon/compt-svg-icon';
import {ComptFormField} from '@compt/common/forms/compt-form-field/compt-form-field';

export interface ComptTagInputProps<
  TFieldValues extends FieldValues,
  TName extends FieldPathByValue<TFieldValues, string>,
> extends ComptFormControlFieldBaseProps<string, TFieldValues, TName> {
  'data-testid'?: string;
  name: TName;
  label?: string;
  helperText?: string;
  errors: FieldValues | undefined;
  setError: UseFormSetError<TFieldValues>;
  clearError: UseFormClearErrors<TFieldValues>;
  getValues: UseFormGetValues<TFieldValues>;
  disabled?: boolean;
  control?: Control<TFieldValues>;
  tagType?: ComptTagInputType;
  headingText?: string;
  separator?: string;
  maxTags?: number;
}

export const ComptKeywordInput = <
  TFieldValues extends FieldValues,
  TName extends FieldPathByValue<TFieldValues, string>,
>(
  props: ComptTagInputProps<TFieldValues, TName>,
) => {
  const [currentInput, setCurrentInput] = useState<string>('');

  const tagType = ComptTagInputType.KEYWORD;

  const separator = props.separator || ',';

  const headingText = props.headingText || `Added ${tagType}s`;
  const {maxTags} = props;

  const handleAddTag = (field: ControllerRenderProps<TFieldValues, TName>) => {
    const currentTags = props.getValues(props.name) || [];
    const trimmedInput = currentInput.trim();

    if (!trimmedInput) {
      props.setError(props.name, {
        type: 'manual',
        message: `Please enter a ${tagType}.`,
      });
      return;
    }

    // Check if we've reached the maximum allowed tags
    if (maxTags && currentTags.length >= maxTags) {
      props.setError(props.name, {
        type: 'manual',
        message: `Maximum of ${maxTags} ${tagType}s allowed.`,
      });
      return;
    }

    // Split by commas
    const newTags = [...new Set(trimmedInput.split(separator).map((tag) => tag.trim()))];

    const duplicateTags: string[] = [];

    const validTags = newTags.filter((tag) => {
      if (!tag) {
        props.setError(props.name, {
          type: 'manual',
          message: `Empty ${tagType}s are not allowed.`,
        });
        return false;
      }

      // Check for duplicates in current tags
      if (currentTags.some((existing: string) => existing.toLowerCase() === tag.toLowerCase())) {
        duplicateTags.push(tag);
        return false;
      }

      return true;
    });

    if (duplicateTags.length > 0) {
      props.setError(props.name, {
        type: 'manual',
        message: `Duplicate ${
          duplicateTags.length > 1 ? `${tagType}s` : tagType
        }: "${duplicateTags.join('", "')}"`,
      });
    }

    if (validTags.length > 0) {
      const updatedTags = [...currentTags, ...validTags];
      field.onChange(updatedTags);
      setCurrentInput('');
      props.clearError(props.name);
    }
  };

  const handleRemoveTag = (
    tagToRemove: string,
    field: ControllerRenderProps<TFieldValues, TName>,
  ) => {
    const currentTags = props.getValues(props.name) || [];
    const updatedTags = currentTags.filter(
      (tag: string) => tag.toLowerCase() !== tagToRemove.toLowerCase(),
    );
    field.onChange(updatedTags);

    props.clearError(props.name);
  };

  const handleInputBlur = () => {
    if (currentInput.trim() === '') {
      props.clearError(props.name);
    }
  };

  const currentTagCount = props.getValues(props.name)?.length || 0;
  const isMaxTagsReached = maxTags !== undefined && currentTagCount >= maxTags;

  return (
    <Controller
      name={props.name}
      control={props.control}
      rules={props.validation}
      render={({field}) => (
        <>
          <ComptFormField
            id={props.name as string}
            field={
              <div>
                <div className="flex">
                  <input
                    type={'text'}
                    value={currentInput}
                    placeholder={props.placeholder || `Type a ${tagType} and press Enter`}
                    disabled={props.disabled || isMaxTagsReached}
                    onChange={(e) => setCurrentInput(e.target.value)}
                    onBlur={handleInputBlur}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter') {
                        e.preventDefault();
                        handleAddTag(field);
                      }
                    }}
                    className={twMerge(
                      `block w-full rounded-md border-0 py-2 px-3 shadow-sm ring-1
                       ring-inset focus:ring-2 body1 text-color-body2
                       placeholder:text-gray-400 sm:leading-6 focus:ring-2
                       disabled:bg-surface-disabled disabled:text-disabled-on-light
                      ${
                        props.errors
                          ? 'ring-stroke-critical focus:ring-stroke-critical'
                          : 'ring-stroke-tertiary focus:ring-stroke-focus'
                      }
                      ${
                        isMaxTagsReached
                          ? 'bg-surface-disabled text-disabled-on-light cursor-not-allowed'
                          : ''
                      }`,
                    )}
                  />
                </div>
                {props.errors ? (
                  <p className="text-sm text-red-600 mt-1 mb-3">{props.errors.message}</p>
                ) : props.helperText ? (
                  <p className="body3 text-color-body2 mt-1 mb-3">{props.helperText}</p>
                ) : null}
                {props.getValues(props.name)?.length > 0 && (
                  <div className="mt-4">
                    <p className="body2 text-gray-700 font-semibold mb-2">{headingText}</p>
                    <div className="flex flex-wrap gap-2">
                      {props.getValues(props.name)?.map((tag: string) => (
                        <div
                          key={tag}
                          className="inline-flex items-center bg-gray-100 text-gray-700 px-3
                            py-1 rounded-full text-sm border border-gray-300"
                        >
                          {tag.toUpperCase()}
                          {!props.disabled && (
                            <button
                              type="button"
                              className="ml-2 text-gray-500 hover:text-gray-700"
                              onClick={() => handleRemoveTag(tag, field)}
                              aria-label={`Remove keyword ${tag}`}
                            >
                              <ComptSvgIcon
                                iconName="x-icon"
                                svgProp={{width: '12px', height: '12px'}}
                              />
                            </button>
                          )}
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              </div>
            }
            label={props.label}
            subLabel={props.subLabel}
            validation={props.validation}
            data-testid={props['data-testid']}
          />
        </>
      )}
    />
  );
};

import React from 'react';
import {DATE_FORMAT_OPTION, formattedDate} from '@compt/utils/date-helpers';

type CardExpirationTextTeamRecognitionProps = {
  expirationDate: string | Date;
  cycleTypeDescription: string | null;
  accrualDescription?: string | null;
};

export const CardExpirationTextTeamRecognition = ({
  expirationDate,
  cycleTypeDescription,
  accrualDescription,
}: CardExpirationTextTeamRecognitionProps) => (
  <>
    <div className="flex pt-400 flex-row flex-wrap">
      <p className="label3 text-color-body1 pr-050 whitespace-nowrap">
        {cycleTypeDescription} {accrualDescription ? accrualDescription : ''} •{' '}
        {expirationDate
          ? `Expires ${formattedDate(expirationDate, DATE_FORMAT_OPTION['month dd yyyy'])}`
          : 'No Expiration'}
      </p>
    </div>
  </>
);

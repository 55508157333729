import React, {HTMLAttributes} from 'react';
import {Allotment} from '@compt/types/allotments';
import {ComptSvgIcon} from '@compt/common/compt-svg-icon/compt-svg-icon';
import {SupportedCountriesType} from '@compt/utils/international-helpers';
import {CardExpirationTextTeamRecognition} from '@compt/common/compt-card/expiration-text-team-recognition';
import {getAllotmentCycleTypeName} from '@compt/utils/allotment-helpers';
import {ComptSpentTeamRecognitionCard} from '@compt/common/compt-card/compt-spent-team-recognition-card';
import DOMPurify from 'dompurify';

export interface TeamRecognitionProps extends HTMLAttributes<HTMLElement> {
  allotment: Allotment;
  userCountryCode?: SupportedCountriesType;
  disableOnClickWhenNoBalance?: boolean;
  onFullDescriptionClicked?: (allotment: Allotment) => void;
}

export const TeamRecognitionCardContent = (props: TeamRecognitionProps) => (
  <div className="flex flex-col justify-between">
    <div className="flex flex-col">
      <ComptSpentTeamRecognitionCard
        allotment={props.allotment}
        userCountryCode={props.userCountryCode}
      />
      <CardExpirationTextTeamRecognition
        expirationDate={props.allotment.last_date}
        cycleTypeDescription={getAllotmentCycleTypeName(
          props.allotment.type,
          props.allotment.cycle.months,
          props.allotment.sub_type ?? undefined,
        )}
      />
      <p
        className="body3 line-clamp-[3] mt-2 mb-300 inner-html"
        dangerouslySetInnerHTML={{
          __html: DOMPurify.sanitize(props.allotment.cycle.description),
        }}
      />
    </div>
    <div className="flex flex-row">
      <button
        className="label3 mr-4 text-color-link disabled:cursor-not-allowed"
        onClick={props.onClick}
        disabled={props.disableOnClickWhenNoBalance && props.allotment.balance_amount === 0}
      >
        Give recognition
      </button>
      {props.allotment.cycle.description && (
        <button
          className="flex flex-row items-center"
          onClick={(event) => {
            event.preventDefault();
            if (props.onFullDescriptionClicked) {
              props.onFullDescriptionClicked(props.allotment);
            }
          }}
        >
          <p className="label3 text-color-body1">View details</p>
          <ComptSvgIcon
            iconName="chevron-right-icon-gray-700"
            svgProp={{width: '18px', height: '18px'}}
          />
        </button>
      )}
    </div>
  </div>
);

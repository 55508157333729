import React, {useMemo, useState} from 'react';

// RTK queries
import {skipToken} from '@reduxjs/toolkit/dist/query';
import {useGetSessionQuery} from '@compt/app/services/api/api-slice';
import {useGetCompanyQuery} from '@compt/app/services/api/company-slice';
import {
  useGetExpenseReviewsQuery,
  useGetStipendsQuery,
} from '@compt/app/services/api/admin-stipends-slice';

// Hooks and methods
import {useFilterContext} from '@compt/common/compt-filter-sidebar/filter-context';
import {getStatusNumber, STATUS_KEYS} from '@compt/types/stipend-expenses/stipend-expenses';

// Types
import {AllotmentCycle} from '@compt/types/allotments';
import {PerkCategory} from '@compt/types/perk-category';
import {currencyOptions} from '@compt/utils/international-helpers';
import {featureEnabled, FeatureFlags} from '@compt/utils/feature-flags-helper';

// Components
import {FilterSideBar} from '@compt/common/compt-filter-sidebar/compt-filter-sidebar';
import {MultiOptionCollapsible} from '@compt/common/compt-filter-sidebar/multi-option-collapsible';
import {RadioGroupCollapsible} from '@compt/common/compt-filter-sidebar/radio-group-collapsible';
import {DateRangeCollapsible} from '@compt/common/compt-filter-sidebar/date-range-collapsible';
import {AmountRangeCollapsible} from '@compt/common/compt-filter-sidebar/amount-range-collapsible';
import {ComptFeature} from '@compt/common/compt-feature/compt-feature';
import {ComptPortal, PortalRoot} from '@compt/common/compt-portal/compt-portal';
import {ComptDateRangePicker} from '@compt/common/compt-date-range-picker/compt-date-range-picker';

export enum StipendFilter {
  HIDE_CLAIM_VERIFICATION = 'hide_ocr_warning',
  ONLY_CLAIM_VERIFICATION = 'only_ocr_warning',
  COUNTRY = 'user__country__in',
  CURRENCY = 'currency__in',
  CATEGORY = 'perk_category_id__in',
  HIDE_SCANNED = 'hide_ocr_scanned',
  ONLY_SCANNED = 'only_ocr_scanned',
  STATUS = 'status__in',
  STIPEND = 'adjustments__allotment__cycle_id__in',
  TAXABLE = 'taxable_only',
  NON_TAXABLE = 'nontaxable_only',
  DATE_SUBMITTED_START = 'created_on__gte',
  DATE_SUBMITTED_END = 'created_on__lt',
  RECEIPT_DATE_START = 'date_of_expense__gte',
  RECEIPT_DATE_END = 'date_of_expense__lte',
  AMOUNT_MIN = 'amount_claimed__gte',
  AMOUNT_MAX = 'amount_claimed__lte',
  SEARCH = 'search',
  COLUMN_FILTER = 'column_filter',
}

export const ReviewStipendSidebar = () => {
  const {filters, setFilters} = useFilterContext();

  const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(false);

  const session = useGetSessionQuery();
  const companyQuery = useGetCompanyQuery(session.data?.user_id ?? skipToken);

  const {data: expenseReviews} = useGetExpenseReviewsQuery(
    {companyId: companyQuery.data?.id},
    {skip: !companyQuery?.data?.id},
  );

  const {data: adminStipends} = useGetStipendsQuery();

  const stipendOptions: {id: string; name: string}[] = useMemo(() => {
    const cycleNames = expenseReviews?.cycle_names;
    const stipendList = adminStipends?.stipends;

    if (!stipendList || stipendList.length === 0 || !cycleNames || cycleNames.length === 0)
      return [];
    return (
      stipendList
        // Include only cycles that are within expense review
        .filter((stipend: AllotmentCycle) => cycleNames.includes(stipend.name))
        .map((stipend: AllotmentCycle) => ({id: stipend.id, name: stipend.name}))
        .sort((a: AllotmentCycle, b: AllotmentCycle) => a.name.localeCompare(b.name))
    );
  }, [adminStipends, expenseReviews]);

  const perkCategoryOptions: {id: string; name: string}[] = useMemo(() => {
    const taxableCategories = adminStipends?.taxablePerks;
    const nonTaxableCategories = adminStipends?.nonTaxablePerks;

    if (!taxableCategories || !nonTaxableCategories) return [];

    return taxableCategories
      .concat(nonTaxableCategories)
      .map((perkCategory: PerkCategory) => ({id: perkCategory.id, name: perkCategory.name}))
      .sort((a: {id: string; name: string}, b: {id: string; name: string}) =>
        a.name.localeCompare(b.name),
      );
  }, [adminStipends, expenseReviews]);

  return (
    <>
      {/* Date filter next to review tabs */}
      <ComptPortal portalNode={PortalRoot.DATE_RANGE}>
        <ComptDateRangePicker
          label="Date range:"
          initialStartDate={filters[StipendFilter.DATE_SUBMITTED_START] as string}
          initialEndDate={filters[StipendFilter.DATE_SUBMITTED_END] as string}
          handleChange={(start, end) => {
            setFilters((prevState) => {
              const newFilterValues = {
                [StipendFilter.DATE_SUBMITTED_START]: start,
                [StipendFilter.DATE_SUBMITTED_END]: end,
              };

              return {...prevState, ...newFilterValues};
            });
          }}
        />
      </ComptPortal>
      <FilterSideBar
        isCollapsed={isSidebarCollapsed}
        setIsCollapsed={setIsSidebarCollapsed}
        doNotClear={[
          StipendFilter.DATE_SUBMITTED_START,
          StipendFilter.DATE_SUBMITTED_END,
          StipendFilter.SEARCH,
          StipendFilter.COLUMN_FILTER,
        ]}
      >
        <div>
          {featureEnabled(FeatureFlags.INTERNATIONAL) && (
            <MultiOptionCollapsible
              id="review-stipend-country"
              label="Country"
              filterKey={StipendFilter.COUNTRY}
              options={expenseReviews?.countries ?? []}
              getOptionKey={(option) => option}
              getOptionLabel={(option) => option}
            />
          )}
          <MultiOptionCollapsible
            id="review-stipend-status"
            label="Status"
            filterKey={StipendFilter.STATUS}
            options={[STATUS_KEYS.Open, STATUS_KEYS.Approved, STATUS_KEYS.In_Review].map(
              (status) => ({
                id: getStatusNumber(status),
                name: status,
              }),
            )}
            getOptionKey={(option) => `${option.id}`}
            getOptionLabel={(option) => option.name}
          />
          <AmountRangeCollapsible
            id="review-stipend-claim-amount"
            label="Amount"
            minAmountKey={StipendFilter.AMOUNT_MIN}
            maxAmountKey={StipendFilter.AMOUNT_MAX}
          />
          <ComptFeature featureFlag={FeatureFlags.EXPENSE_OCR}>
            <>
              <RadioGroupCollapsible
                id="review-stipend-claim-verification"
                label="Claim verification"
                options={[
                  {key: StipendFilter.HIDE_CLAIM_VERIFICATION, label: 'Hide claim verification'},
                  {key: StipendFilter.ONLY_CLAIM_VERIFICATION, label: 'Only claim verification'},
                ]}
              />
              <RadioGroupCollapsible
                id="review-stipend-scan"
                label="Scanned"
                options={[
                  {key: StipendFilter.HIDE_SCANNED, label: 'Hide scanned'},
                  {key: StipendFilter.ONLY_SCANNED, label: 'Only scanned'},
                ]}
              />
            </>
          </ComptFeature>
          <DateRangeCollapsible
            id="review-stipend-claim-receipt-date"
            label="Receipt date"
            startDateKey={StipendFilter.RECEIPT_DATE_START}
            endDateKey={StipendFilter.RECEIPT_DATE_END}
          />
          <MultiOptionCollapsible
            id="review-stipend-claim-stipend-name"
            label="Stipend"
            filterKey={StipendFilter.STIPEND}
            options={stipendOptions}
            getOptionKey={(option) => option?.id}
            getOptionLabel={(option) => option?.name}
          />
          <MultiOptionCollapsible
            id="review-stipend-claim-categories"
            label="Category"
            filterKey={StipendFilter.CATEGORY}
            options={perkCategoryOptions}
            getOptionKey={(option) => option?.id}
            getOptionLabel={(option) => option?.name}
          />
          <RadioGroupCollapsible
            id="review-stipend-taxability"
            label="Taxability"
            filterValue="true"
            options={[
              {key: StipendFilter.TAXABLE, label: 'Taxable'},
              {key: StipendFilter.NON_TAXABLE, label: 'Nontaxable'},
            ]}
          />
          <MultiOptionCollapsible
            id="review-stipend-currency"
            label="Currency"
            filterKey={StipendFilter.CURRENCY}
            options={currencyOptions}
            getOptionKey={(option) => option.code}
            getOptionLabel={(option) => `${option.name} (${option.code})`}
          />
        </div>
      </FilterSideBar>
    </>
  );
};

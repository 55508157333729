import React, {HTMLAttributes} from 'react';
import {ComptSideNavButton} from './compt-side-nav-button';
import {SideNavItemType} from './compt-side-nav.types';

interface SideNavSectionProps extends HTMLAttributes<HTMLElement> {
  sectionTitle?: string;
  sectionItems: SideNavItemType[];
  excludeIcon?: boolean;
}

export const ComptSideNavSection = (props: SideNavSectionProps) => {
  const {sectionTitle, sectionItems, excludeIcon} = props;

  return (
    <div className="grid gap-y-1">
      {sectionTitle && <p>{sectionTitle.toUpperCase()}</p>}
      {sectionItems.map((navItem, index) => (
        <React.Fragment key={index}>
          <ComptSideNavButton
            key={index}
            id={`nav-button-${index}-${navItem.key}`}
            iconId={navItem.iconId}
            pagePath={navItem.pagePath}
            isHref={navItem.isHref}
            excludeIcon={excludeIcon}
          >
            {navItem.title}
          </ComptSideNavButton>
        </React.Fragment>
      ))}
    </div>
  );
};

import React, {useContext} from 'react';
import {SideNavContext} from './side-nav-context';
import {ComptImage, ComptImageSize} from '../compt-image/compt-image';
import {useGetSessionQuery} from '@compt/app/services/api/api-slice';
import {SECTION_TYPE, SectionType} from '../compt-layout/compt-layout.controller';
import {ComptButtonIcon} from '../compt-button/compt-button';
import {AccountSettingSection} from './compt-account-setting-section';
import {Transition} from '@headlessui/react';
import {ComptCollapsibleSideNavSection} from './compt-collapsible-side-nav-section';
import {ComptSideNavSection} from './compt-side-nav-section';
import {ComptTopBarHomeButton} from '../compt-top-bar/compt-top-bar';
import {useGetCompanyQuery} from '@compt/app/services/api/company-slice';
import {skipToken} from '@reduxjs/toolkit/dist/query';
import {ComptInitialsAvatar} from '../compt-initials-avatar/compt-initials-avatar';
import {ComptSvgIcon} from '../compt-svg-icon/compt-svg-icon';
import {twMerge} from 'tailwind-merge';

interface MobileSideNavProps {
  navSections: SectionType[];
  closeSecondaryNav: () => void;
}

const MobileAdminNavSections = ({navSections}: {navSections: SectionType[]}) => {
  const generalAdminSection = navSections.find(
    (section) => section.type === SECTION_TYPE.GENERAL_ADMIN,
  );
  const collapsibleSections = navSections.filter((section) => section?.isCollapsible);

  if (collapsibleSections.length === 0 && !generalAdminSection) {
    return null;
  }
  const generalAdminItems = generalAdminSection?.items.map((item) => item.sideNavItem);
  return (
    <div className="grid gap-y-1">
      <hr className="my-1" />
      <div className="pb-1">
        <ComptSideNavSection sectionItems={generalAdminItems ?? []} />
      </div>
      {collapsibleSections.length > 0 &&
        collapsibleSections?.map((section, i) => {
          const sideNavItems = section.items.map((item) => item.sideNavItem);

          return (
            <ComptCollapsibleSideNavSection
              key={`collapsible-${i}`}
              id={`section-id-${i}`}
              sectionTitle={section.title}
              sectionItems={sideNavItems}
              sectionIcon={section.sectionIcon}
              sectionType={section.type}
            />
          );
        })}
    </div>
  );
};

export const MobileEmployeeNavSection = ({navSections}: {navSections: SectionType[]}) => {
  const employeeNavSection = navSections?.find((section) => section.type === SECTION_TYPE.EMPLOYEE);

  if (!employeeNavSection) {
    return null;
  }

  const employeeSideNavItems = employeeNavSection.items.map((item) => item.sideNavItem);
  return <ComptSideNavSection sectionItems={employeeSideNavItems} />;
};

export const MobileSideNav = (props: MobileSideNavProps) => {
  const {isAdminView, sidebarOpen, setSidebarOpen} = useContext(SideNavContext);

  const session = useGetSessionQuery();
  const userData = session.data;
  const company = useGetCompanyQuery(userData?.user_id ?? skipToken).data;

  return (
    <Transition.Root show={sidebarOpen} as="div">
      <div className={'sm:hidden z-50 fixed h-full inset-y-0 right-0 pt-2 pr-2'}>
        {sidebarOpen && (
          <div className="flex pl-2 justify-center">
            <button
              className="mt-300 mr-200"
              onClick={() => setSidebarOpen((previousState) => !previousState)}
            >
              <ComptSvgIcon iconName={ComptButtonIcon.X_STYLEABLE} className="stroke-white" />
            </button>
          </div>
        )}
      </div>
      <Transition.Child
        as="div"
        enter="transform transition ease-in-out duration-300"
        enterFrom="-translate-x-full"
        enterTo="translate-x-0"
        leave="transform transition ease-in-out duration-300"
        leaveFrom="translate-x-0"
        leaveTo="-translate-x-full"
        className={twMerge(
          `bg-white inset-0 fixed w-5/6 h-full overflow-y-auto overflow-x-hidden ${
            isAdminView && 'w-full'
          }`,
        )}
      >
        <div className="flex flex-col">
          <div className="relative">
            <div className="p-300">
              <ComptTopBarHomeButton setSidebarOpen={setSidebarOpen} company={company} />
            </div>
            <ComptImage
              alt="company-logo-image"
              src="/static/img/cover-squiggles.png"
              size={ComptImageSize.LARGE}
            />
            <div className="absolute bottom-10 left-0">
              <ComptImage
                className="pl-4"
                alt="company-logo-image"
                src="/static/img/doodle-crown.png"
                size={ComptImageSize.SMALL}
              />
              <div
                className={`absolute -bottom-[145px] grid gap-4 px-4 pb-3 ${
                  !isAdminView && 'pb-5 '
                }`}
              >
                <ComptInitialsAvatar>
                  <h2 className="m-[18px] text-brand-700">
                    {userData?.first_name[0]}
                    {userData?.last_name[0]}
                  </h2>
                </ComptInitialsAvatar>
                <div className="grid gap-2">
                  <h4>{userData?.nickname}</h4>
                  <p className="body3">{userData?.email}</p>
                </div>
              </div>
            </div>
          </div>
          <div className="px-2 pt-28">
            <div className="grid gap-y-1">
              <MobileEmployeeNavSection navSections={props.navSections} />
            </div>
            <MobileAdminNavSections navSections={props.navSections} />
            {!isAdminView && (
              <AccountSettingSection hasNavSections={props.navSections.length > 0} />
            )}
          </div>
        </div>
      </Transition.Child>
    </Transition.Root>
  );
};

import React from 'react';

// Hooks and methods
import {useFilterContext} from './filter-context';

// Types
import {FiltersState} from './compt-filter-sidebar';

// Components
import {ComposableCollapsible} from './composable-collapsible';

interface MultiOptionCollapsibleProps<TOptionType> {
  id: string;
  label: string;
  filterKey: string;
  options: TOptionType[];
  getOptionKey: (option: TOptionType) => string | number;
  getOptionLabel: (option: TOptionType) => string;
  testId?: string;
}

export const MultiOptionCollapsible = <TOptionType,>(
  props: MultiOptionCollapsibleProps<TOptionType>,
) => {
  const {filters, setFilters} = useFilterContext();

  const selectedOptions = filters[props.filterKey];

  function handleMultipleOptionSelection<T extends FiltersState>(
    filterKey: keyof T,
    optionId: string,
    isChecked: boolean,
    setFilters: (updater: (prevState: T) => T) => void,
  ) {
    setFilters((prevState) => {
      if (!Array.isArray(prevState[filterKey])) return {...prevState};

      const currentFilterValues = prevState[filterKey] as string[];

      const updatedFilterValues = isChecked
        ? [...currentFilterValues, optionId]
        : currentFilterValues.filter((id: string) => id !== optionId);

      return {...prevState, [filterKey]: updatedFilterValues};
    });
  }

  return (
    <ComposableCollapsible
      collapsibleId={props.id}
      appliedFilterCount={selectedOptions.length}
      label={props.label}
    >
      <div className="flex flex-col">
        {props.options.map((option: TOptionType) => (
          <label
            key={props.getOptionKey(option)}
            className="flex items-center hover:bg-surface-secondary-hover p-1 rounded"
          >
            <input
              type="checkbox"
              checked={selectedOptions?.includes(props.getOptionKey(option) as string)}
              onChange={(e) =>
                handleMultipleOptionSelection(
                  props.filterKey,
                  props.getOptionKey(option) as string,
                  e.target.checked,
                  setFilters,
                )
              }
              className={`h-4 w-4 rounded border-gray-300 text-color-link checked:bg-base-500
                        focus:ring-base-500 cursor-pointer mr-100 disabled:bg-gray-400 
                        disabled:hover:bg-gray-400`}
            />
            <span className="text-sm text-gray-700 hover:text-gray-900 cursor-pointer">
              {props.getOptionLabel(option)}
            </span>
          </label>
        ))}
      </div>
    </ComposableCollapsible>
  );
};

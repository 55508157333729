import {useMemo} from 'react';

// RTK queries
import {useGetAdminBusinessExpenseReportListQuery} from '@compt/app/services/api/admin-business-expenses-slice';
import {useGetSessionQuery} from '@compt/app/services/api/api-slice';
import {useGetCompanyQuery} from '@compt/app/services/api/company-slice';
import {skipToken} from '@reduxjs/toolkit/dist/query';

// Hooks and methods
import {useNavigate, useParams} from 'react-router-dom';
import {twMerge} from 'tailwind-merge';
import {ReviewBEReportsTableController as controller} from './review-business-expense-reports-table.controller';

// Types
import {MAX_ADMIN_BUSINESS_EXPENSE_REPORTS_PER_PAGE} from '@compt/constants';
import {BusinessExpenseReport} from '@compt/types/business-expenses/business-expense-report';

// Components
import {ComptRow} from '@compt/common/compt-row/compt-row';
import {LeanComptTable} from '@compt/common/lean-compt-table/lean-compt-table';
import {
  LeanComptTableContextProvider,
  useLeanComptTableContext,
} from '@compt/common/lean-compt-table/lean-compt-table-context';

export const BusinessExpensePayrollView = () => {
  const {payrollCycleId} = useParams();
  const {currentPage} = useLeanComptTableContext();

  const navigate = useNavigate();

  const session = useGetSessionQuery();
  const userId = session.data?.user_id;
  const companyQuery = useGetCompanyQuery(userId ?? skipToken);
  const companyId = companyQuery.data?.id;

  const onActionClicked = (report: BusinessExpenseReport) => {
    navigate(`/review-programs/business-expenses/${report.id}`);
  };

  const paginationQuery = useMemo(
    () => ({
      offset: MAX_ADMIN_BUSINESS_EXPENSE_REPORTS_PER_PAGE * Math.max(currentPage - 1, 0),
      page: currentPage,
    }),
    [currentPage],
  );

  const columnDefinition = controller.getColumnDefinition(onActionClicked);

  const businessExpenseReportListQuery = useGetAdminBusinessExpenseReportListQuery(
    {
      companyId,
      params: {
        payroll_cycle__id: payrollCycleId,
        ...paginationQuery,
        limit: MAX_ADMIN_BUSINESS_EXPENSE_REPORTS_PER_PAGE,
      },
    },
    {skip: !companyId},
  );

  return (
    <div className="flex w-full h-full">
      <div className="my-6 mt-4 flex flex-col overflow-y-auto w-full px-4 sm:px-10">
        <div className={twMerge('flex flex-col justify-between sm:flex-row')}>
          <div className="flex flex-col">
            <div className="flex gap-2 items-center">
              <h1 className={'display1'}>Business expense reports</h1>
            </div>
            <p className="body1 sm:mb-7">View payroll processed business expense reports</p>
          </div>
        </div>
        <ComptRow className="pb-10 w-full">
          <LeanComptTableContextProvider
            columnDefinition={columnDefinition}
            tableId="be-payroll-table"
          >
            <LeanComptTable
              data={businessExpenseReportListQuery.data?.results ?? []}
              isDataLoading={businessExpenseReportListQuery.isLoading}
              noDataTitleText={'No business expenses found'}
              onRowClicked={(row) => {
                navigate(`/review-programs/business-expenses/${row.id}`);
              }}
              totalCount={businessExpenseReportListQuery.data?.count ?? 0}
              itemsPerPage={MAX_ADMIN_BUSINESS_EXPENSE_REPORTS_PER_PAGE}
              stickyLastColumn
              showPagination
            />
          </LeanComptTableContextProvider>
        </ComptRow>
      </div>
    </div>
  );
};

import React, {useEffect} from 'react';

// RTK queries
import {useDeleteDraftExpenseMutation} from '@compt/app/services/api/draft-expense-slice';

// Hooks and methods
import {useLocation, useNavigate, useSearchParams} from 'react-router-dom';
import {DraftExpensesController as controller} from './draft-expenses-table.controller';
import {featureEnabled, FeatureFlags} from '@compt/utils/feature-flags-helper';
import {useConfirmationModal} from '@compt/utils/confirmation-modal-helper';
import {triggerCustomToast} from '@compt/common/compt-toaster/compt-toaster';

// Types
import {DraftExpense} from './draft-expense.types';
import {ModalType} from '@compt/common/compt-confirmation-modal/compt-confirmation.modal';

// Components
import {FilterContextProvider} from '@compt/common/compt-filter-sidebar/filter-context';
import {DraftExpensesTable} from './draft-expenses-table';
import {LeanComptTableContextProvider} from '@compt/common/lean-compt-table/lean-compt-table-context';

export const DraftExpensesTab = () => {
  const FILTER_ID = 'draft-expense-table-v1.0,0';

  const [, setSearchParams] = useSearchParams();
  const {pathname} = useLocation();
  const navigate = useNavigate();

  const draftExpensesEnabled = featureEnabled(FeatureFlags.EMAIL_TO_DRAFT);

  const [deleteDraftExpense] = useDeleteDraftExpenseMutation();

  const {modal: confirmDeleteModal, showModal: showDeleteModal} = useConfirmationModal(
    'Are you sure you want to delete this draft expense? This action cannot be undone.',
    'Confirm draft deletion',
    ModalType.DESTRUCTIVE,
    'Delete',
  );

  async function onDeleteDraftExpense(draft: DraftExpense) {
    const confirmDelete = await showDeleteModal();
    if (!confirmDelete) return;
    deleteDraftExpense(draft.id).then((results) => {
      if ('error' in results) {
        triggerCustomToast(
          'error',
          'There was a problem deleting the draft expense',
          'Please try again',
        );
        return;
      }

      triggerCustomToast('success', 'Successfully deleted draft expense');
    });
  }

  const columnDefinition = controller.getColumnDefinition(onActionMenuClicked);
  function onActionMenuClicked(draft: DraftExpense, action: 'REVIEW' | 'DELETE') {
    switch (action) {
      case 'REVIEW':
        setSearchParams({draft_id: `${draft.id}`});
        break;
      case 'DELETE':
        onDeleteDraftExpense(draft);
        break;
    }
  }

  // Redirect if draft expenses are not enabled
  useEffect(() => {
    if (draftExpensesEnabled || pathname !== '/activity/draft-expenses') return;

    navigate('/activity/claims-submitted');
  }, [pathname]);

  return (
    <>
      {confirmDeleteModal}
      <FilterContextProvider filterId={FILTER_ID} initialValues={{}}>
        <LeanComptTableContextProvider columnDefinition={columnDefinition} tableId={FILTER_ID}>
          <DraftExpensesTable />
        </LeanComptTableContextProvider>
      </FilterContextProvider>
    </>
  );
};

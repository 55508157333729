import React, {useState} from 'react';

// Types
import {LearningRequestStatus} from '@compt/types/learning-development/learning-request-status';

// Hooks and methods
import {useFilterContext} from '@compt/common/compt-filter-sidebar/filter-context';

// Components
import {FilterSideBar} from '@compt/common/compt-filter-sidebar/compt-filter-sidebar';
import {MultiOptionCollapsible} from '@compt/common/compt-filter-sidebar/multi-option-collapsible';
import {ComptPortal, PortalRoot} from '@compt/common/compt-portal/compt-portal';
import {ComptDateRangePicker} from '@compt/common/compt-date-range-picker/compt-date-range-picker';

const STATUS_OPTIONS = [
  {id: LearningRequestStatus.PRE_APPROVAL_REQUESTED, label: 'Pre-approval requested'},
  {id: LearningRequestStatus.PARTIALLY_APPROVED, label: 'Partially approved'},
  {id: LearningRequestStatus.PRE_APPROVED, label: 'Pre-approved'},
  {id: LearningRequestStatus.PRE_APPROVAL_REJECTED, label: 'Pre-approval rejected'},
  {id: LearningRequestStatus.REIMBURSEMENT_REQUESTED, label: 'Reimbursement requested'},
  {id: LearningRequestStatus.REIMBURSEMENT_APPROVED, label: 'Reimbursement approved'},
  {id: LearningRequestStatus.REIMBURSED, label: 'Reimbursed'},
  {id: LearningRequestStatus.REIMBURSEMENT_REJECTED, label: 'Reimbursement rejected'},
];

const FILTER_OPTIONS = [{id: 'status', label: 'Status', testId: 'review-lnd-status-filter'}];

export enum ProfessionalDevelopmentFilter {
  STATUS = 'status__in',
  SEARCH = 'search',
  DATE_SUBMITTED_START = 'created_on_after',
  DATE_SUBMITTED_END = 'created_on_before',
}

export const ReviewLndFilterSidebar = () => {
  const {filters, setFilters} = useFilterContext();

  const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(false);

  return (
    <>
      {/* Date filter next to review tabs */}
      <ComptPortal portalNode={PortalRoot.DATE_RANGE}>
        <ComptDateRangePicker
          label="Date range:"
          initialStartDate={filters[ProfessionalDevelopmentFilter.DATE_SUBMITTED_START] as string}
          initialEndDate={filters[ProfessionalDevelopmentFilter.DATE_SUBMITTED_END] as string}
          handleChange={(start, end) => {
            setFilters((prevState) => {
              const newFilterValues = {
                [ProfessionalDevelopmentFilter.DATE_SUBMITTED_START]: start,
                [ProfessionalDevelopmentFilter.DATE_SUBMITTED_END]: end,
              };

              return {...prevState, ...newFilterValues};
            });
          }}
        />
      </ComptPortal>
      <FilterSideBar
        isCollapsed={isSidebarCollapsed}
        setIsCollapsed={setIsSidebarCollapsed}
        doNotClear={[
          ProfessionalDevelopmentFilter.SEARCH,
          ProfessionalDevelopmentFilter.DATE_SUBMITTED_START,
          ProfessionalDevelopmentFilter.DATE_SUBMITTED_END,
        ]}
      >
        <div className="flex flex-col mt-4 space-y-2">
          {FILTER_OPTIONS.map((filter) => (
            <MultiOptionCollapsible
              key={`side-bar-collapse-${filter.id}`}
              id={filter.id}
              label={filter.label}
              filterKey={ProfessionalDevelopmentFilter.STATUS}
              options={STATUS_OPTIONS}
              getOptionKey={(option) => option.id}
              getOptionLabel={(option) => option.label}
            />
          ))}
        </div>
      </FilterSideBar>
    </>
  );
};

import React from 'react';

// Types
import {UseFormReturn} from 'react-hook-form';
import {RequestFieldType} from '@compt/types/learning-development/learning-development-program';
import {CustomFieldFormFieldValues} from './custom-field-form.types';

// Components
import {ComptRadioField} from '@compt/common/forms/compt-radio-field/compt-radio-field';
import {ComptTextField} from '@compt/common/forms/compt-text-field/compt-text-field';

interface CustomFieldFormProps {
  formMethods: UseFormReturn<CustomFieldFormFieldValues>;
  readOnly?: boolean;
}

export const CustomFieldForm = (props: CustomFieldFormProps) => {
  const {formMethods, readOnly} = props;
  const watchedFieldName = formMethods.watch('field_name');
  const watchedSupportingText = formMethods.watch('supporting_text');

  return (
    <>
      <ComptTextField
        name="field_name"
        label="Field name"
        placeholder="Enter name here"
        control={formMethods.control}
        register={formMethods.register}
        validation={{required: 'Field name is required'}}
        errors={formMethods.formState.errors.field_name}
        maxLength={100}
        watchedValue={watchedFieldName}
        disabled={readOnly}
        readOnly={readOnly}
      />
      <ComptTextField
        name="supporting_text"
        label="Supporting text"
        placeholder="Enter information here"
        subLabel={'This will appear below the field to provide additional context.'}
        control={formMethods.control}
        register={formMethods.register}
        errors={formMethods.formState.errors.supporting_text}
        maxLength={250}
        watchedValue={watchedSupportingText}
        disabled={readOnly}
        readOnly={readOnly}
      />
      <ComptRadioField
        id="is_required_field"
        name="is_required_field"
        label="Is this field required?"
        validation={{
          required: 'Selection is required',
        }}
        options={[
          {id: 'Y', label: 'Yes'},
          {id: 'N', label: 'No'},
        ]}
        register={formMethods.register}
        control={formMethods.control}
        errors={formMethods.formState.errors.is_required_field}
        readOnly={readOnly}
      />
      <ComptRadioField
        id="field_type"
        name="field_type"
        label="Select a field type"
        validation={{
          required: 'Field type is required',
        }}
        options={[
          {id: RequestFieldType.SHORT_TEXT, label: 'Short text'},
          {id: RequestFieldType.LONG_TEXT, label: 'Long text'},
          {id: RequestFieldType.DATE, label: 'Date'},
          {id: RequestFieldType.CURRENCY, label: 'Amount (currency)'},
        ]}
        register={formMethods.register}
        control={formMethods.control}
        errors={formMethods.formState.errors.field_type}
        readOnly={readOnly}
      />
    </>
  );
};

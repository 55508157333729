import {ComptStyleType} from '@compt/utils/style-helpers';

export enum BusinessExpenseReportStatus {
  DRAFT = 'DRAFT',
  SUBMITTED = 'SUBMITTED',
  REVIEWED_BY_MANAGER = 'REVIEWED_BY_MANAGER',
  REVIEWED = 'REVIEWED',
  REJECTED = 'REJECTED',
  PROCESSED = 'PROCESSED',
}

export interface StatusFormatItems {
  statusName: string;
  statusStyle: ComptStyleType;
}

export const expenseReportStatusFormats: Record<BusinessExpenseReportStatus, StatusFormatItems> = {
  SUBMITTED: {statusName: 'Submitted', statusStyle: ComptStyleType.SECONDARY},
  REJECTED: {statusName: 'Rejected', statusStyle: ComptStyleType.DESTRUCTIVE},
  PROCESSED: {statusName: 'Processed', statusStyle: ComptStyleType.PRIMARY},
  DRAFT: {statusName: 'Draft', statusStyle: ComptStyleType.GRAY},
  REVIEWED_BY_MANAGER: {statusName: 'Reviewed by manager', statusStyle: ComptStyleType.SUCCESS},
  REVIEWED: {statusName: 'Reviewed', statusStyle: ComptStyleType.SUCCESS},
};

import React from 'react';

// Hooks and methods
import {formatCurrency} from '@compt/utils/international-helpers';
import {DATE_FORMAT_OPTION, formattedDate} from '@compt/utils/date-helpers';

// Types
import {LearningRequestStatus} from '@compt/types/learning-development/learning-request-status';
import {LearningStatusFormats} from '../review-lnd.types';
import {PreApprovalRequest} from '@compt/types/learning-development/pre-approval-request';

// Components
import {ComptPill} from '@compt/common/forms/compt-pill/compt-pill';
import {SimpleAction, SimpleActionMenu} from '@compt/common/compt-table/simple-action-menu';
import {Tooltip} from 'react-tooltip';
import {ComptSvgIcon} from '@compt/common/compt-svg-icon/compt-svg-icon';

export class ReviewLnDTableController {
  static getColumnDefinition(
    onMenuClicked: (reviewData: PreApprovalRequest, action: 'EDIT' | 'VIEW' | 'DELETE') => void,
  ) {
    const columnDefinition = {
      request_number: {
        id: 'request_number',
        name: '#',
        width: '80px',
        grow: 0.7,
        selector: (reviewData: PreApprovalRequest) => (
          <p data-tag="allowRowEvents">{reviewData?.id}</p>
        ),
        order: 1,
        omit: false,
      },
      employee_name: {
        id: 'employee_name',
        name: 'Employee name',
        grow: 1,
        selector: (reviewData: PreApprovalRequest) => (
          <p data-tag="allowRowEvents">{reviewData?.user?.full_name}</p>
        ),
        order: 2,
        omit: false,
      },
      amount: {
        id: 'amount',
        name: 'Amount',
        grow: 0.5,
        selector: (reviewData: PreApprovalRequest) => (
          <p data-tag="allowRowEvents">
            {formatCurrency(
              reviewData?.expense?.amount_claimed ?? reviewData?.request_data?.['Amount'],
              reviewData?.currency,
            )}
          </p>
        ),
        order: 3,
        omit: false,
      },
      program: {
        id: 'program',
        name: 'Program',
        width: '200px',
        grow: 1,
        selector: (reviewData: PreApprovalRequest) => (
          <p data-tag="allowRowEvents">{reviewData?.program?.name}</p>
        ),
        order: 4,
      },
      date_submitted: {
        id: 'date_submitted',
        label: 'Date submitted',
        name: (
          <>
            <Tooltip
              anchorSelect={'#date-submitted-label'}
              place="bottom"
              opacity={100}
              style={{borderRadius: '6px', zIndex: 100}}
            >
              The date the pre-approval request was submitted
            </Tooltip>
            <div id="date-submitted-label" className="flex items-center gap-x-1">
              Date submitted
              <ComptSvgIcon
                iconName="help-circle"
                svgProp={{width: '16px', height: '16px'}}
                className="text-gray-500 cursor-pointer"
              />
            </div>
          </>
        ),
        width: '175px',
        grow: 1,
        selector: (reviewData: PreApprovalRequest) => (
          <p data-tag="allowRowEvents">
            {formattedDate(reviewData.created_on, DATE_FORMAT_OPTION['month dd yyyy'])}
          </p>
        ),
        order: 5,
        omit: false,
      },
      last_updated: {
        id: 'last_updated',
        name: 'Last updated',
        grow: 1,
        selector: (reviewData: PreApprovalRequest) => (
          <p data-tag="allowRowEvents">
            {formattedDate(reviewData.modified_on, DATE_FORMAT_OPTION['month dd yyyy'])}
          </p>
        ),
        order: 5,
        omit: false,
      },
      status: {
        id: 'status',
        name: 'Status',
        grow: 1,
        width: '200px',
        selector: (reviewData: PreApprovalRequest) => {
          const requestStatusFormat =
            LearningStatusFormats[reviewData?.status as LearningRequestStatus];

          return (
            <ComptPill pillType={requestStatusFormat?.statusStyle} data-tag="allowRowEvents">
              {requestStatusFormat?.label}
            </ComptPill>
          );
        },
        order: 6,
        omit: false,
      },
      action: {
        id: 'action',
        ignoreRowClick: true,
        compact: true,
        selector: (reviewData: PreApprovalRequest) => {
          const _onMenuClicked = (
            reviewData: PreApprovalRequest,
            action: 'VIEW' | 'EDIT' | 'DELETE',
          ) => {
            onMenuClicked(reviewData, action);
          };
          const actions: Array<SimpleAction<PreApprovalRequest>> = [
            {
              label: 'View',
              onClick: (reviewData) => _onMenuClicked(reviewData, 'VIEW'),
            },
          ];

          if (reviewData.status === LearningRequestStatus.PRE_APPROVAL_REQUESTED) {
            actions.push({
              label: 'Edit',
              onClick: (reviewData) => _onMenuClicked(reviewData, 'EDIT'),
            });
          }

          return <SimpleActionMenu relatedActionItem={reviewData} actions={actions} />;
        },
        grow: 0,
        order: 7,
        disableRemoval: true,
      },
    };

    return columnDefinition;
  }
}

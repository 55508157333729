import {StepStatus} from '@compt/common/compt-progress-stepper/compt-progress-stepper';
import {LearningDevelopmentProgram} from '@compt/types/learning-development/learning-development-program';
import {EligibilityBreakdownOption} from '@compt/types/learning-development/eligibility-breakdown.types';

export class LndFormController {
  static getFormStatus(
    isFormComplete: boolean,
    isPreviousFormComplete: boolean,
    isReadOnly: boolean,
  ) {
    if (isReadOnly) return StepStatus.READ_ONLY;
    if (isFormComplete) return StepStatus.COMPLETE;
    if (!isFormComplete && isPreviousFormComplete) return StepStatus.NEXT;

    return StepStatus.LOCKED;
  }

  static isProgramOverviewComplete(existingData?: LearningDevelopmentProgram) {
    if (!existingData) return false;

    return !!existingData.name && !!existingData.description;
  }

  static isEligibilityBreakdownComplete(existingData?: LearningDevelopmentProgram) {
    if (!existingData) return false;

    if (
      existingData.eligibility === EligibilityBreakdownOption.CASE_BY_CASE &&
      existingData.program_funding_rules.length > 0
    )
      return true;

    return (
      !!existingData.budget_distribution &&
      !!existingData.eligibility &&
      !!existingData.budget_renewal_months &&
      existingData.program_funding_rules.length > 0
    );
  }

  static isFundingExpirationComplete(existingData?: LearningDevelopmentProgram) {
    if (!existingData) return false;

    return !!existingData.funds_expiration_days && !!existingData.funds_expiration_strategy;
  }

  // TODO (COMPT-5854): Need a more robust way of determining step completion for this
  // and the reimbursement request
  static isPreApprovalRequestComplete(existingData?: LearningDevelopmentProgram) {
    if (!existingData) return false;

    // Ensure the funding expiration step is complete before considering this step complete
    return (
      !!existingData.pre_approval_configuration && this.isFundingExpirationComplete(existingData)
    );
  }
  static isReimbursementRequestComplete(
    existingData?: LearningDevelopmentProgram,
    reimbursementFormStepUpdated?: boolean | undefined,
  ) {
    if (this.isApproverReviewerComplete(existingData)) return true;
    if (!existingData || !reimbursementFormStepUpdated) return false;
    return (
      !!existingData.reimbursement_configuration &&
      this.isPreApprovalRequestComplete(existingData) &&
      reimbursementFormStepUpdated
    );
  }

  static isApproverReviewerComplete(existingData?: LearningDevelopmentProgram) {
    if (!existingData) return false;

    return (
      !!existingData.program_reviewing_configuration &&
      (!!existingData.program_reviewing_configuration.primary_approvers ||
        !!existingData.program_reviewing_configuration.use_employee_manager_as_primary_approver) &&
      (!!existingData.program_reviewing_configuration.reimbursement_reviewers ||
        !!existingData.program_reviewing_configuration
          .use_employee_manager_as_reimbursement_reviewer)
    );
  }

  static allStepsComplete(existingData?: LearningDevelopmentProgram) {
    return (
      this.isEligibilityBreakdownComplete(existingData) &&
      this.isFundingExpirationComplete(existingData) &&
      this.isPreApprovalRequestComplete(existingData) &&
      this.isReimbursementRequestComplete(existingData) &&
      this.isApproverReviewerComplete(existingData)
    );
  }

  static getReviewerStepStatus(existingData?: LearningDevelopmentProgram, readOnly?: boolean) {
    if (existingData?.is_draft && this.allStepsComplete(existingData)) return StepStatus.NEXT;
    if (readOnly) return StepStatus.READ_ONLY;
    if (existingData && !existingData?.is_draft) return StepStatus.COMPLETE;

    return StepStatus.LOCKED;
  }
}

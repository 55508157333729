import React, {InputHTMLAttributes} from 'react';
import {ComposableCollapsible} from './composable-collapsible';
import {useFilterContext} from './filter-context';
import {FiltersState} from './compt-filter-sidebar';

interface Props {
  id: string;
  label: string;
  minAmountKey: string;
  maxAmountKey: string;
  minPlaceholder?: string;
  maxPlaceholder?: string;
}

export const AmountRangeCollapsible = (props: Props) => {
  const {filters, setFilters} = useFilterContext();

  const appliedFilterCount = [props.minAmountKey, props.maxAmountKey].filter(
    (key) => filters[key] !== '',
  ).length;

  function handleAmountSelection<T extends FiltersState>(
    filterKey: keyof T,
    amount: string,
    setFilters: (updater: (prevState: T) => T) => void,
  ) {
    setFilters((prevState) => {
      if (typeof prevState[filterKey] !== 'string') return {...prevState};

      return {...prevState, [filterKey]: amount};
    });
  }

  return (
    <ComposableCollapsible
      collapsibleId={props.id}
      label={props.label}
      appliedFilterCount={appliedFilterCount}
    >
      <div className="flex space-x-2 items-center">
        <AmountInput
          value={filters[props.minAmountKey] as string}
          placeholder={props.minPlaceholder ?? 'No min'}
          onChange={(e) => handleAmountSelection(props.minAmountKey, e.target.value, setFilters)}
        />
        <span className="text-center mx-2">-</span>
        <AmountInput
          value={filters[props.maxAmountKey] as string}
          placeholder={props.maxPlaceholder ?? 'No max'}
          onChange={(e) => handleAmountSelection(props.maxAmountKey, e.target.value, setFilters)}
        />
      </div>
    </ComposableCollapsible>
  );
};

interface AmountProps extends InputHTMLAttributes<HTMLInputElement> {
  value: string;
}

const AmountInput = (props: AmountProps) => (
  <input
    className={`w-1/2 rounded-md border-0 ring-1 h-8 
      ring-stroke-tertiary focus:ring-stroke-focus focus:ring-2
      ring-inset text-color-body2 placeholder:text-color-tertiary placeholder:body3 `}
    type="number"
    step="0.01"
    min={0}
    placeholder={props.placeholder}
    value={props.value}
    onChange={props.onChange}
    onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
      e.key === 'Enter' && e.preventDefault();
    }}
  />
);

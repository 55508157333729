import {createContext, Dispatch, ReactNode, SetStateAction, useContext, useState} from 'react';

interface ReceiptUploadContextProps {
  receiptUploading: boolean;
  setReceiptUploading: Dispatch<SetStateAction<boolean>>;
}

export const ReceiptUploadContext = createContext<ReceiptUploadContextProps>({
  receiptUploading: false,
  setReceiptUploading: () => {},
});

interface Props {
  children: ReactNode;
}

export const ReceiptUploadContextProvider = (props: Props) => {
  const [receiptUploading, setReceiptUploading] = useState(false);

  return (
    <ReceiptUploadContext.Provider value={{receiptUploading, setReceiptUploading}}>
      {props.children}
    </ReceiptUploadContext.Provider>
  );
};

export const useReceiptUploadContext = (): ReceiptUploadContextProps => {
  const context = useContext(ReceiptUploadContext);

  if (!context) {
    throw new Error('useReceiptUploadContext must be used within a ReceiptUploadContextProvider. ');
  }

  return context;
};

import React, {useEffect, useState} from 'react';

// Hooks and methods
import {useFilterContext} from './filter-context';

// Types
import {FiltersState} from './compt-filter-sidebar';

// Components
import {ComposableCollapsible} from './composable-collapsible';
import {ComptDatePicker} from '../forms/compt-date-picker/compt-date-picker';

interface DateRangeCollapsibleProps {
  id: string;
  label: string;
  startDateKey: string;
  endDateKey: string;
}

export const DateRangeCollapsible = (props: DateRangeCollapsibleProps) => {
  const [startDate, setStartDate] = useState<string | Date>('');
  const [endDate, setEndDate] = useState<string | Date>('');

  const {filters, setFilters} = useFilterContext();

  const appliedFilterCount = [props.startDateKey, props.endDateKey].filter(
    (key) => filters[key] !== '',
  ).length;

  // Clear start and end dates when clearing all filters
  useEffect(() => {
    if (filters[props.startDateKey] === '' && startDate !== '') {
      setStartDate('');
    }

    if (filters[props.endDateKey] === '' && endDate !== '') {
      setEndDate('');
    }
  }, [filters]);

  function handleDateSelection<T extends FiltersState>(
    filterKey: keyof T,
    date: string | Date,
    setFilters: (updater: (prevState: T) => T) => void,
  ) {
    setFilters((prevState) => {
      if (typeof prevState[filterKey] !== 'string') return {...prevState};

      return {...prevState, [filterKey]: date};
    });
  }

  return (
    <ComposableCollapsible
      collapsibleId={props.id}
      label={props.label}
      appliedFilterCount={appliedFilterCount}
    >
      <div className="space-y-1">
        <label className="body3 text-color-body2">Start date</label>
        <ComptDatePicker
          value={(filters[props.startDateKey] as string) ?? ''}
          endDate={endDate}
          onChange={(date: string | Date) => {
            setStartDate(date);
            handleDateSelection(props.startDateKey, date, setFilters);
          }}
          datePickerClassName="right-0"
          showClearButton
          propagateClear
        />
      </div>
      <div className="space-y-1">
        <label className="body3 text-color-body2">End date</label>
        <ComptDatePicker
          value={(filters[props.endDateKey] as string) ?? ''}
          startDate={startDate}
          onChange={(date: string | Date) => {
            setEndDate(date);
            handleDateSelection(props.endDateKey, date, setFilters);
          }}
          datePickerClassName="right-0"
          showClearButton
          propagateClear
        />
      </div>
    </ComposableCollapsible>
  );
};

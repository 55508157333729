import {ComptStyleType} from '@compt/utils/style-helpers';

export enum BusinessExpenseStatus {
  OPEN = 'OPEN',
  SUBMITTED = 'SUBMITTED',
  APPROVED_BY_MANAGER = 'APPROVED_BY_MANAGER',
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED',
  PROCESSED = 'PROCESSED',
}

export const businessExpenseStatusFormat = {
  OPEN: {statusName: 'Open', statusStyle: ComptStyleType.PRIMARY},
  SUBMITTED: {statusName: 'Submitted', statusStyle: ComptStyleType.SECONDARY},
  APPROVED: {statusName: 'Approved', statusStyle: ComptStyleType.SUCCESS},
  REJECTED: {statusName: 'Rejected', statusStyle: ComptStyleType.DESTRUCTIVE},
  PROCESSED: {statusName: 'Processed', statusStyle: ComptStyleType.PRIMARY},
  APPROVED_BY_MANAGER: {statusName: 'Approved by manager', statusStyle: ComptStyleType.SUCCESS},
};

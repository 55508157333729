import {ReactNode, useEffect, useState} from 'react';
import {createPortal} from 'react-dom';

interface PortalProps {
  portalNode: PortalRoot;
  children: ReactNode;
}

export enum PortalRoot {
  RIGHT_NAV = 'right-nav-portal-root',
  DATE_RANGE = 'date-range-portal-root',
}

/**
 * A portal component that renders its children into a DOM node outside the
 * current component hierarchy. It attempts to find an existing element with the
 * ID specified by {@link portalNode}. If the element does not exist, it will not
 * render the children.
 *
 * For more information on this, see the following link:
 * https://react.dev/reference/react-dom/createPortal
 */
export const ComptPortal = ({portalNode, children}: PortalProps): JSX.Element | null => {
  const [portalElement, setPortalElement] = useState<HTMLElement | null>(null);

  useEffect(() => {
    const el = document.getElementById(portalNode);
    // If element does not exist, it's likely hidden for a reason (ex: is mobile view). Do not create a new one.
    if (!el) return;
    setPortalElement(el);
  }, []);

  if (!portalElement) return null;

  return createPortal(children, portalElement);
};

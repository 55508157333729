import React from 'react';

// Hooks and methods
import {DATE_FORMAT_OPTION, formattedDate} from '@compt/utils/date-helpers';
import {formatCurrencyFromCountryCode} from '@compt/utils/international-helpers';
import {formatWithThousandsSeparator} from '@compt/utils/business-expense-helpers';

// Types
import {
  BusinessExpense,
  expenseTypeFormats,
  BusinessExpenseType,
} from '@compt/types/business-expenses/business-expense';
import {
  BusinessExpenseStatus,
  businessExpenseStatusFormat,
} from '@compt/types/business-expenses/business-expense-statuses';

// Components
import {SimpleAction, SimpleActionMenu} from '@compt/common/compt-table/simple-action-menu';
import {ComptPill} from '@compt/common/forms/compt-pill/compt-pill';

export class BusinessExpenseTableController {
  getColumnDefinition(
    onActionMenuClicked: (expense: BusinessExpense, action: 'EDIT' | 'VIEW' | 'DELETE') => void,
  ) {
    const columnDefinition = {
      expense_type: {
        id: 'expense_type',
        name: 'Expense type',
        grow: 0.75,
        selector: (expense: BusinessExpense) => (
          <p data-tag="allowRowEvents">{expenseTypeFormats[expense.expense_type]}</p>
        ),
        order: 1,
        omit: false,
      },
      vendor: {
        id: 'vendor',
        name: 'Vendor/Mileage',
        grow: 0.75,
        selector: (expense: BusinessExpense) => (
          <p data-tag="allowRowEvents">
            {expense.expense_type === BusinessExpenseType.MILEAGE && expense.distance
              ? formatWithThousandsSeparator(parseFloat(expense.distance), 1)
              : expense.vendor
              ? (expense.vendor as string)
              : '-'}
          </p>
        ),
        order: 2,
        omit: false,
      },
      amount_of_expense: {
        id: 'amount_of_expense',
        name: 'Amount',
        grow: 0.25,
        selector: (expense: BusinessExpense) => (
          <p data-tag="allowRowEvents">
            {formatCurrencyFromCountryCode(expense.amount_of_expense, 'US')}
          </p>
        ),
        order: 3,
        omit: false,
      },
      receipt_date: {
        id: 'receipt_date',
        name: 'Expense date',
        grow: 0.5,
        selector: (expense: BusinessExpense) => (
          <p data-tag="allowRowEvents">
            {formattedDate(expense.receipt_date, DATE_FORMAT_OPTION['month dd yyyy']) ||
              formattedDate(expense.start_date, DATE_FORMAT_OPTION['month dd yyyy'])}
          </p>
        ),
        order: 4,
        omit: false,
      },
      description: {
        id: 'description',
        name: 'Expense summary',
        grow: 2,
        selector: (expense: BusinessExpense) => (
          <p data-tag="allowRowEvents">{expense.description}</p>
        ),
        format: (expense: BusinessExpense) => {
          if (expense.description.length < 50) {
            return expense.description;
          }

          return `${expense.description.slice(0, 50)}...`;
        },
        order: 6,
        omit: false,
      },
      status: {
        id: 'status',
        name: 'Status',
        grow: 1,
        order: 5,
        selector: (expense: BusinessExpense) => {
          // Hide manager statuses for employees
          const expenseStatus =
            expense.status === BusinessExpenseStatus.APPROVED_BY_MANAGER
              ? BusinessExpenseStatus.SUBMITTED
              : expense.status;

          const statusFormat = businessExpenseStatusFormat[expenseStatus];

          return (
            <ComptPill pillType={statusFormat.statusStyle} data-tag="allowRowEvents">
              {statusFormat.statusName}
            </ComptPill>
          );
        },
        omit: false,
      },
      action: {
        id: 'action',
        ignoreRowClick: true,
        compact: true,
        selector: (expense: BusinessExpense) =>
          this.renderActionSelector(expense, onActionMenuClicked),
        grow: 0,
        order: 7,
      },
    };

    return columnDefinition;
  }

  renderActionSelector(
    expense: BusinessExpense,
    onActionMenuClicked: (expense: BusinessExpense, action: 'EDIT' | 'VIEW' | 'DELETE') => void,
  ) {
    const _onMenuClicked = (expense: BusinessExpense, action: 'VIEW' | 'EDIT' | 'DELETE') => {
      onActionMenuClicked(expense, action);
    };
    const actions: Array<SimpleAction<BusinessExpense>> = [
      {
        label: 'View',
        onClick: (expense) => _onMenuClicked(expense, 'VIEW'),
      },
    ];

    if (expense.status === BusinessExpenseStatus.OPEN) {
      actions.push({
        label: 'Edit',
        onClick: (expense) => _onMenuClicked(expense, 'EDIT'),
      });
      actions.push({
        label: 'Delete',
        onClick: (expense) => _onMenuClicked(expense, 'DELETE'),
        textColor: 'text-red-600',
      });
    }

    return <SimpleActionMenu relatedActionItem={expense} actions={actions} />;
  }
}

import React from 'react';

// Hooks and methods
import DOMPurify from 'dompurify';

// Types
import {RequestFieldType} from '@compt/types/learning-development/learning-development-program';
import {EmployeePreApprovalRequest} from '@compt/types/learning-development/pre-approval-request';

// Components
import {ProgramActivity} from './program-activity';
import {RequestCustomFields, RequestDataRow} from './request-data-table';
import {ComptSidePanel} from '@compt/common/compt-side-panel/compt-side-panel';
import {ComptSvgIcon} from '@compt/common/compt-svg-icon/compt-svg-icon';
import {ComptRejectionReason} from '@compt/common/compt-rejection-reason/compt-rejection-reason';
import {FileExtensionButton} from '@compt/common/compt-file-extension-button/compt-file-extension-button';
import {ComptButton, ComptButtonType} from '@compt/common/compt-button/compt-button';

export interface ReimbursementSidePanelDetailsTabProps {
  preApprovalRequest: EmployeePreApprovalRequest;
  preApprovalRequestReceiptUrl: string;
  preApprovalRequestSupportingDocumentsUrls?: string[];
  onCloseClicked: () => void;
}

export const ReimbursementSidePanelDetailsTab = ({
  preApprovalRequest,
  preApprovalRequestReceiptUrl,
  preApprovalRequestSupportingDocumentsUrls,
  onCloseClicked,
}: ReimbursementSidePanelDetailsTabProps) => (
  <>
    <ComptSidePanel.Content className="sm:overflow-y-auto">
      <div className="h-full flex flex-col p-6">
        <div>
          <h4>{preApprovalRequest.program.name}</h4>
          <p
            className="body3 text-color-body1 inner-html"
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(preApprovalRequest.program.description),
            }}
          />
        </div>
        <hr className="my-6 border-gray-300" />
        <ProgramActivity preApprovalRequest={preApprovalRequest} />

        <div>
          <div className="flex flex-row gap-x-2 items-center mb-4">
            <ComptSvgIcon iconName="message-text-icon" />
            <h4>Request form</h4>
          </div>
          {preApprovalRequest.rejection_reason && (
            <ComptRejectionReason rejectionReason={preApprovalRequest.rejection_reason} />
          )}

          <table className="table-auto border-collapse mb-6">
            <thead>
              <th className="text-left label3 text-color-body1">Items</th>
              <th className="text-left label3 text-color-body1">Selection</th>
            </thead>
            <tbody>
              <RequestCustomFields
                configurationFields={preApprovalRequest?.pre_approval_configuration.request_fields}
                data={preApprovalRequest?.request_data}
                currency={preApprovalRequest.currency}
              />
            </tbody>
          </table>
          {preApprovalRequest.expense && (
            <>
              <div className="flex flex-row gap-x-2 items-center mb-4">
                <ComptSvgIcon iconName="message-text-icon" />
                <h4>Reimbursement form</h4>
              </div>
              {preApprovalRequest.expense.rejection_reason && (
                <ComptRejectionReason
                  rejectionReason={preApprovalRequest.expense.rejection_reason}
                />
              )}
              <table className="table-auto border-collapse mb-6">
                <thead>
                  <th className="text-left label3 text-color-body1">Items</th>
                  <th className="text-left label3 text-color-body1">Selection</th>
                </thead>
                <tbody>
                  <RequestDataRow
                    fieldName="Vendor name"
                    fieldValue={preApprovalRequest.expense.vendor_name}
                    fieldType={RequestFieldType.SHORT_TEXT}
                  />
                  <RequestDataRow
                    fieldName="Amount"
                    fieldValue={preApprovalRequest.expense.amount_of_expense}
                    fieldType={RequestFieldType.CURRENCY}
                    currency={preApprovalRequest.currency}
                  />
                  <RequestDataRow
                    fieldName="Date of expense"
                    fieldValue={preApprovalRequest.expense.date_of_expense}
                    fieldType={RequestFieldType.DATE}
                  />
                  <RequestDataRow
                    fieldName="Description and reason"
                    fieldValue={preApprovalRequest.expense.description}
                    fieldType={RequestFieldType.LONG_TEXT}
                  />
                  <RequestCustomFields
                    configurationFields={preApprovalRequest?.program?.custom_expense_fields}
                    data={preApprovalRequest?.expense?.custom_field_values}
                    currency={preApprovalRequest.currency}
                  />
                </tbody>
              </table>
            </>
          )}
          <div className="pb-4">
            <div className="flex flex-row gap-x-2 items-center mb-4">
              <ComptSvgIcon iconName="upload-cloud-icon" />
              <h4>Request form documents</h4>
            </div>
            <div className="grid gap-y-2">
              <FileExtensionButton fileUrl={preApprovalRequestReceiptUrl} />
              {preApprovalRequestSupportingDocumentsUrls
                ? preApprovalRequestSupportingDocumentsUrls.map((url) => (
                    <FileExtensionButton fileUrl={url} key={url} />
                  ))
                : null}
              {preApprovalRequest.expense && (
                <>
                  <div className="flex flex-row gap-x-2 items-center mt-6 pt-6 mb-3 border-t">
                    <ComptSvgIcon iconName="upload-cloud-icon" />
                    <h4>Reimbursement form documents</h4>
                  </div>
                  <FileExtensionButton fileUrl={preApprovalRequest.expense.receipt_image} />
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </ComptSidePanel.Content>
    <ComptSidePanel.Footer>
      <ComptButton
        buttonType={ComptButtonType.OUTLINED}
        onClick={onCloseClicked}
        onKeyDown={(e: React.KeyboardEvent<HTMLButtonElement>) => {
          e.key === 'Enter' && e.preventDefault();
        }}
      >
        Close
      </ComptButton>
    </ComptSidePanel.Footer>
  </>
);

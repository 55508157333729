import React, {createContext, useContext, useMemo, useState} from 'react';

// Hooks and methods
import {ComptTableController as controller} from '@compt/common/compt-table/compt-table.controller';
import {generateLocalStorageKey, useCacheableObjectState} from '@compt/utils/local-storage-helper';

// Types
import {ColumnDefinition, ComptTableColumn} from '@compt/common/compt-table/compt-table.types';

// Components
import {TableColumn} from 'react-data-table-component';

interface LeanComptTableContextProps<T> {
  currentPage: number;
  setCurrentPage: React.Dispatch<React.SetStateAction<number>>;
  visibleColumns: {
    [key: string]: boolean;
  };
  setVisibleColumns: React.Dispatch<React.SetStateAction<Record<string, boolean>>>;
  columnDefinition: ColumnDefinition<T>;
  columnList: ComptTableColumn<unknown>[];
  filteredColumnList: TableColumn<unknown>[];
  editMode?: boolean;
  setEditMode?: React.Dispatch<React.SetStateAction<boolean>>;
}

const LeanComptTableContext = createContext<LeanComptTableContextProps<unknown>>({
  currentPage: 0,
  setCurrentPage: () => null,
  visibleColumns: {},
  setVisibleColumns: () => null,
  columnDefinition: {},
  columnList: [],
  filteredColumnList: [],
});

interface ContextProviderProps<T> {
  columnDefinition?: ColumnDefinition<T>;
  children: React.ReactNode;
  tableId: string;
  editMode?: boolean;
  setIsEditMode?: React.Dispatch<React.SetStateAction<boolean>>;
}

export const LeanComptTableContextProvider = <T,>(props: ContextProviderProps<T>) => {
  const [currentPage, setCurrentPage] = useState(1);

  const columnList = controller.getColumnsList(props.columnDefinition as ColumnDefinition<unknown>);
  const initialVisibleColumns = controller.getVisibleColumns(columnList);

  const [visibleColumns, setVisibleColumns] = useCacheableObjectState(
    generateLocalStorageKey(props.tableId, 'visible-columns'),
    initialVisibleColumns,
  );

  const filteredColumnList = useMemo(
    () => controller.getFilteredColumnList(columnList, visibleColumns),
    [visibleColumns],
  );

  return (
    <LeanComptTableContext.Provider
      value={{
        currentPage,
        setCurrentPage,
        visibleColumns,
        setVisibleColumns,
        columnDefinition: (props.columnDefinition as ColumnDefinition<unknown>) ?? {},
        columnList,
        filteredColumnList,
        editMode: props.editMode,
        setEditMode: props.setIsEditMode,
      }}
    >
      {props.children}
    </LeanComptTableContext.Provider>
  );
};

export const useLeanComptTableContext = (): LeanComptTableContextProps<unknown> => {
  const context = useContext(LeanComptTableContext);
  if (!context) {
    throw new Error('useComptTableContext must be used within a ComptPageContextProvider');
  }
  return context;
};

import React, {ReactNode} from 'react';

// Hooks and methods
import {ComptTableController as controller} from './compt-table.controller';
import {useLeanComptTableContext} from '../lean-compt-table/lean-compt-table-context';

// Types
import {SelectionChangeValues} from '../compt-filter-bar/compt-filter-bar.types';

// Components
import {SelectFilter} from '../compt-filter-bar/compt-select-filter';

interface ComptTableBarProps {
  children: ReactNode;
  showColumnFilter?: boolean;
}

export const ComptTableBar = (props: ComptTableBarProps) => {
  const {visibleColumns, columnDefinition, columnList, setVisibleColumns} =
    useLeanComptTableContext();

  return (
    <div className="flex justify-between py-3 px-4 border-b">
      {props.children && <div className="sm:w-1/2 self-center">{props.children}</div>}
      {props.showColumnFilter && (
        <div className="col align-self-center">
          <div className="flex flex-1 justify-end">
            <SelectFilter
              label="Show/hide columns"
              currentValues={controller.getSelectedColumnOptions(columnDefinition, visibleColumns)}
              options={controller.getColumnOptions(columnList)}
              handleChange={(change: SelectionChangeValues) => {
                controller.handleColumnsChange(change, visibleColumns, setVisibleColumns);
              }}
            />
          </div>
        </div>
      )}
    </div>
  );
};

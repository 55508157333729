import React, {useEffect} from 'react';

// Types
import {UseFormReturn} from 'react-hook-form';
import {
  RequestField,
  RequestType,
} from '@compt/types/learning-development/learning-development-program';
import {SupportedCountriesType} from '@compt/utils/international-helpers';
import {EligibilityBreakdownOption} from '@compt/types/learning-development/eligibility-breakdown.types';
import {EmployeePreApprovalRequest} from '@compt/types/learning-development/pre-approval-request';
import {ProgramSummary} from '@compt/types/learning-development/common-types';

// Components
import {ComputedAmountSection} from '@compt/pages/learning-page/components/request-form-side-panel/computed-amount-section';
import {DynamicFormContent} from './dynamic-form-content';

interface RequestFormContentProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  formMethods: UseFormReturn<any>;
  requestFields: RequestField[];
  requestType: RequestType;
  isPreview?: boolean;
  userCountryCode?: SupportedCountriesType;
  program: ProgramSummary;
  preApprovalRequest?: EmployeePreApprovalRequest;
  programCurrency?: string;
  maxAmount?: number;
}

export const RequestFormContent = (props: RequestFormContentProps) => {
  const {formMethods} = props;

  // Populate fields with existing data
  useEffect(() => {
    if (!props.preApprovalRequest?.request_data) return;

    const receiptKeyValue =
      props.requestType === RequestType.PRE_APPROVAL
        ? props.preApprovalRequest?.request_data['Upload document(s)']
        : null;

    const requestData = {
      ...props.preApprovalRequest.request_data,
      receipt_key: receiptKeyValue,
    };

    formMethods.reset(requestData);
  }, [props.preApprovalRequest?.request_data, formMethods]);

  const renderComputedAmountSection = () => {
    if (
      props.isPreview ||
      (props.requestType === RequestType.PRE_APPROVAL &&
        props.program.eligibility === EligibilityBreakdownOption.INDIVIDUAL_BUDGET)
    ) {
      return (
        <ComputedAmountSection
          maxAvailable={props.maxAmount?.toString()}
          currency={props.programCurrency}
          control={formMethods.control}
          overBudgetErrorMessage="This is over program budget. Please explain in your description below."
        />
      );
    }

    if (props.requestType === RequestType.REIMBURSEMENT) {
      if (!props.preApprovalRequest) {
        throw new Error('Must pass valid existing pre-approval request.');
      }
      const maxAvailableAmount: number = props.preApprovalRequest.request_data.Amount;
      if (!maxAvailableAmount) {
        // eslint-disable-next-line no-console
        console.warn('Did not get a valid max amount. Got: ', maxAvailableAmount);
        return null;
      }
      return (
        <ComputedAmountSection
          maxAvailable={`${maxAvailableAmount}`}
          currency={props.programCurrency}
          control={formMethods.control}
          overBudgetErrorMessage={
            'This is over the pre-approved amount. Please adjust the amount accordingly.'
          }
        />
      );
    }

    return null;
  };

  return (
    <>
      <DynamicFormContent
        fields={props.requestFields}
        formMethods={formMethods}
        requestType={props.requestType}
        requestStartDate={props.preApprovalRequest?.allotment?.start_date}
      />

      {renderComputedAmountSection()}
    </>
  );
};

import React, {useCallback, useEffect, useRef, useState} from 'react';

// RTK Queries
import {
  useCreateCommentMutation,
  useGetChannelCommentsQuery,
} from '@compt/app/services/api/lnd-comments-slice';

// Hooks and methods
import {FieldValues, useForm} from 'react-hook-form';
import {generateLocalStorageKey, useCacheableObjectState} from '@compt/utils/local-storage-helper';
import {triggerCustomToast} from '../compt-toaster/compt-toaster';

// Types
import {Comment} from '@compt/types/comments/comment';

// Components
import {ComptReceivedComment, ComptSentComment} from './compt-comment';
import {ComptSvgIcon} from '../compt-svg-icon/compt-svg-icon';
import {ComptButton, ComptButtonType} from '@compt/common/compt-button/compt-button';
import {ComptLoadingAnimation} from '../compt-loading/compt-loading-animation';

export interface CommentFieldValues extends FieldValues {
  comment_input: string;
}

export interface CommentBoxProps {
  channelId?: number;
  placeHolderText?: string;
}

export const ComptCommentBox = ({channelId, placeHolderText}: CommentBoxProps) => {
  const [draftComments, setDraftComments] = useCacheableObjectState<Record<number, string>>(
    generateLocalStorageKey('lnd-draft-comments', 'comments'),
    {},
  );

  const [offset, setOffset] = useState<number>(0);
  const [comments, setComments] = useState<Comment[]>([]);

  const {data, isLoading, isFetching, refetch} = useGetChannelCommentsQuery(
    {channelId, params: {offset}},
    {skip: !channelId},
  );

  const [createComment] = useCreateCommentMutation();

  const formMethods = useForm<CommentFieldValues>({
    defaultValues: {comment_input: channelId ? draftComments[channelId] || '' : ''},
  });

  const watchedCommentInput = formMethods.watch('comment_input');
  const chatEndRef = useRef<HTMLDivElement | null>(null);

  const setDraftComment = (draftComment: string) => {
    if (!channelId) return;
    setDraftComments((prev) => ({...prev, [channelId]: draftComment}));
  };

  // Load comments and persist drafts
  useEffect(() => {
    if (data?.results) {
      setComments((prevComments) => {
        const allComments = [
          ...data.results.filter((comment) => !prevComments.some((prev) => prev.id === comment.id)),
          ...prevComments,
        ];
        return allComments.sort((a, b) => b.id - a.id);
      });
    }

    if (channelId) {
      const channelDraft = draftComments[channelId] || '';
      formMethods.setValue('comment_input', channelDraft);
    }
  }, [data, channelId]);

  const loadMoreComments = useCallback(() => {
    if (data?.next && !isLoading && !isFetching) {
      const url = new URL(data.next);
      const newOffset = parseInt(url.searchParams.get('offset') || '0', 10);
      setOffset(newOffset);
    }
  }, [data]);

  const scrollToBottom = () => {
    if (chatEndRef.current) {
      chatEndRef.current.scrollIntoView({behavior: 'smooth'});
    }
  };

  const handleScroll = (e: React.UIEvent<HTMLDivElement>) => {
    const container = e.target as HTMLDivElement;
    if (container.scrollTop <= -Math.abs(container.scrollHeight - container.clientHeight)) {
      loadMoreComments();
    }
  };

  async function handleSubmit(form: CommentFieldValues) {
    if (!channelId) return;

    const submission = {channelId, message: form.comment_input};

    return createComment(submission).then((results) => {
      if ('data' in results) {
        setComments((prev) => [results.data, ...prev].sort((a, b) => b.id - a.id));
        setDraftComments((prev) => ({...prev, [channelId]: ''})); // Clear draft on successful submit
        formMethods.resetField('comment_input');
        scrollToBottom();
      } else if ('error' in results) {
        triggerCustomToast(
          'error',
          'There was a problem submitting your comment',
          'Please try again.',
        );
      }
      return results;
    });
  }

  return (
    <div className="flex flex-col w-full h-full border">
      <div
        onScroll={handleScroll}
        id="Commentbox"
        className="flex w-full overflow-y-auto gap-y-4 py-5 px-6 flex-1 flex-col-reverse basis-0"
      >
        {/* Chat end ref is at the top due to flex-col-reverse */}
        <div ref={chatEndRef} />
        <div className="flex flex-col items-center">
          <ComptButton
            buttonType={ComptButtonType.PRIMARY}
            disabled={isLoading || isFetching}
            onClick={refetch}
            className="shadow-lg"
          >
            Refresh feed
          </ComptButton>
        </div>
        {comments.length > 0
          ? comments.map((message) =>
              message.is_author ? (
                <ComptSentComment
                  key={message.id}
                  name={message.author.full_name}
                  date={message.created_on}
                  message={message.message}
                  messageType={message.comment_type}
                  status={message.new_status}
                />
              ) : (
                <ComptReceivedComment
                  key={message.id}
                  name={message.author.full_name}
                  date={message.created_on}
                  message={message.message}
                  messageType={message.comment_type}
                  status={message.new_status}
                />
              ),
            )
          : null}
        {(isLoading || isFetching) && (
          <div className="flex flex-col items-center mt-6">
            <ComptLoadingAnimation />
            <p className="label3 text-color-body1 mt-2">Loading comments...</p>
          </div>
        )}
        {!isLoading && !isFetching && comments.length === 0 && (
          <div className="flex flex-col items-center justify-center h-full">
            <ComptSvgIcon iconName="empty-img-icon" className="mb-6" />
            <p className="heading4 text-center">No comments exist yet!</p>
          </div>
        )}
      </div>
      <form
        className="flex w-full items-center px-6 py-4 border-t bg-surface-background"
        onSubmit={formMethods.handleSubmit(handleSubmit)}
      >
        <input
          className={`w-full border-stroke-divider1 rounded-lg mr-4 ring-stroke-divider1
             focus:border-stroke-focus focus:ring-1 focus:ring-stroke-focus`}
          type="text"
          placeholder={placeHolderText}
          autoComplete="off"
          {...formMethods.register('comment_input', {
            onChange: (e) => setDraftComment(e.target.value),
          })}
        />
        <button
          type="submit"
          disabled={!watchedCommentInput || watchedCommentInput.trim() === ''}
          className={`rounded-full bg-stroke-primary p-3 active:opacity-90 
          ring-inset ring-1 focus:ring-stroke-focus disabled:bg-stroke-disabled
          disabled:ring-stroke-disabled`}
        >
          <ComptSvgIcon iconName="paper-plane-icon" />
        </button>
      </form>
    </div>
  );
};

import React, {Dispatch, SetStateAction, useEffect} from 'react';

// Hooks and methods
import {useForm} from 'react-hook-form';

// Components
import {ComptRejectionModal} from '@compt/common/compt-rejection-modal/compt-rejection-modal';

// Types
import {PreApprovalRequest} from '@compt/types/learning-development/pre-approval-request';
import {LearningRequestStatus} from '@compt/types/learning-development/learning-request-status';
import {RejectionReasonForm} from '@compt/types/common/rejection-reason-form';

interface RejectPreApprovalRequestModalProps {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  requestToReject: PreApprovalRequest | undefined;
  isUpdating: boolean;
  handleRequestStatusUpdate: (
    request: PreApprovalRequest,
    action: LearningRequestStatus,
    rejectionReason: string,
  ) => void;
}

export const RejectPreApprovalRequestModal = (props: RejectPreApprovalRequestModalProps) => {
  const {requestToReject, isOpen, setIsOpen, isUpdating} = props;

  const formMethods = useForm<RejectionReasonForm>();

  // Reset form when it is closed
  useEffect(() => {
    if (isOpen) return;
    formMethods.resetField('rejection_reason');
  }, [isOpen]);

  function onSubmitRejectionReason(form: RejectionReasonForm) {
    if (!requestToReject) return;

    props.handleRequestStatusUpdate(
      requestToReject,
      LearningRequestStatus.PRE_APPROVAL_REJECTED,
      form.rejection_reason,
    );
  }

  return (
    <ComptRejectionModal
      formMethods={formMethods}
      modalTitle="Reject request"
      modalText="Please give a reason for rejecting this request"
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      isUpdating={isUpdating}
      onSubmitRejection={onSubmitRejectionReason}
    />
  );
};

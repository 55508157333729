import React, {Dispatch, SetStateAction, useEffect} from 'react';

// Hooks and methods
import {useForm} from 'react-hook-form';
import {triggerCustomToast} from '@compt/common/compt-toaster/compt-toaster';

// Types
import {CustomFieldFormFieldValues} from './custom-field-form.types';
import {
  LearningDevelopmentProgram,
  RequestField,
} from '@compt/types/learning-development/learning-development-program';

// Components
import {ComptSidePanel} from '@compt/common/compt-side-panel/compt-side-panel';
import {CustomFieldForm} from './custom-field-form';
import {ComptButton, ComptButtonType} from '@compt/common/compt-button/compt-button';
import {MutationTrigger} from '@reduxjs/toolkit/dist/query/react/buildHooks';

interface CustomFieldSidePanelProps {
  programData: LearningDevelopmentProgram;
  fieldData: RequestField | null;
  setFieldData: Dispatch<SetStateAction<RequestField | null>>;
  readOnly?: boolean;
  configurationId: number;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  updateRequestField: MutationTrigger<any>;
}

export const UpdateCustomFieldSidePanel = (props: CustomFieldSidePanelProps) => {
  const {fieldData, readOnly} = props;
  const isDefaultField = fieldData?.is_default;

  const formMethods = useForm<CustomFieldFormFieldValues>();

  useEffect(() => {
    if (fieldData) {
      formMethods.reset({
        field_name: fieldData.field_name,
        supporting_text: fieldData.supporting_text,
        is_required_field: fieldData.is_required ? 'Y' : 'N',
        field_type: fieldData.field_type,
      });
    }
  }, [fieldData]);

  function onSubmit(form: CustomFieldFormFieldValues) {
    if (!form.field_name || !form.field_type || !form.is_required_field || !props.fieldData) return;

    const submission = {
      programId: props.programData.id,
      companyId: props.programData.company.id,
      configurationId: props.configurationId,
      fieldId: props.fieldData.id,
      body: {
        field_name: form.field_name,
        supporting_text: form.supporting_text,
        field_type: form.field_type,
        is_required: form.is_required_field === 'Y',
      },
    };

    props.updateRequestField(submission).then((results) => {
      if ('error' in results) {
        triggerCustomToast('error', 'There was a problem creating your custom field');
        return;
      }

      formMethods.reset();
      props.setFieldData(null);
    });
  }

  function onCancel() {
    formMethods.reset({
      field_name: null,
      supporting_text: null,
      is_required_field: null,
      field_type: null,
    });
    props.setFieldData(null);
  }

  return (
    <ComptSidePanel open={!!props.fieldData}>
      <ComptSidePanel.Header title="Update pre-approval request field" setOpen={onCancel} />
      <ComptSidePanel.Content className="px-4 py-6 sm:px-6">
        <CustomFieldForm formMethods={formMethods} readOnly={!!isDefaultField || readOnly} />
      </ComptSidePanel.Content>
      <ComptSidePanel.Footer>
        {!isDefaultField && !readOnly && (
          <ComptButton onClick={formMethods.handleSubmit(onSubmit)}>Save</ComptButton>
        )}
        <ComptButton buttonType={ComptButtonType.BORDERLESS} onClick={onCancel}>
          Cancel
        </ComptButton>
      </ComptSidePanel.Footer>
    </ComptSidePanel>
  );
};

import {StipendExpense} from '../stipend-expense';
import {ListResultsBase} from '../common/list-results';
import {PerkCategory} from '../perk-category';
import {AllotmentCycle} from '../allotments';
import {SupportedCountriesType} from '@compt/utils/international-helpers';
import {DateString} from '../common/date-string';
import {UserSession} from '../account';

enum ReceiptOCRStatus {
  PROCESSING = 'Processing',
  FLAGGED = 'Flagged',
  SUCCESS = 'Success',
}

export interface ExpenseReview extends Omit<StipendExpense, 'perk_category'> {
  auto_approve_rule_id: number | null;
  ocr_status: ReceiptOCRStatus | null;
  country: SupportedCountriesType;
  user: UserSession['user_id'];
  full_name: string;
  first_name: string;
  last_name: string;
  submitted_date: DateString;
  stipend_name: AllotmentCycle['name'];
  perk_category: PerkCategory;
}

export interface ExpenseReviewResults extends ListResultsBase<ExpenseReview> {
  cycle_names: AllotmentCycle['name'][];
  perk_categories: PerkCategory['name'][];
  countries: SupportedCountriesType[];
}

export enum ReviewTabType {
  REMAINING = 'Remaining',
  SPENT = 'Spent',
  TOTAL = 'Total',
}

import React from 'react';

// Hooks and methods
import {twMerge} from 'tailwind-merge';

// Types
import {UseFormReturn} from 'react-hook-form';
import {ReviewFieldValues} from './review-field-values.types';
import {DEFAULT_CHAR_FIELD_MAX_LENGTH} from '@compt/constants';

// Components
import {ComptTextField} from '@compt/common/forms/compt-text-field/compt-text-field';
import {ComptRatingField} from '@compt/common/forms/compt-rating-field/compt-rating-field';
import {ComptTextAreaField} from '@compt/common/forms/compt-text-area-field/compt-text-area-field';
import {ComptKeywordInput} from '@compt/common/forms/compt-tag-inputs/compt-keyword-tag-input';

interface ReviewFormProps {
  formMethods: UseFormReturn<ReviewFieldValues>;
  readOnly?: boolean;
}

export const ReviewForm = (props: ReviewFormProps) => {
  const {formMethods} = props;
  const watchedRatingDetail = formMethods.watch('rating_detail');

  return (
    <div className={twMerge(`h-full flex flex-col ${props.readOnly && 'gap-y-8'}`)}>
      <ComptTextField
        name="describe_purchased"
        label="What did you purchase or learn?"
        placeholder="e.g. title"
        register={formMethods.register}
        control={formMethods.control}
        errors={formMethods.formState.errors['describe_purchased']}
        validation={{required: 'Field is required'}}
        readOnly={props.readOnly}
        disabled={props.readOnly}
      />
      <ComptTextField
        name="course_url"
        label="Link"
        subLabel={
          props.readOnly ? '' : 'Please provide a link to the purchase for others to learn more.'
        }
        placeholder="https://"
        register={formMethods.register}
        control={formMethods.control}
        errors={formMethods.formState.errors['course_url']}
        validation={{required: 'Field is required'}}
        readOnly={props.readOnly}
        disabled={props.readOnly}
      />
      <ComptRatingField
        name="rating_number"
        label="How much would you recommend this purchase to others? (5 = highly recommended)"
        register={formMethods.register}
        control={formMethods.control}
        errors={formMethods.formState.errors['rating_number']}
        validation={{required: 'Field is required'}}
        readOnly={props.readOnly}
        disabled={props.readOnly}
      />
      <ComptTextAreaField
        name="rating_detail"
        label="Please provide more detail on your rating."
        rows={4}
        placeholder="Found this course to be extremely helpful in understanding AI."
        validation={{
          required: 'Field is required',
          maxLength: {
            value: DEFAULT_CHAR_FIELD_MAX_LENGTH,
            message: `Field has max ${DEFAULT_CHAR_FIELD_MAX_LENGTH} characters`,
          },
        }}
        register={formMethods.register}
        control={formMethods.control}
        watchedValue={watchedRatingDetail}
        errors={formMethods.formState.errors['rating_detail']}
        readOnly={props.readOnly}
        disabled={props.readOnly}
        maxLength={props.readOnly ? undefined : DEFAULT_CHAR_FIELD_MAX_LENGTH}
      />
      <ComptKeywordInput
        name="keywords"
        label="What keywords describe this experience?"
        placeholder="Enter keywords"
        control={formMethods.control}
        register={formMethods.register}
        setError={formMethods.setError}
        clearError={formMethods.clearErrors}
        getValues={formMethods.getValues}
        validation={{
          required: 'Field is required',
        }}
        errors={formMethods.formState.errors.keywords}
        helperText="Separate each keyword with a comma."
        disabled={props.readOnly}
      />
    </div>
  );
};

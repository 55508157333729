import React from 'react';

// Types
import {
  EmployeePreApprovalRequest,
  getCustomFieldURLKey,
} from '@compt/types/learning-development/pre-approval-request';
import {
  RequestConfiguration,
  RequestFieldType,
} from '@compt/types/learning-development/learning-development-program';
import {RequestDataFieldKeys} from '@compt/types/learning-development/learning-type-consts';

// Hooks and methods
import {formatCurrency} from '@compt/utils/international-helpers';
import {DATE_FORMAT_OPTION, formattedDate} from '@compt/utils/date-helpers';

interface RequestCustomFieldsProps {
  configurationFields: RequestConfiguration['request_fields'] | null;
  data: {[key: string]: string | number | string[]};
  currency: EmployeePreApprovalRequest['currency'];
}
export const RequestCustomFields = ({
  configurationFields,
  data,
  currency,
}: RequestCustomFieldsProps) => {
  if (!configurationFields) return null;

  const sortedConfiguration = [...configurationFields].sort((a, b) => a.sequence - b.sequence);

  return (
    <>
      {sortedConfiguration.map((field) => {
        const fieldValue = data[field.field_name];

        if (
          [
            'Upload document(s)',
            getCustomFieldURLKey('Upload document(s)'),
            RequestDataFieldKeys.SupportingDocuments,
            getCustomFieldURLKey(RequestDataFieldKeys.SupportingDocuments),
          ].includes(field.field_name) ||
          Array.isArray(fieldValue)
        )
          return <></>;

        return (
          <RequestDataRow
            key={field.id}
            fieldName={field.field_name}
            fieldValue={fieldValue}
            fieldType={field.field_type}
            currency={currency}
          />
        );
      })}
    </>
  );
};

interface RequestDataRowProps {
  fieldName: string;
  fieldValue: string | number;
  fieldType: RequestFieldType;
  currency?: EmployeePreApprovalRequest['currency'];
}

export const RequestDataRow = (props: RequestDataRowProps) => {
  function getFormattedFieldValue() {
    if (!props.fieldValue) return '-';

    if (props.fieldType === RequestFieldType.CURRENCY && props.currency) {
      return formatCurrency(props.fieldValue, props.currency);
    }

    if (props.fieldType === RequestFieldType.DATE && typeof props.fieldValue === 'string') {
      return formattedDate(props.fieldValue, DATE_FORMAT_OPTION['mm/dd/yyyy']);
    }

    return props.fieldValue;
  }

  return (
    <tr className="border-b">
      <td className="py-2 pr-2 body2 text-left align-top w-1/3 break-words">{props.fieldName}</td>
      <td className="py-2 body2 text-left break-words">{getFormattedFieldValue()}</td>
    </tr>
  );
};

import {CommentListResults, Comment} from '@compt/types/comments/comment';
import {comptApiSlice, ComptRTKTags} from './api-slice';

const extendedComptApiSlice = comptApiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getChannelComments: builder.query<
      CommentListResults,
      {channelId?: number; params?: Record<string, unknown> | null}
    >({
      providesTags: [ComptRTKTags.CHANNEL_COMMENTS],
      // https://redux-toolkit.js.org/rtk-query/usage/cache-behavior
      keepUnusedDataFor: 60, //60s
      query: ({channelId, params}) => ({
        url: `comment_channels/${channelId}/comments`,
        params: {...params},
      }),
    }),
    createComment: builder.mutation<Comment, {channelId: number; message: string}>({
      invalidatesTags: [ComptRTKTags.CHANNEL_COMMENTS],
      query: (data: {channelId: number; message: string}) => ({
        url: `comment_channels/${data.channelId}/comments/`,
        method: 'POST',
        body: {message: data.message},
      }),
    }),
  }),
});

export const {useGetChannelCommentsQuery, useCreateCommentMutation} = extendedComptApiSlice;

import React from 'react';

// Types
import {UserSession} from '@compt/types/account';

// Hooks and methods
import {useIsMobileView} from '@compt/utils/mobile-helpers';

// Components
import {ComptBanner, ComptBannerType} from '../compt-banner/compt-banner';
import {ComptSvgIcon} from '../compt-svg-icon/compt-svg-icon';
import {ComptButton, ComptButtonSize, ComptButtonType} from '../compt-button/compt-button';

interface HijackBannerProps {
  userData: UserSession;
  handleReleaseHijack: () => void;
  loading: boolean;
}

export const HijackBanner = ({userData, handleReleaseHijack, loading}: HijackBannerProps) => {
  const isMobileView = useIsMobileView();

  return (
    <ComptBanner
      bannerType={ComptBannerType.WARNING}
      message={
        <div className="flex flex-row">
          {!isMobileView && (
            <div
              className={`grid place-items-center rounded-full border-black
            border-2 border-opacity-10 w-[36px] h-[36px]`}
            >
              <ComptSvgIcon
                iconName={'alert-circle-black-icon'}
                className={`grid place-items-center rounded-full border-black
                  border-2 border-opacity-30	w-[28px] h-[28px]`}
              />
            </div>
          )}
          {userData && (
            <div className="flex items-center">
              <span className="ml-2 align-middle">
                <b className="break-all">
                  You are currently working on behalf of {userData.email}.
                </b>
                Once finished, use the release button.
              </span>
            </div>
          )}
        </div>
      }
      renderAction={
        <ComptButton
          buttonType={ComptButtonType.OUTLINED_TRANSPARENT}
          onClick={() => handleReleaseHijack()}
          disabled={loading}
          size={ComptButtonSize.SMALL}
          className={`${isMobileView && 'mt-100'}`}
        >
          {loading ? 'RELEASING...' : 'RELEASE'}
        </ComptButton>
      }
    />
  );
};

import React, {HTMLAttributes} from 'react';
import DOMPurify from 'dompurify';
import comptColors from '@compt/styles/compt-colors';

// Hooks & methods
import {twMerge} from 'tailwind-merge';
import {useLeanComptTableContext} from './lean-compt-table-context';
import {useIsMobileView} from '@compt/utils/mobile-helpers';
import {ComptTableController as controller} from '@compt/common/compt-table/compt-table.controller';

// Components
import {ComptSvgIcon} from '@compt/common/compt-svg-icon/compt-svg-icon';
import {ComptMobilePagination} from '@compt/common/compt-mobile-pagination/compt-mobile-pagination';
import {ComptLoadingIndicator} from '@compt/common/compt-loading/compt-loading';
import {ComptPaginationBar} from '@compt/common/compt-pagination-bar/compt-pagination-bar';
import DataTable, {ConditionalStyles} from 'react-data-table-component';

// Types
import {MAX_EXPENSES_PER_PAGE} from '@compt/constants';

export interface LeanComptTableProps<T> extends HTMLAttributes<HTMLElement> {
  data: T[];
  noDataTitleText: string;
  noDataSubtitle?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onRowClicked?: (row: any) => void;
  stickyLastColumn?: boolean;
  fixedHeader?: boolean;
  showPagination?: boolean;
  totalCount: number;
  itemsPerPage: number;
  isDataLoading?: boolean;
  conditionalRowStyles?: ConditionalStyles<T>[];
  selectableRows?: boolean;
  onSelectedRowsChange?: ({selectedRows}: {selectedRows: unknown}) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  selectableRowDisabled?: (row: any) => boolean;
  clearSelectedRows?: boolean;
  mobilePaginationRef?: React.RefObject<HTMLDivElement>;
  desktopPaginationRef?: React.RefObject<HTMLDivElement>;
}

export const LeanComptTable = <T,>(props: LeanComptTableProps<T>) => {
  const isMobileView = useIsMobileView();

  const {currentPage, setCurrentPage, filteredColumnList} = useLeanComptTableContext();

  const NoDataComponent = (
    <div className="flex flex-col items-center m-40">
      <ComptSvgIcon iconName="empty-img-icon" className="mb-6" />
      <p
        className="heading4 inner-html text-center"
        dangerouslySetInnerHTML={{
          __html: DOMPurify.sanitize(props.noDataTitleText),
        }}
      />
      {props.noDataSubtitle}
    </div>
  );

  const customStyles = {
    headCells: {
      style: {
        backgroundColor: comptColors['surface-tint'],
        color: comptColors.gray['800'],
        fontSize: '14px',
        fontWeight: '600',
      },
    },
    cells: {
      style: {
        fontSize: '14px',
        color: comptColors.gray['700'],
      },
    },
    table: {
      style: {
        borderBottomRightRadius: '0.75rem',
      },
    },
  };

  const tableClassNames = [];

  if (props.onRowClicked) {
    tableClassNames.push('compt-table-clickable-rows');
  }

  // Default to true if stickyLastColumn is not defined
  if (props.stickyLastColumn ?? true) {
    tableClassNames.push('compt-table-sticky-last-column');
  }
  if (props.data.length === 0) {
    tableClassNames.push('compt-empty-data-table');
  }

  return (
    <div className="border rounded-xl">
      {props.children && <>{props.children}</>}
      <div className={twMerge(`rounded-xl ${props.className}`)}>
        <DataTable
          columns={filteredColumnList}
          style={{whiteSpace: 'normal'}}
          className={`compt-data-table ${tableClassNames.join(' ')}`}
          customStyles={customStyles}
          fixedHeader={props.fixedHeader ?? isMobileView}
          data={props.data}
          noDataComponent={NoDataComponent}
          pagination={props.showPagination}
          paginationTotalRows={props.totalCount}
          paginationComponent={(pageProps) => (
            <>
              {isMobileView ? (
                <div ref={props.mobilePaginationRef}>
                  <ComptMobilePagination
                    {...pageProps}
                    itemsPerPage={props.itemsPerPage || MAX_EXPENSES_PER_PAGE}
                    totalPages={controller.getTotalPages(
                      props.totalCount,
                      props.itemsPerPage || MAX_EXPENSES_PER_PAGE,
                    )}
                    currentPage={currentPage}
                    onChangePage={(page: number) => setCurrentPage(page)}
                  />
                </div>
              ) : (
                <div ref={props.desktopPaginationRef}>
                  <ComptPaginationBar
                    {...pageProps}
                    totalPages={controller.getTotalPages(
                      props.totalCount,
                      props.itemsPerPage || MAX_EXPENSES_PER_PAGE,
                    )}
                    currentPage={currentPage}
                    itemsPerPage={props.itemsPerPage as number}
                    onChangePage={(page: number) => setCurrentPage(page)}
                  />
                </div>
              )}
            </>
          )}
          paginationComponentOptions={{noRowsPerPage: true}}
          paginationPerPage={props.itemsPerPage}
          progressPending={props.isDataLoading}
          progressComponent={
            <div className="h-[400px] sm:h-[700px]">
              <ComptLoadingIndicator isLoading={true} dependentData={false} className="h-full" />
            </div>
          }
          paginationDefaultPage={currentPage}
          paginationServer
          sortServer
          onRowClicked={props?.onRowClicked}
          selectableRows={props.selectableRows}
          onSelectedRowsChange={props.onSelectedRowsChange}
          selectableRowDisabled={props.selectableRowDisabled}
          clearSelectedRows={props.clearSelectedRows}
          conditionalRowStyles={props?.conditionalRowStyles as ConditionalStyles<unknown>[]}
        />
      </div>
    </div>
  );
};

import {useGetAllotmentsQuery} from '@compt/app/services/api/allotments-slice';
import {useGetSessionQuery} from '@compt/app/services/api/api-slice';
import {useGetCompanyQuery} from '@compt/app/services/api/company-slice';
import {
  ComptButton,
  ComptButtonIcon,
  ComptButtonType,
} from '@compt/common/compt-button/compt-button';
import {ComptLoadingIndicator} from '@compt/common/compt-loading/compt-loading';
import {ComptPage} from '@compt/common/compt-page/compt-page';
import {skipToken} from '@reduxjs/toolkit/dist/query';
import React from 'react';
import {TeamRecognitionActivityFeed} from '../team-recognition-activity-feed';
import {Allotment, AllotmentCycleFundingMode} from '@compt/types/allotments';
import {NonMonetaryRecognitionCardContent} from '../recognition-card-content/non-monetary-recognition-card-content';
import {TeamRecognitionCardContent} from '../recognition-card-content/team-recognition-card-content';
import {useIsMobileView} from '@compt/utils/mobile-helpers';

interface TeamRecognitionLayoutProps {
  handleSelectAllotment: (allotment: Allotment) => void;
  setAllotmentToDescribe: React.Dispatch<React.SetStateAction<Allotment | null>>;
  setShowRecognitionForm: React.Dispatch<React.SetStateAction<boolean>>;
}

export const TeamRecognitionLayout = (props: TeamRecognitionLayoutProps) => {
  const isMobileView = useIsMobileView();

  const sessionQuery = useGetSessionQuery();
  const companyQuery = useGetCompanyQuery(sessionQuery.data?.user_id ?? skipToken);

  const userId = sessionQuery.data?.user_id;
  const userCountryCode = sessionQuery.data?.country;
  const allotmentQuery = useGetAllotmentsQuery(userId ?? skipToken);
  const teamBonusAllotments = allotmentQuery.data?.team_bonus_allotments;

  // If all monetary allotments have no balance and there are no non-monetary allotments, disable recognition button.
  const noBudgetsLeft = teamBonusAllotments?.every((m) => m.sub_type === 1 && m.balance === '0.00');

  if (isMobileView) {
    return null;
  }

  return (
    <>
      <ComptPage
        title="Team Recognition"
        subtitle="Recognize your teammates"
        className="grow px-600 pb-600 pt-6 h-full overflow-scroll"
        includeBottomHR={false}
        useDefaultMargins={false}
        action={
          <div className="mt-300 sm:mt-000">
            <ComptButton
              buttonType={ComptButtonType.PRIMARY}
              iconId={ComptButtonIcon.MEGAPHONE_WHITE}
              onClick={() => props.setShowRecognitionForm(true)}
              disabled={noBudgetsLeft}
            >
              Give Recognition
            </ComptButton>
          </div>
        }
      >
        <ComptLoadingIndicator isLoading={companyQuery.isLoading || allotmentQuery.isLoading}>
          {allotmentQuery.data && sessionQuery.data && companyQuery.data && (
            <TeamRecognitionActivityFeed
              company={companyQuery.data}
              userSession={sessionQuery.data}
            />
          )}
        </ComptLoadingIndicator>
      </ComptPage>
      <div className="w-[386px] min-w-[386px] border-l border-stroke-divider1 h-full overflow-scroll">
        <div className="flex flex-col">
          {teamBonusAllotments?.map((allotment: Allotment) => {
            const isNonMonetary =
              allotment.cycle.funding_mode === AllotmentCycleFundingMode.NoFunding;

            return (
              <div
                key={`${allotment.id}-card`}
                id={`allotment-${allotment.id}`}
                className="w-full border-b border-stroke-divider1"
              >
                {isNonMonetary ? (
                  <div className="non-monetary-card py-6 pl-6 pr-10">
                    <h2 className="mb-300">{allotment.cycle.name}</h2>
                    <NonMonetaryRecognitionCardContent
                      allotment={allotment}
                      userCountryCode={userCountryCode}
                      onFullDescriptionClicked={(allotment) =>
                        props.setAllotmentToDescribe(allotment)
                      }
                      onClick={() => props.handleSelectAllotment(allotment)}
                    />
                  </div>
                ) : (
                  <div className="team-recognition-card py-6 pl-6 pr-10">
                    <h2 className="mb-300">{allotment.cycle.name}</h2>
                    <TeamRecognitionCardContent
                      allotment={allotment}
                      userCountryCode={userCountryCode}
                      onFullDescriptionClicked={(allotment) =>
                        props.setAllotmentToDescribe(allotment)
                      }
                      disableOnClickWhenNoBalance
                      onClick={() => props.handleSelectAllotment(allotment)}
                    />
                  </div>
                )}
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};

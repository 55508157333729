import React, {Dispatch, SetStateAction} from 'react';

// Hooks and methods
import {DATE_FORMAT_OPTION, formattedDate} from '@compt/utils/date-helpers';
import {formatCurrencyFromCountryCode} from '@compt/utils/international-helpers';
import {formatWithThousandsSeparator} from '@compt/utils/business-expense-helpers';
import {selectionOptionValuesChangeHandler} from '@compt/common/compt-filter-bar/compt-filter-bar.utils';

// Components
import {SearchFilter} from '@compt/common/compt-filter-bar/compt-search-filter';
import {SelectFilter} from '@compt/common/compt-filter-bar/compt-select-filter';
import {ComptPill} from '@compt/common/forms/compt-pill/compt-pill';
import {NavLink} from 'react-router-dom';

// Types
import {MAX_BUSINESS_EXPENSES_PER_PAGE, REPORT_ID_PREFIX} from '@compt/constants';
import {
  BusinessExpense,
  BusinessExpenseType,
  expenseTypeFormats,
} from '@compt/types/business-expenses/business-expense';
import {
  FilterValues,
  FormattedFilterObject,
} from '@compt/common/compt-filter-bar/compt-filter-bar.types';
import {Pagination} from '@compt/common/compt-table/compt-table.types';
import {
  BusinessExpenseStatus,
  businessExpenseStatusFormat,
} from '@compt/types/business-expenses/business-expense-statuses';

export class AdminSearchBEPageController {
  static getInitialFilterValues() {
    const initialFilterValues = {
      textSearch: [''],
      status: [],
    };

    return initialFilterValues;
  }

  static getFilterConfiguration() {
    const statusOptions = Object.keys(BusinessExpenseStatus)
      .filter((key) => key !== BusinessExpenseStatus.OPEN) // Hide open filter from admins
      .map((key) => {
        const id = BusinessExpenseStatus[key as keyof typeof BusinessExpenseStatus];
        const name = businessExpenseStatusFormat[id].statusName;
        return {id, name};
      });

    const filterConfiguration = {
      textSearch: {
        filterType: SearchFilter,
        label: 'Search business expenses',
        options: [
          {
            id: 1,
            name: 'Business expense table search',
            placeholder: 'Search by employee name or email',
          },
        ],
        nonCollapsible: true,
      },
      status: {
        filterType: SelectFilter,
        label: 'Status',
        options: statusOptions,
        valuesChangeHandler: selectionOptionValuesChangeHandler,
        getKey: (object: unknown) => object,
      },
    };

    return filterConfiguration;
  }

  static getColumnDefinition(onActionClicked: (expense: BusinessExpense) => void) {
    const columnDefinition = {
      employee_name: {
        order: 1,
        id: 'employee_name',
        name: 'Employee name',
        selector: (expense: BusinessExpense) => <p>{expense.full_name}</p>,
        grow: 1,
      },
      report_id: {
        order: 2,
        id: 'report_id',
        name: 'Report number',
        selector: (expense: BusinessExpense) => (
          <NavLink
            to={`/review-programs/business-expenses/${expense.report_id}`}
            className="text-color-link hover:underline"
          >{`${REPORT_ID_PREFIX}-${expense.report_id}`}</NavLink>
        ),
        grow: 0.5,
      },
      report_title: {
        order: 3,
        id: 'report_title',
        name: 'Report title',
        selector: (expense: BusinessExpense) => (
          <NavLink
            to={`/review-programs/business-expenses/${expense.report_id}`}
            className="text-color-link hover:underline"
          >
            {expense.report.title}
          </NavLink>
        ),
        grow: 1.5,
      },
      date_submitted: {
        order: 4,
        id: 'date_submitted',
        name: 'Date submitted',
        selector: (expense: BusinessExpense) => (
          <p data-tag="allowRowEvents">
            {expense.report.submitted_date
              ? formattedDate(expense.report.submitted_date, DATE_FORMAT_OPTION['month dd yyyy'])
              : ''}
          </p>
        ),
        grow: 1,
      },
      expense_id: {
        order: 5,
        id: 'expense_id',
        name: 'Expense ID',
        selector: (expense: BusinessExpense) => <p data-tag="allowRowEvents">{expense.id}</p>,
        grow: 0.5,
      },
      expense_type: {
        order: 6,
        id: 'expense_type',
        name: 'Expense type',
        selector: (expense: BusinessExpense) => (
          <p data-tag="allowRowEvents">{expenseTypeFormats[expense.expense_type]}</p>
        ),
        grow: 1.5,
      },
      expense_amount: {
        order: 7,
        id: 'expense_amount',
        name: 'Amount',
        selector: (expense: BusinessExpense) => (
          <p data-tag="allowRowEvents">
            {formatCurrencyFromCountryCode(expense.amount_of_expense || 0, 'US')}
          </p>
        ),
        grow: 0.5,
      },
      status: {
        order: 8,
        id: 'status',
        name: 'Status',
        selector: (expense: BusinessExpense) => {
          const statusFormat = businessExpenseStatusFormat[expense.status];

          return (
            <ComptPill pillType={statusFormat.statusStyle} data-tag="allowRowEvents">
              {statusFormat.statusName}
            </ComptPill>
          );
        },
        grow: 1,
      },
      vendor: {
        order: 9,
        id: 'vendor',
        name: 'Vendor/Mileage',
        grow: 0.75,
        selector: (expense: BusinessExpense) => (
          <p data-tag="allowRowEvents">
            {expense.expense_type === BusinessExpenseType.MILEAGE && expense.distance
              ? formatWithThousandsSeparator(parseFloat(expense.distance), 1)
              : expense.vendor
              ? (expense.vendor as string)
              : '-'}
          </p>
        ),
        omit: false,
      },
      receipt_date: {
        order: 10,
        id: 'receipt_date',
        name: 'Receipt date',
        selector: (expense: BusinessExpense) => (
          <p data-tag="allowRowEvents">
            {formattedDate(expense.receipt_date, DATE_FORMAT_OPTION['month dd yyyy']) ||
              formattedDate(expense.start_date, DATE_FORMAT_OPTION['month dd yyyy'])}
          </p>
        ),
        omit: false,
        grow: 1,
      },
      origin: {
        order: 11,
        id: 'origin',
        name: 'Origin',
        selector: (expense: BusinessExpense) => <p data-tag="allowRowEvents">{expense.origin}</p>,
        grow: 1,
      },
      destination: {
        order: 12,
        id: 'destination',
        name: 'Destination',
        selector: (expense: BusinessExpense) => (
          <p data-tag="allowRowEvents">{expense.destination}</p>
        ),
        grow: 1,
      },
      start_date: {
        order: 13,
        id: 'start_date',
        name: 'Start date',
        grow: 1,
        selector: (expense: BusinessExpense) => (
          <p data-tag="allowRowEvents">
            {formattedDate(expense.start_date, DATE_FORMAT_OPTION['month dd yyyy'])}
          </p>
        ),
        omit: false,
      },
      end_date: {
        order: 14,
        id: 'end_date',
        name: 'End date',
        grow: 1,
        selector: (expense: BusinessExpense) => (
          <p data-tag="allowRowEvents">
            {formattedDate(expense.end_date, DATE_FORMAT_OPTION['month dd yyyy'])}
          </p>
        ),
        omit: false,
      },
      distance: {
        order: 15,
        id: 'distance',
        name: 'Distance (miles)',
        selector: (expense: BusinessExpense) => <p data-tag="allowRowEvents">{expense.distance}</p>,
        grow: 0.5,
      },
      reviewed_date: {
        order: 16,
        id: 'reviewed_date',
        name: 'Reviewed on',
        grow: 1,
        selector: (expense: BusinessExpense) => (
          <p data-tag="allowRowEvents">
            {formattedDate(expense.reviewed_on, DATE_FORMAT_OPTION['month dd yyyy'])}
          </p>
        ),
        omit: false,
      },
      manager_approved_date: {
        order: 17,
        id: 'manager_approved_date',
        name: 'Manager approved on',
        grow: 1,
        selector: (expense: BusinessExpense) => (
          <p data-tag="allowRowEvents">
            {formattedDate(expense.manager_approval_on, DATE_FORMAT_OPTION['month dd yyyy'])}
          </p>
        ),
        omit: false,
      },
      finance_approved_date: {
        order: 18,
        id: 'finance_approved_date',
        name: 'Finance approved on',
        grow: 1,
        selector: (expense: BusinessExpense) => (
          <p data-tag="allowRowEvents">
            {formattedDate(expense.finance_approval_on, DATE_FORMAT_OPTION['month dd yyyy'])}
          </p>
        ),
        omit: false,
      },
      closed_date: {
        order: 19,
        id: 'closed_date',
        name: 'Closed on',
        grow: 1,
        selector: (expense: BusinessExpense) => (
          <p data-tag="allowRowEvents">
            {formattedDate(expense.closed_on, DATE_FORMAT_OPTION['month dd yyyy'])}
          </p>
        ),
        omit: false,
      },
      employee_email: {
        order: 20,
        id: 'employee_email',
        name: 'Employee email',
        selector: (expense: BusinessExpense) => <p data-tag="allowRowEvents">{expense.email}</p>,
        grow: 2,
      },
      expense_description: {
        order: 21,
        id: 'expense_description',
        name: 'Expense description',
        selector: (expense: BusinessExpense) => (
          <p data-tag="allowRowEvents">{expense.description}</p>
        ),
        grow: 3,
      },
      action: {
        order: 22,
        id: 'action',
        ignoreRowClick: true,
        compact: true,
        selector: (expense: BusinessExpense) => (
          <p
            className="text-color-link cursor-pointer hover:underline"
            onClick={() => onActionClicked(expense)}
          >
            View
          </p>
        ),
        grow: 0,
        disableRemoval: true,
      },
    };

    return columnDefinition;
  }

  static updateQueryParamsOnValuesChanged(
    companyId: number | undefined,
    setExpenseQueryValues: Dispatch<SetStateAction<FormattedFilterObject | undefined>>,
    setFiltersApplied: Dispatch<SetStateAction<boolean>>,
    pagination?: Pagination | null,
    filterValues?: FilterValues,
    ordering?: string | null,
  ) {
    if (!companyId) {
      return;
    }

    const formattedFilter = formatFilterValuesOptions(filterValues, pagination, ordering);

    setExpenseQueryValues(formattedFilter);
    if (
      Array.isArray(filterValues?.status) &&
      filterValues?.status.length &&
      filterValues.status.length > 0
    ) {
      setFiltersApplied(true);
    } else if (
      filterValues?.textSearch &&
      filterValues?.textSearch?.length > 0 &&
      filterValues?.textSearch[0].length > 0
    ) {
      setFiltersApplied(true);
    } else {
      setFiltersApplied(false);
    }
  }
}

const formatFilterValuesOptions = (
  filterValues?: FilterValues,
  pagination?: Pagination | null,
  ordering?: string | null,
) => {
  const formattedFilter: FormattedFilterObject = {};

  if (
    Array.isArray(filterValues?.status) &&
    filterValues?.status.length &&
    filterValues.status.length > 0
  ) {
    formattedFilter['status__in'] = filterValues?.status.map((status) => status.id).join(',');
  }

  if (filterValues?.textSearch && filterValues?.textSearch?.length > 0) {
    [formattedFilter['search']] = filterValues.textSearch;
  }

  // TODO: replace this
  formattedFilter.limit = MAX_BUSINESS_EXPENSES_PER_PAGE;

  if (pagination) {
    formattedFilter.offset = MAX_BUSINESS_EXPENSES_PER_PAGE * Math.max(pagination.page - 1, 0);
    formattedFilter.page = pagination.page;
  } else {
    formattedFilter.page = 1;
    formattedFilter.offset = 0;
  }

  if (ordering) {
    formattedFilter.ordering = ordering;
  }

  return formattedFilter;
};

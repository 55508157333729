import {useEffect} from 'react';

// RTK Queries
import {useGetAdminReviewingStatusRequestQuery} from '@compt/app/services/api/learning-development-slice';

// Hooks and methods
import {twMerge} from 'tailwind-merge';
import {useIsMobileView} from '@compt/utils/mobile-helpers';
import {useHasRequiredRole} from '@compt/utils/permission-helpers';

// Types
import {FeatureFlags} from '@compt/utils/feature-flags-helper';
import {USER_ROLES} from '@compt/utils/user-roles-helper';
import {PortalRoot} from '@compt/common/compt-portal/compt-portal';

// Components
import {Outlet, useLocation, useNavigate} from 'react-router-dom';
import {NavLinkTab} from '@compt/common/compt-tab-button/compt-tab-nav-link-button';
import {ComptRow} from '@compt/common/compt-row/compt-row';
import {Error404Page} from '@compt/pages/404-error-page/error-404-page';
import {ComptFeature} from '@compt/common/compt-feature/compt-feature';

export const AdminReviewProgramsPage = () => {
  const isMobileView = useIsMobileView();

  const navigate = useNavigate();
  const location = useLocation();
  const {pathname} = location;

  const isStipendReviewer = useHasRequiredRole([USER_ROLES.stipendReviewer]);
  const isBusinessExpenseReviewer = useHasRequiredRole([
    USER_ROLES.employeeManager,
    USER_ROLES.financeReviewer,
  ]);
  const isLearningReviewer = useHasRequiredRole([
    USER_ROLES.employeeManager,
    USER_ROLES.learningAndDevelopmentPrimaryReviewer,
    USER_ROLES.learningAndDevelopmentSecondaryReviewer,
  ]);

  // Default to particular tab based on user role
  useEffect(() => {
    if (pathname !== '/review-programs') return;

    if (isStipendReviewer) {
      navigate('/review-programs/stipend-claims');
      return;
    }

    if (isBusinessExpenseReviewer) {
      navigate('/review-programs/business-expenses');
      return;
    }

    if (isLearningReviewer) {
      navigate('/review-programs/professional-development');
      return;
    }
  }, [pathname]);

  const {data: reviewing_status_count} = useGetAdminReviewingStatusRequestQuery();

  if (!isStipendReviewer && !isBusinessExpenseReviewer && !isLearningReviewer) {
    return <Error404Page />;
  }

  return (
    <div className="flex w-full h-full">
      <div className="my-6 mt-4 flex flex-col overflow-y-auto w-full px-4 sm:px-10">
        <div className={twMerge('flex flex-col justify-between sm:flex-row')}>
          <div className="flex flex-col">
            <div className="flex gap-2 items-center">
              <h1 className={`${isMobileView ? 'display2' : 'display1'}`}>Review</h1>
            </div>
            <p className="body1 sm:mb-7">Manage stipends for employees</p>
          </div>
        </div>
        <div className="flex mb-6 items-center justify-between">
          <div className="flex gap-x-6">
            <ComptFeature featureFlag={FeatureFlags.CONSOLIDATE_PROGRAMS}>
              <>
                {isStipendReviewer && (
                  <NavLinkTab to="stipend-claims">
                    Stipend claims{' '}
                    {reviewing_status_count &&
                      reviewing_status_count?.stipend_claims_count > 0 &&
                      `(${reviewing_status_count.stipend_claims_count})`}
                  </NavLinkTab>
                )}
              </>
            </ComptFeature>
            {isBusinessExpenseReviewer && (
              <NavLinkTab to="business-expenses">
                Business expenses{' '}
                {reviewing_status_count &&
                  reviewing_status_count?.business_expenses_count > 0 &&
                  `(${reviewing_status_count.business_expenses_count})`}
              </NavLinkTab>
            )}
            {isLearningReviewer && (
              <NavLinkTab to="professional-development">
                Professional development{' '}
                {reviewing_status_count &&
                  reviewing_status_count?.learning_requests_count > 0 &&
                  `(${reviewing_status_count.learning_requests_count})`}
              </NavLinkTab>
            )}
          </div>
          {/* Hide date range filter in mobile view */}
          {!isMobileView && <div id={PortalRoot.DATE_RANGE} />}
        </div>
        <ComptRow className="pb-10 w-full">
          <Outlet />
        </ComptRow>
      </div>
    </div>
  );
};

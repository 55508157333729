import React from 'react';

// Components
import {ComptButtonIcon} from '../compt-button/compt-button';
import {ComptSvgIcon} from '../compt-svg-icon/compt-svg-icon';
import {CalendarDay} from './calendar-day';

interface Props {
  year: number;
  month: number;
  handlePrevMonthSelect?: () => void;
  handleNextMonthSelect?: () => void;
}

export const MonthCalendar = (props: Props) => {
  const days = generateCalendarDays(props.year, props.month);

  const monthName = new Date(props.year, props.month, 1).toLocaleString('default', {
    month: 'long',
    year: 'numeric',
  });

  return (
    <div className="px-6 py-5 w-full">
      <div className="flex items-center justify-between">
        {props.handlePrevMonthSelect && (
          <button className="justify-self-start" onClick={props.handlePrevMonthSelect}>
            <ComptSvgIcon iconName={ComptButtonIcon.CHEVRON_LEFT} />
          </button>
        )}
        <p className="label2 text-center flex-1">{monthName}</p>
        {props.handleNextMonthSelect && (
          <button onClick={props.handleNextMonthSelect}>
            <ComptSvgIcon iconName={ComptButtonIcon.CHEVRON_RIGHT} />
          </button>
        )}
      </div>
      <div className="grid grid-cols-[repeat(7,48px)] gap-y-1 items-center">
        {['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'].map((dayOfWeek) => (
          <div key={dayOfWeek} className="label3 text-center w-6 h-5 px-2 py-3 mt-2 mb-4">
            {dayOfWeek}
          </div>
        ))}
        {days.map((day, index) => {
          const inCurrentMonth = day.getMonth() === props.month;
          const isLeftEdge = index % 7 === 0;
          const isRightEdge = index % 7 === 6;

          return (
            <CalendarDay
              key={day.toISOString()}
              day={day}
              inCurrentMonth={inCurrentMonth}
              isLeftEdge={isLeftEdge}
              isRightEdge={isRightEdge}
            />
          );
        })}
      </div>
    </div>
  );
};

export function generateCalendarDays(year: number, month: number): Date[] {
  // 1) Start at the first day of the month
  const firstOfMonth = new Date(year, month, 1);
  const lastOfMonth = new Date(year, month + 1, 0); // 0 = last day of previous month

  // 2) Figure out what day of week the month starts on (0 = Sunday, 1 = Monday, etc.)
  const startDayOfWeek = firstOfMonth.getDay();
  const endDayOfWeek = lastOfMonth.getDay();

  // 3) We’ll display a 6-row calendar, so figure out the first day we show (Sunday of the first row)
  //    and the last day we show (Saturday of the last row).
  //    - Move `firstOfMonth` back to the previous Sunday (or stay on Sunday if it's already Sunday)
  const firstDisplayDate = new Date(firstOfMonth);
  firstDisplayDate.setDate(firstDisplayDate.getDate() - startDayOfWeek);

  //    - Similarly, move `lastOfMonth` forward to Saturday
  const lastDisplayDate = new Date(lastOfMonth);
  lastDisplayDate.setDate(lastDisplayDate.getDate() + (6 - endDayOfWeek));

  // 4) Now, collect each day in between in an array
  const days = [];
  const current = new Date(firstDisplayDate);
  while (current <= lastDisplayDate) {
    days.push(new Date(current));
    current.setDate(current.getDate() + 1);
  }

  return days; // This will be 42 days (6 weeks * 7 days)
}

import {useMemo} from 'react';
import {useFilterContext} from '@compt/common/compt-filter-sidebar/filter-context';

export const useNoDataText = (
  noDataExistsTitle: string,
  filtersAppliedTitle: string,
  noDataExistsSubText?: string,
  filtersAppliedSubText?: string,
) => {
  const {filters} = useFilterContext();

  const filtersApplied = useMemo(
    () => Object.values(filters).some((values) => values && values.length > 0),
    [filters],
  );

  if (filtersApplied) {
    return {title: filtersAppliedTitle, subText: filtersAppliedSubText ?? ''};
  }

  return {title: noDataExistsTitle, subText: noDataExistsSubText ?? ''};
};

import React, {useMemo} from 'react';

// Hooks and methods
import {getTomorrowDateString, getUTCDateFromString} from '@compt/utils/date-helpers';

// Types
import {
  RequestField,
  RequestFieldType,
  RequestType,
} from '@compt/types/learning-development/learning-development-program';
import {UseFormReturn} from 'react-hook-form';
import {DEFAULT_CHAR_FIELD_MAX_LENGTH} from '@compt/constants';
import {DateString} from '@compt/types/common/date-string';

// Components
import {ComptCurrencyField} from '@compt/common/forms/compt-currency-field/compt-currency-field';
import {ComptDatePickerField} from '@compt/common/forms/compt-date-picker-field/compt-date-picker-field';
import {ComptTextAreaField} from '@compt/common/forms/compt-text-area-field/compt-text-area-field';
import {ComptTextField} from '@compt/common/forms/compt-text-field/compt-text-field';

interface DynamicFormContentProps {
  fields: RequestField[];
  formMethods: UseFormReturn;
  requestType: RequestType;
  requestStartDate: DateString | undefined;
  isReadOnly?: boolean;
}

export const DynamicFormContent = (props: DynamicFormContentProps) => {
  const {fields} = props;

  const sortedFields = useMemo(
    () => [...fields].sort((a, b) => a.sequence - b.sequence).filter((field) => field.is_active),
    [fields],
  );

  return (
    <div>
      {sortedFields.map((field) => (
        <DynamicFormField
          key={field.id}
          fieldType={field.field_type}
          fieldName={field.field_name}
          supportingText={field.supporting_text}
          required={field.is_required || field.is_default}
          formMethods={props.formMethods}
          requestType={props.requestType}
          requestStartDate={props.requestStartDate}
          isReadOnly={props.isReadOnly}
        />
      ))}
    </div>
  );
};

interface DynamicFormFieldProps {
  fieldName: string;
  fieldType: RequestFieldType;
  required: boolean;
  formMethods: UseFormReturn;
  requestType: RequestType;
  requestStartDate: DateString | undefined;
  isReadOnly?: boolean;
  supportingText?: string | null;
}

export const DynamicFormField = (props: DynamicFormFieldProps) => {
  function startDate() {
    if (props.requestType === RequestType.PRE_APPROVAL) {
      return getUTCDateFromString(getTomorrowDateString()) || null;
    }

    return getUTCDateFromString(props.requestStartDate) || null;
  }

  if (props.fieldType === RequestFieldType.SHORT_TEXT) {
    const watchedValue = props.formMethods.watch(props.fieldName);

    return (
      <ComptTextField
        name={props.fieldName}
        label={props.fieldName}
        subLabel={props.supportingText ?? ''}
        control={props.formMethods.control}
        register={props.formMethods.register}
        maxLength={DEFAULT_CHAR_FIELD_MAX_LENGTH}
        watchedValue={watchedValue}
        errors={props.formMethods.formState.errors[props.fieldName]}
        validation={{required: props.required && `${props.fieldName} is required`}}
        disabled={props.isReadOnly}
      />
    );
  }

  if (props.fieldType === RequestFieldType.LONG_TEXT) {
    const watchedValue = props.formMethods.watch(props.fieldName);

    return (
      <ComptTextAreaField
        name={props.fieldName}
        label={props.fieldName}
        subLabel={props.supportingText ?? ''}
        register={props.formMethods.register}
        maxLength={DEFAULT_CHAR_FIELD_MAX_LENGTH}
        watchedValue={watchedValue}
        rows={5}
        errors={props.formMethods.formState.errors[props.fieldName]}
        validation={{
          required: props.required && `${props.fieldName} is required`,
          maxLength: {
            value: DEFAULT_CHAR_FIELD_MAX_LENGTH,
            message: `Description has max ${DEFAULT_CHAR_FIELD_MAX_LENGTH} characters`,
          },
        }}
        disabled={props.isReadOnly}
      />
    );
  }

  if (props.fieldType === RequestFieldType.CURRENCY) {
    return (
      <ComptCurrencyField
        name={props.fieldName}
        label={props.fieldName}
        subLabel={props.supportingText ?? ''}
        control={props.formMethods.control}
        register={props.formMethods.register}
        errors={props.formMethods.formState.errors[props.fieldName]}
        validation={{required: props.required && `${props.fieldName} is required`}}
        disabled={props.isReadOnly}
      />
    );
  }

  if (props.fieldType === RequestFieldType.DATE && props.fieldName === 'Expected completion date') {
    const label =
      props.requestType === RequestType.PRE_APPROVAL ? props.fieldName : 'Completion date';

    return (
      <ComptDatePickerField
        name={props.fieldName}
        label={label}
        subLabel={props.supportingText ?? ''}
        control={props.formMethods.control}
        register={props.formMethods.register}
        validation={{required: 'Expected completion date is required'}}
        allowFutureDates
        startDate={startDate()}
        errors={props.formMethods.formState.errors[props.fieldName]}
        disabled={props.isReadOnly}
      />
    );
  }

  if (props.fieldType === RequestFieldType.DATE) {
    return (
      <ComptDatePickerField
        name={props.fieldName}
        label={props.fieldName}
        subLabel={props.supportingText ?? ''}
        control={props.formMethods.control}
        register={props.formMethods.register}
        errors={props.formMethods.formState.errors[props.fieldName]}
        validation={{required: props.required && `${props.fieldName} is required`}}
        disabled={props.isReadOnly}
        allowFutureDates
      />
    );
  }

  return null;
};

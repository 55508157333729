import React, {Dispatch, SetStateAction, useMemo} from 'react';

// RTK queries
import {skipToken} from '@reduxjs/toolkit/dist/query';
import {useGetSessionQuery} from '@compt/app/services/api/api-slice';
import {useGetCompanyQuery} from '@compt/app/services/api/company-slice';
import {useGetAllAdminPreApprovalRequestsQuery} from '@compt/app/services/api/learning-development-slice';

// Hooks and methods
import {useSearchParams} from 'react-router-dom';
import {useFilterContext} from '@compt/common/compt-filter-sidebar/filter-context';
import {useNoDataText} from '@compt/common/compt-filter-sidebar/no-data-helper';
import {useDebounce} from '@uidotdev/usehooks';
import {useLeanComptTableContext} from '@compt/common/lean-compt-table/lean-compt-table-context';
import {useFormatFilterQuery} from '../../format-query-helper';

// Types
import {ProfessionalDevelopmentFilter} from './review-lnd-sidebar';

// Components
import {ReviewRequestSidePanel} from './review-lnd-request-side-panel';
import {LeanComptTable} from '@compt/common/lean-compt-table/lean-compt-table';
import {ComptTableBar} from '@compt/common/compt-table/compt-table-bar';
import {SearchFilter} from '@compt/common/compt-filter-bar/compt-search-filter';

// Types
import {MAX_15_PAGE_LIMIT} from '@compt/constants';

interface Props {
  editingMode: boolean;
  setEditingMode: Dispatch<SetStateAction<boolean>>;
}

export const ReviewLnDTable = (props: Props) => {
  const {filters, setFilters} = useFilterContext();
  const [searchParams, setSearchParams] = useSearchParams();
  const requestId = searchParams.get('request_id');

  const {currentPage, setCurrentPage} = useLeanComptTableContext();

  const formatFilterQuery = useFormatFilterQuery(setCurrentPage);
  const paginationQuery = useMemo(
    () => ({offset: MAX_15_PAGE_LIMIT * Math.max(currentPage - 1, 0), page: currentPage}),
    [currentPage],
  );

  const debouncedFilterValues = useDebounce(formatFilterQuery, 300);

  const session = useGetSessionQuery();
  const company = useGetCompanyQuery(session.data?.user_id ?? skipToken);

  const preApprovalRequests = useGetAllAdminPreApprovalRequestsQuery(
    {
      companyId: company.data?.id,
      params: {
        ...debouncedFilterValues,
        ...paginationQuery,
        limit: MAX_15_PAGE_LIMIT,
        ordering: 'created_on',
      },
    },
    {skip: !company.data?.id},
  );

  const preApprovalRequestIdList = preApprovalRequests.data?.results.map((request) => request.id);

  const noDataText = useNoDataText(
    'No requests are ready for your review.',
    'No requests were found with the current filters.',
  );

  return (
    <>
      <ReviewRequestSidePanel
        requestId={requestId}
        editMode={props.editingMode}
        setEditMode={props.setEditingMode}
        requestIdList={preApprovalRequestIdList}
      />
      <LeanComptTable
        data={preApprovalRequests.data?.results ?? []}
        isDataLoading={preApprovalRequests.isLoading}
        noDataTitleText={noDataText.title}
        onRowClicked={(row) => setSearchParams({request_id: `${row.id}`})}
        totalCount={preApprovalRequests.data?.count ?? 0}
        itemsPerPage={MAX_15_PAGE_LIMIT}
        showPagination
      >
        <ComptTableBar showColumnFilter>
          <SearchFilter
            key={ProfessionalDevelopmentFilter.SEARCH}
            currentValues={filters[ProfessionalDevelopmentFilter.SEARCH]}
            options={[
              {
                id: 1,
                name: 'Professional development search',
                placeholder: 'Search by request ID, employee name, or program name',
              },
            ]}
            handleChange={(searchQuery) => {
              setFilters((prevState) => ({
                ...prevState,
                [ProfessionalDevelopmentFilter.SEARCH]: searchQuery,
              }));
            }}
          />
        </ComptTableBar>
      </LeanComptTable>
    </>
  );
};

import React, {useEffect, useState} from 'react';

// RTK queries
import {useGetSessionQuery} from '@compt/app/services/api/api-slice';
import {useGetEmployeePreApprovalRequestQuery} from '@compt/app/services/api/employee-learning-development-slice';
import {useGetChannelCommentsQuery} from '@compt/app/services/api/lnd-comments-slice';

// Hooks and methods
import {useSearchParams} from 'react-router-dom';
import {featureEnabled, FeatureFlags} from '@compt/utils/feature-flags-helper';
import {getCustomFieldURLKey} from '@compt/types/learning-development/pre-approval-request';

// Components
import {ComptSidePanel} from '@compt/common/compt-side-panel/compt-side-panel';
import {ComptLoadingIndicator} from '@compt/common/compt-loading/compt-loading';
import {ReviewFormTab} from './review-forms/review-form-tab';
import {ComptTabButton} from '@compt/common/compt-tab-button/compt-tab-button';
import {ComptCommentBox} from '@compt/common/compt-comment-box/compt-comment-box';
import {RequestDataFieldKeys} from '@compt/types/learning-development/learning-type-consts';
import {ReimbursementSidePanelDetailsTab} from './reimbursement-side-panel-details-tab';

interface ReimbursementSidePanelProps {
  requestId: string | null;
  companyId: number;
  'data-testid'?: string;
}

enum TabKeys {
  'REQUEST_DETAILS',
  'COMMENTS',
  'REVIEW',
}

export const ReimbursementSidePanel = (props: ReimbursementSidePanelProps) => {
  const {companyId} = props;
  const [, setSearchParams] = useSearchParams();

  const preApprovalRequestQuery = useGetEmployeePreApprovalRequestQuery(
    {
      companyId: companyId,
      requestId: props.requestId,
    },
    {skip: !props.companyId || !props.requestId},
  );
  const preApprovalRequest = preApprovalRequestQuery.data;

  const {data} = useGetChannelCommentsQuery(
    {channelId: preApprovalRequest?.comment_channel_id},
    {skip: !preApprovalRequest?.comment_channel_id},
  );

  function clearSelectedRequest() {
    setSearchParams({});
  }

  const preApprovalRequestReceiptUrl: string = (
    preApprovalRequest?.request_data[getCustomFieldURLKey('Upload document(s)')] || ''
  ).toString();
  let preApprovalRequestSupportingDocumentsUrls: string[] = [];

  if (
    preApprovalRequest?.request_data[getCustomFieldURLKey(RequestDataFieldKeys.SupportingDocuments)]
  ) {
    preApprovalRequestSupportingDocumentsUrls = preApprovalRequest.request_data[
      getCustomFieldURLKey(RequestDataFieldKeys.SupportingDocuments)
    ] as string[];
  }

  const vendorName = preApprovalRequest?.request_data['Vendor name'];
  const [activeTab, setActiveTab] = useState(TabKeys.REQUEST_DETAILS);
  const session = useGetSessionQuery();
  const isHijacked = session.data?.is_hijacked;

  // Reset tab state when opening different requests
  useEffect(() => {
    setActiveTab(TabKeys.REQUEST_DETAILS);
  }, [props.requestId]);

  return (
    <>
      <ComptSidePanel
        open={!!props.requestId}
        className="max-w-[520px]"
        data-testid={props['data-testid']}
      >
        <ComptLoadingIndicator
          isLoading={preApprovalRequestQuery.isLoading || preApprovalRequestQuery.isFetching}
        >
          {preApprovalRequest && (
            <>
              <ComptSidePanel.Header
                title={vendorName || ''}
                setOpen={clearSelectedRequest}
                headerIcon={{id: 'file-icon-blue'}}
              />
              <div className="flex pt-300 px-400 gap-x-6">
                <ComptTabButton
                  isActive={activeTab === TabKeys.REQUEST_DETAILS}
                  onClick={() => setActiveTab(TabKeys.REQUEST_DETAILS)}
                >
                  <p className="heading4">Overview</p>
                </ComptTabButton>
                {!isHijacked && (
                  <ComptTabButton
                    isActive={activeTab === TabKeys.COMMENTS}
                    onClick={() => setActiveTab(TabKeys.COMMENTS)}
                  >
                    <p className="heading4">Comments {data?.count ? `(${data?.count})` : null}</p>
                  </ComptTabButton>
                )}
                {!isHijacked &&
                  preApprovalRequest.employee_review &&
                  featureEnabled(FeatureFlags.COMMUNITY_FEED) && (
                    <ComptTabButton
                      isActive={activeTab === TabKeys.REVIEW}
                      onClick={() => setActiveTab(TabKeys.REVIEW)}
                    >
                      <p className="heading4">Review</p>
                    </ComptTabButton>
                  )}
              </div>
              {activeTab === TabKeys.REQUEST_DETAILS ? (
                <>
                  <ReimbursementSidePanelDetailsTab
                    preApprovalRequest={preApprovalRequest}
                    preApprovalRequestReceiptUrl={preApprovalRequestReceiptUrl}
                    preApprovalRequestSupportingDocumentsUrls={
                      preApprovalRequestSupportingDocumentsUrls
                    }
                    onCloseClicked={clearSelectedRequest}
                  />
                </>
              ) : null}
              {activeTab === TabKeys.COMMENTS &&
              preApprovalRequest?.comment_channel_id &&
              !isHijacked ? (
                <>
                  <ComptCommentBox
                    channelId={preApprovalRequest.comment_channel_id}
                    placeHolderText="Type your message..."
                  />
                </>
              ) : null}
              {activeTab === TabKeys.REVIEW && !isHijacked && preApprovalRequest.employee_review ? (
                <ReviewFormTab
                  employeeReview={preApprovalRequest.employee_review}
                  employeeSession={session.data}
                />
              ) : null}
            </>
          )}
        </ComptLoadingIndicator>
      </ComptSidePanel>
    </>
  );
};

import {FieldValues} from 'react-hook-form';
import {stripImageNameFromS3URL} from './image-helpers';
import {DEFAULT_SUPPORTING_DOCS_ALLOWED} from '@compt/types/learning-development/learning-type-consts';

export interface ObjectWithSupportingDocument {
  [key: `supporting_doc_${number}`]: string;
  [key: string]: unknown; // Allows any other properties with unknown types
}

/**
 * Type guard to check if an object conforms to `ObjectWithSupportingDocument`.
 *
 * @param obj - The object to check.
 * @returns `true` if the object has at least one `supporting_doc_${string}` property, `false` otherwise.
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function isObjectWithSupportingDocument(obj: any): obj is ObjectWithSupportingDocument {
  if (typeof obj !== 'object' || obj === null) return false;

  let hasSupportingDoc = false;

  for (const key of Object.keys(obj)) {
    if (key.startsWith('supporting_doc_')) {
      if (obj[key] !== undefined && obj[key] !== null && typeof obj[key] !== 'string') {
        return false; // Ensures all `supporting_doc_` keys have string, null, or undefined values
      }
      hasSupportingDoc = true;
    }
  }

  return hasSupportingDoc;
}

/**
 * Extracts supporting document properties `supporting_doc_*` file keys from an object
 * and returns a new object without those properties.
 *
 * This function **does not mutate** the input object.
 *
 * @param objectWithSupportingDocuments - The object containing `supporting_doc_*` properties.
 * @param maxSupportingDocumentsLength - The maximum number of document keys to expect on the object.
 * @returns An object containing:
 *   - `fileKeys`: An array of extracted document file keys.
 *   - `cleanedObject`: A new object with the `supporting_doc_*` keys removed.
 *
 * @example
 * ```ts
 * const input = {
 *   supporting_doc_0: "doc1.pdf",
 *   supporting_doc_1: "doc2.pdf",
 *   some_other_key: "hello",
 * };
 * const { fileKeys, cleanedObject } = cleanSupportingDocumentURLs(input, 2);
 * console.log(fileKeys); // ["doc1.pdf", "doc2.pdf"]
 * console.log(cleanedObject); // { some_other_key: "hello" } // Input remains unchanged
 * ```
 */
export const cleanSupportingDocumentFileKeys = <T extends Record<string, unknown>>(
  objectWithSupportingDocuments: T,
  maxSupportingDocumentsLength = 3,
): {fileKeys: string[]; cleanedObject: Omit<T, `supporting_doc_${number}`>} => {
  if (!isObjectWithSupportingDocument(objectWithSupportingDocuments)) {
    console.error(
      'Invalid input: Object must contain at least one `supporting_doc_*` property. Received: ',
      objectWithSupportingDocuments,
    );
    throw new Error('Invalid input: Object must contain at least one `supporting_doc_*` property.');
  }

  const obj = objectWithSupportingDocuments;
  const supportingDocumentsKeys: string[] = [];
  const cleanedObject = {...obj} as Omit<T, `supporting_doc_${number}`>; // Preserve immutability

  try {
    // Find all `supporting_doc_*` keys
    const supportingDocKeys = Object.keys(obj).filter((key) => key.startsWith('supporting_doc_'));

    // Extract only valid string values and remove them from cleanedObject
    const validKeys = supportingDocKeys
      .filter((key) => obj[key] === null || obj[key] === undefined || typeof obj[key] === 'string')
      .slice(0, maxSupportingDocumentsLength);

    for (const key of validKeys) {
      if (obj[key]) {
        supportingDocumentsKeys.push(obj[key] as string);
      }

      delete cleanedObject[key]; // Remove only valid string properties
    }

    return {fileKeys: supportingDocumentsKeys, cleanedObject};
  } catch (e) {
    throw new Error('An error occurred while processing the object.');
  }
};

/**
 * Extracts and cleans document-related fields from the given form values.
 *
 * This function processes the input object to:
 * - Extract and clean the receipt key using `stripImageNameFromS3URL`.
 * - Identify and clean supporting document file keys if the rest of the fields contain supporting document data.
 * - Return the cleaned receipt key, an array of supporting document file keys (if any), and the remaining non-document fields.
 *
 * @param formValues - The object containing form field values, including a `receipt_key` and potentially other fields.
 * @returns An object with the following properties:
 *   - `receiptKey`: The cleaned receipt key obtained from `receipt_key`.
 *   - `supportingDocumentKeys`: An array of file keys for supporting documents, or an empty array if none are found.
 *   - `nonDocumentFields`: The cleaned non-document fields if supporting documents were detected; otherwise, the original remaining fields.
 */
export const extractDocumentFields = <T extends FieldValues>(formValues: T) => {
  const {receipt_key, ...restOfFields} = formValues;

  const cleanedReceiptKey = stripImageNameFromS3URL(receipt_key);
  let supportDocumentFileKeys: string[] = [];
  let cleanedRestOfFields: Omit<T, 'receipt_key'> | null = null;

  if (isObjectWithSupportingDocument(restOfFields)) {
    const result = cleanSupportingDocumentFileKeys(restOfFields, DEFAULT_SUPPORTING_DOCS_ALLOWED);
    supportDocumentFileKeys = result.fileKeys;
    cleanedRestOfFields = result.cleanedObject as typeof restOfFields;
  }

  return {
    receiptKey: cleanedReceiptKey,
    supportingDocumentKeys: supportDocumentFileKeys,
    nonDocumentFields: cleanedRestOfFields ?? restOfFields,
  };
};

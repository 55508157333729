import React from 'react';

// Hooks and methods
import {useNavigate} from 'react-router-dom';
import {ReviewBEReportsTableController as controller} from './business-expenses/review-business-expense-reports-table.controller';
import {useIsMobileView} from '@compt/utils/mobile-helpers';
import {formatCurrency} from '@compt/utils/international-helpers';

// Types
import {BusinessExpenseReport} from '@compt/types/business-expenses/business-expense-report';
import {BusinessExpenseReportStatus} from '@compt/types/business-expenses/business-expense-report-statuses';
import {ReviewTabType} from '@compt/types/stipend-expenses/expense-review';

// Components
import {FilterContextProvider} from '@compt/common/compt-filter-sidebar/filter-context';
import {ReviewBusinessExpenseReportsTable} from './business-expenses/review-business-expense-reports-table';
import {LeanComptTableContextProvider} from '@compt/common/lean-compt-table/lean-compt-table-context';
import {ComptPortal, PortalRoot} from '@compt/common/compt-portal/compt-portal';
import {
  BusinessExpenseReportFilter,
  ReviewBusinessExpenseReportSidebar,
} from './business-expenses/review-business-expense-reports-sidebar';
import {FilterWarningBanner} from './components/filter-banner';
import {ReviewScoreButton} from './components/review-score-button';

// TO-DO: Replace in COMPT-6351
export const dummyExpensesData = [
  {
    id: 1,
    title: 'Total spend',
    total_amount: 104302,
    currency: 'USD',
  },
  {
    id: 2,
    title: 'Number of expenses',
    total_amount: 523,
  },
];

export const ReviewBusinessExpensesTab = () => {
  const FILTER_ID = 'review-business-expense-reports-table-v1.0.0';

  const navigate = useNavigate();
  const isMobile = useIsMobileView();

  const initialFilterValues = {
    [BusinessExpenseReportFilter.SEARCH]: '',
    [BusinessExpenseReportFilter.STATUS]: [],
    [BusinessExpenseReportFilter.SUBMITTED_START_DATE]: '',
    [BusinessExpenseReportFilter.SUBMITTED_END_DATE]: '',
  };

  const onActionClicked = (report: BusinessExpenseReport) => {
    navigate(`/review-programs/business-expenses/${report.id}`);
  };

  const columnDefinition = controller.getColumnDefinition(onActionClicked);

  return (
    <FilterContextProvider filterId={FILTER_ID} initialValues={initialFilterValues}>
      <div className={'flex flex-row gap-6 mb-4'}>
        {!isMobile &&
          dummyExpensesData.length > 0 &&
          dummyExpensesData.map((data) => (
            <div
              key={data.id}
              className="flex flex-col items-start p-4 gap-3 rounded-xl w-[480px]
            border border-gray-300 bg-white flex-1"
            >
              <p className="label4 text-color-body2">{data.title}</p>
              <h3 className="heading1 text-color-heading">
                {data.currency
                  ? formatCurrency(data.total_amount, data.currency)
                  : data.total_amount}
              </h3>
              <div>
                {data.currency ? (
                  <ReviewScoreButton activeTab={ReviewTabType.SPENT} showActiveTab={true} />
                ) : (
                  <ReviewScoreButton activeTab={ReviewTabType.TOTAL} showActiveTab={true} />
                )}
              </div>
            </div>
          ))}
      </div>
      <FilterWarningBanner
        filterKey={BusinessExpenseReportFilter.STATUS}
        shouldShowWarning={(filterValues, reviewingCount) =>
          // Show the banner if filters are selected, not both statuses are included, and items need attention
          filterValues.length > 0 &&
          !(
            filterValues.includes(BusinessExpenseReportStatus.SUBMITTED) &&
            filterValues.includes(BusinessExpenseReportStatus.REVIEWED_BY_MANAGER)
          ) &&
          reviewingCount?.business_expenses_count > 0
        }
        messageText="expense reports"
      />
      <LeanComptTableContextProvider columnDefinition={columnDefinition} tableId={FILTER_ID}>
        <ReviewBusinessExpenseReportsTable />
      </LeanComptTableContextProvider>
      <ComptPortal portalNode={PortalRoot.RIGHT_NAV}>
        <ReviewBusinessExpenseReportSidebar />
      </ComptPortal>
    </FilterContextProvider>
  );
};

import {comptApiSlice, ComptRTKTags} from './api-slice';
import {ExpenseReview, ExpenseReviewResults} from '@compt/types/stipend-expenses/expense-review';

const extendedComptApiSlice = comptApiSlice.injectEndpoints({
  endpoints: (builder) => ({
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    getStipends: builder.query<any, void>({
      providesTags: [ComptRTKTags.ADMIN_STIPENDS],
      query: () => '/stipends',
    }),
    getExpenseReview: builder.query<ExpenseReview, {companyId?: number; expenseId?: string}>({
      query: ({companyId, expenseId}) => ({
        url: `expenses/${companyId}/review/${expenseId}/`,
      }),
      providesTags: (result, error, params) => [
        {type: ComptRTKTags.EXPENSE_REVIEW, id: params.expenseId},
      ],
    }),
    getExpenseReviews: builder.query<
      ExpenseReviewResults,
      {companyId?: number; filter?: Record<string, unknown>}
    >({
      query: ({companyId, filter}) => ({
        url: `payroll/${companyId}/expense-review`,
        params: {
          ...filter,
        },
      }),
      providesTags: (data) =>
        data
          ? [
              ...data.results.map(({id}) => ({
                type: ComptRTKTags.EXPENSE_REVIEW_LIST as const,
                id,
              })),
              ComptRTKTags.EXPENSE_REVIEW_LIST,
            ]
          : [ComptRTKTags.EXPENSE_REVIEW_LIST],
    }),
    updateExpenseReview: builder.mutation<
      ExpenseReview,
      {body: Partial<ExpenseReview>; filter?: Record<string, unknown>}
    >({
      query: ({body}) => ({
        url: `expenses/${body.company}/review/${body.id}/`,
        method: 'PUT',
        body,
      }),
      async onQueryStarted({body: updatedReview, filter}, {dispatch, queryFulfilled}) {
        // If filter is not passed into the mutation, invalidate the list
        if (!filter) {
          await queryFulfilled;
          dispatch(extendedComptApiSlice.util.invalidateTags([ComptRTKTags.EXPENSE_REVIEW_LIST]));
          return;
        }

        /**
         * Find the expense review with the same id in the cache and update it with the new data.
         * If the review is not found, the cache will not be updated.
         */
        const patchResult = dispatch(
          extendedComptApiSlice.util.updateQueryData(
            'getExpenseReviews',
            {companyId: updatedReview.company, filter: filter},
            (draft) => {
              const expenseReview = draft.results.find((review) => review.id === updatedReview.id);
              if (expenseReview) {
                Object.assign(expenseReview, updatedReview);
              }
            },
          ),
        );

        try {
          await queryFulfilled;
        } catch {
          patchResult.undo();
        }
      },
      invalidatesTags: (_result, _error, {body}) => [
        {type: ComptRTKTags.EXPENSE_REVIEW, id: body.id},
      ],
    }),
    bulkUpdateExpenseReview: builder.mutation<
      ExpenseReview,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      {companyId?: number; body: any; filter?: Record<string, unknown>}
    >({
      query: ({body, companyId}) => ({
        url: `expenses/${companyId}/review_stipend_expenses/`,
        method: 'PUT',
        body,
      }),
      async onQueryStarted({body, companyId, filter}, {dispatch, queryFulfilled, getState}) {
        // If filter is not passed, invalidate list after completion
        if (!filter) {
          await queryFulfilled;
          dispatch(extendedComptApiSlice.util.invalidateTags([ComptRTKTags.EXPENSE_REVIEW_LIST]));
          return;
        }

        // Optimistic update
        const patchResult = dispatch(
          extendedComptApiSlice.util.updateQueryData(
            'getExpenseReviews',
            {companyId, filter},
            (draft) => {
              const expenseIds = Object.keys(body.stipend_expenses || {}).map((id) => parseInt(id));
              expenseIds.forEach((expenseId) => {
                const reviewIndex = draft.results.findIndex((review) => review.id === expenseId);
                if (reviewIndex === -1) return;
                draft.results[reviewIndex] = {
                  ...draft.results[reviewIndex],
                  status: body.status,
                };
              });
            },
          ),
        );

        try {
          await queryFulfilled;
        } catch (error) {
          console.error('Error in optimistic update, undoing patch:', error);
          patchResult.undo();
        }
      },
      // Only invalidate specific items, not the whole list
      invalidatesTags: (_result, _error, {body}) => {
        if (body?.stipend_expenses) {
          return Object.keys(body.stipend_expenses).map((id) => ({
            type: ComptRTKTags.EXPENSE_REVIEW as const,
            id: parseInt(id),
          }));
        }
        return [ComptRTKTags.EXPENSE_REVIEW_LIST];
      },
    }),
  }),
});

export const {
  useGetStipendsQuery,
  useGetExpenseReviewQuery,
  useGetExpenseReviewsQuery,
  useUpdateExpenseReviewMutation,
  useBulkUpdateExpenseReviewMutation,
} = extendedComptApiSlice;

import {comptApiSlice, ComptRTKTags} from './api-slice';

// Types
import {
  EmployeeReview,
  LearningCourseReviewListResults,
  LearningReviewPayload,
  LearningReviewReaction,
} from '@compt/types/community-feed';

const extendedComptApiSlice = comptApiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getCommunityFeed: builder.query<
      LearningCourseReviewListResults,
      {filter: Record<string, unknown>}
    >({
      query: ({filter}) => ({
        url: 'community_feed/',
        params: {
          ...filter,
        },
      }),
      providesTags: [ComptRTKTags.LEARNING_COMMUNITY_FEED_LIST],
    }),
    createLearningReview: builder.mutation<
      EmployeeReview,
      {companyId: number; body: LearningReviewPayload}
    >({
      query: ({companyId, body}) => ({
        url: `learning_and_development/${companyId}/me/reviews/`,
        method: 'POST',
        body,
      }),
    }),
    createReviewReaction: builder.mutation<
      EmployeeReview,
      {review_id: number; body: {reaction_type: LearningReviewReaction}}
    >({
      query: ({review_id, body}) => ({
        url: `community_feed/${review_id}/reactions/`,
        method: 'POST',
        body,
      }),
      invalidatesTags: [ComptRTKTags.LEARNING_COMMUNITY_FEED_LIST],
    }),
    updateReviewReaction: builder.mutation<
      EmployeeReview,
      {review_id: number; reaction_id: number; body: {reaction_type: LearningReviewReaction}}
    >({
      query: ({review_id, reaction_id, body}) => ({
        url: `community_feed/${review_id}/reactions/${reaction_id}/`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: [ComptRTKTags.LEARNING_COMMUNITY_FEED_LIST],
    }),
    deleteReviewReaction: builder.mutation<
      EmployeeReview,
      {review_id: number; reaction_id: number; body: {reaction_type: LearningReviewReaction}}
    >({
      query: ({review_id, reaction_id, body}) => ({
        url: `community_feed/${review_id}/reactions/${reaction_id}/`,
        method: 'DELETE',
        body,
      }),
      invalidatesTags: [ComptRTKTags.LEARNING_COMMUNITY_FEED_LIST],
    }),
  }),
});

export const {
  useGetCommunityFeedQuery,
  useCreateLearningReviewMutation,
  useCreateReviewReactionMutation,
  useUpdateReviewReactionMutation,
  useDeleteReviewReactionMutation,
} = extendedComptApiSlice;

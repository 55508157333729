export function getAsNumber(amount: number | string | undefined) {
  if (!amount) return NaN;
  return typeof amount === 'string' ? parseFloat(amount) : amount;
}

export function calculateOverBudget(
  amountAvailable: number | string | undefined,
  amountSubmitted: number | string | undefined,
) {
  if (!amountAvailable || !amountSubmitted) return 0;

  const convertedAmountAvailable = getAsNumber(amountAvailable);
  const convertedAmountSubmitted = getAsNumber(amountSubmitted);

  return Math.max(0, convertedAmountSubmitted - convertedAmountAvailable);
}

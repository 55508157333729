import React, {Dispatch, SetStateAction} from 'react';

// Hooks and methods
import {useIsMobileView} from '@compt/utils/mobile-helpers';

// Components
import {ComptSidePanel} from '@compt/common/compt-side-panel/compt-side-panel';
import {BusinessExpense} from '@compt/types/business-expenses/business-expense';
import {ComptPill, ComptPillSize} from '@compt/common/forms/compt-pill/compt-pill';

// Types
import {AdminBusinessExpenseForm} from './admin-business-expense-form';
import {ComptButton, ComptButtonType} from '@compt/common/compt-button/compt-button';
import {businessExpenseStatusFormat} from '@compt/types/business-expenses/business-expense-statuses';

interface AdminSearchBusinessExpenseSidePanelProps {
  selectedExpense: BusinessExpense | null;
  setSelectedExpense: Dispatch<SetStateAction<BusinessExpense | null>>;
}

export const AdminSearchBusinessExpenseSidePanel = (
  props: AdminSearchBusinessExpenseSidePanelProps,
) => {
  const {selectedExpense} = props;

  const isMobileView = useIsMobileView();

  let pillName;
  let pillStyle;

  if (selectedExpense?.status) {
    const {statusName, statusStyle} = businessExpenseStatusFormat[selectedExpense.status];
    pillName = statusName;
    pillStyle = statusStyle;
  }

  return (
    <ComptSidePanel open={!!selectedExpense} className="max-w-[920px]">
      <ComptSidePanel.Header
        title="Expense details"
        setOpen={() => props.setSelectedExpense(null)}
        statusPill={
          selectedExpense?.status &&
          !isMobileView && (
            <span
              className="flex"
              data-testid={`status-pill-${selectedExpense?.status.toLowerCase()}`}
            >
              <h2 className="text-color-body1">&nbsp;Status</h2>
              <ComptPill className="ml-2" pillSize={ComptPillSize.MEDIUM} pillType={pillStyle}>
                {pillName}
              </ComptPill>
            </span>
          )
        }
      />
      <AdminBusinessExpenseForm
        selectedExpense={selectedExpense}
        editMode={false}
        setEditMode={() => {}}
      />
      <ComptSidePanel.Footer>
        <ComptButton
          onClick={() => props.setSelectedExpense(null)}
          buttonType={ComptButtonType.OUTLINED}
        >
          Cancel
        </ComptButton>
      </ComptSidePanel.Footer>
    </ComptSidePanel>
  );
};

import React from 'react';
import {FiltersState} from './compt-filter-sidebar';
import {ComposableCollapsible} from './composable-collapsible';
import {useFilterContext} from './filter-context';

interface RadioGroupCollapsibleProps {
  id: string;
  label: string;
  options: {key: string; label: string}[];
  filterValue?: string;
}

export const RadioGroupCollapsible = ({
  id,
  label,
  options,
  filterValue = 'true',
}: RadioGroupCollapsibleProps) => {
  const {filters, setFilters} = useFilterContext();

  const radioOptions = options.map((option) => ({
    key: option.key,
    label: option.label,
    isChecked: filters[option.key] === filterValue,
    onChange: (isChecked: boolean) =>
      onRadioOptionChange(option.key, isChecked, setFilters, filterValue),
  }));

  const radioKeys = options.map((option) => option.key);
  const isOptionSelected = radioKeys.some((key) => filters[key] === filterValue);

  function onRadioOptionChange<T extends FiltersState>(
    filterKey: keyof T,
    isChecked: boolean,
    setFilters: (updater: (prevState: T) => T) => void,
    filterValue = 'true',
  ) {
    setFilters((prevState) => {
      if (typeof prevState[filterKey] !== 'string') return {...prevState};

      const updatedFilterOptions: Record<string, string> = {};

      radioKeys.forEach((key) => {
        // Set filter option being selected or unselected
        if (key === filterKey) {
          updatedFilterOptions[key] = isChecked ? filterValue : '';
        }

        // Clear all other radio options
        if (key !== filterKey && isChecked) {
          updatedFilterOptions[key] = '';
        }
      });

      return {...prevState, ...updatedFilterOptions};
    });
  }

  return (
    <ComposableCollapsible
      appliedFilterCount={isOptionSelected ? 1 : 0}
      collapsibleId={id}
      label={label}
    >
      <div className="flex flex-col">
        {radioOptions.map((option) => (
          <RadioOption
            key={option.key}
            isChecked={option.isChecked}
            label={option.label}
            onChange={option.onChange}
          />
        ))}
      </div>
    </ComposableCollapsible>
  );
};

interface RadioOptionProps {
  isChecked: boolean;
  label: string;
  onChange: (isChecked: boolean) => void;
}

const RadioOption = (props: RadioOptionProps) => (
  <label className="flex items-center hover:bg-surface-secondary-hover p-1 rounded">
    <input
      // While this component should behave like a radio option, it will be kept a checkbox to allow de-selection
      type="checkbox"
      className={`h-4 w-4 rounded border-gray-300 text-color-link checked:bg-base-500
          focus:ring-base-500 cursor-pointer mr-100 disabled:bg-gray-400 
          disabled:hover:bg-gray-400`}
      checked={props.isChecked}
      onChange={(e) => props.onChange(e.target.checked)}
    />
    <span className="text-sm text-gray-700 hover:text-gray-900 cursor-pointer">{props.label}</span>
  </label>
);

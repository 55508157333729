import React, {useEffect} from 'react';

// RTK queries
import {useGetBundledQueries} from '@compt/utils/queries-helper';
import {useGetAllotmentQueryInfo} from '@compt/utils/allotment-helpers';
import {
  useGetExpenseReviewQuery,
  useUpdateExpenseReviewMutation,
} from '@compt/app/services/api/admin-stipends-slice';

// Hooks and methods
import {useForm} from 'react-hook-form';
import {useSearchParams} from 'react-router-dom';
import {formatCurrencyFromCountryCode} from '@compt/utils/international-helpers';
import {useLeanComptTableContext} from '@compt/common/lean-compt-table/lean-compt-table-context';
import {getUTCDateFromString} from '@compt/utils/date-helpers';
import {useScrollOutsideContainer} from '@compt/utils/scroll-helper';
import {stripImageNameFromS3URL} from '@compt/utils/image-helpers';
import {triggerCustomToast} from '@compt/common/compt-toaster/compt-toaster';

// Types
import {DEFAULT_CHAR_FIELD_MAX_LENGTH} from '@compt/constants';
import {ComptTooltipPlacement} from '@compt/common/compt-tooltip/compt-tooltip.controller';
import {StatusFormats, statusMap} from '@compt/types/stipend-expenses/stipend-expenses';
import {ExpenseReview} from '@compt/types/stipend-expenses/expense-review';

// Components
import {ComptSidePanel} from '@compt/common/compt-side-panel/compt-side-panel';
import {ComptLoadingIndicator} from '@compt/common/compt-loading/compt-loading';
import {ComptReceiptDisplayCarousel} from '@compt/common/compt-receipt-carousel/compt-receipt-display-carousel';
import {ClaimEditHistory} from '@compt/pages/employee-stipends/components/existing-claim-side-panel/claim-edit-history';
import {ComptRejectionReason} from '@compt/common/compt-rejection-reason/compt-rejection-reason';
import {
  SaveClaimFormFieldValues,
  SaveClaimSidePanelController,
  // eslint-disable-next-line max-len
} from '@compt/pages/employee-stipends/components/compt-save-claim-side-panel/compt-save-claim-side-panel.controller';
import {ComptReceiptFormCarousel} from '@compt/common/compt-receipt-carousel/compt-receipt-form-carousel';
import {ComptDropDownField} from '@compt/common/forms/compt-dropdown-field/compt-dropdown-field';
import {ComptFormDivider} from '@compt/common/forms/compt-form-divider/compt-form-divider';
import {ComptDatePickerField} from '@compt/common/forms/compt-date-picker-field/compt-date-picker-field';
import {ComptTextField} from '@compt/common/forms/compt-text-field/compt-text-field';
import {ComptTextAreaField} from '@compt/common/forms/compt-text-area-field/compt-text-area-field';
import {ComptCurrencyField} from '@compt/common/forms/compt-currency-field/compt-currency-field';
import {ComptPill, ComptPillSize} from '@compt/common/forms/compt-pill/compt-pill';
import {ReviewStipendFooter} from '../review-stipend-footer';

interface Props {
  expenseId: string | null;
  expenseReviewIdList: number[];
}

const controller = SaveClaimSidePanelController;

export const ReviewStipendSidePanel = (props: Props) => {
  const [, setSearchParams] = useSearchParams();
  const {editMode, setEditMode} = useLeanComptTableContext();

  const {contentRef} = useScrollOutsideContainer();

  const {session, company} = useGetBundledQueries();
  const expenseReviewQuery = useGetExpenseReviewQuery(
    {
      companyId: company?.data?.id,
      expenseId: props.expenseId as string,
    },
    {skip: !company.data?.id || !props.expenseId},
  );
  const expense = expenseReviewQuery.data;
  const {allPerkCategories} = useGetAllotmentQueryInfo(expense);

  const [updateExpenseReview] = useUpdateExpenseReviewMutation();

  const formMethods = useForm<SaveClaimFormFieldValues>();
  const watchedAllotment = formMethods.watch('allotment');

  function clearSelectedExpense() {
    setSearchParams({});
  }

  // Populate with existing form values
  useEffect(() => {
    if (!expense) return;

    const defaultSupportingDocuments: {[key: string]: string} = {};
    expense?.supporting_documents.forEach((doc, index) => {
      const fieldName = `supporting_doc_${index}`;
      defaultSupportingDocuments[fieldName] = doc.document_image;
    });
    formMethods.reset({
      perk_category: controller.getCategoryFromAllotmentById(
        expense.allotments[0],
        expense.perk_category.id,
      ),
      allotment: expense.allotments[0],
      date_of_expense: expense.date_of_expense,
      receipt_key: expense.receipt_image,
      vendor: expense.vendor_name,
      description: expense.description,
      amount_of_expense: expense.amount_of_expense,
      ...defaultSupportingDocuments,
    });
  }, [expense]);

  function onSubmitEdit(form: SaveClaimFormFieldValues) {
    if (!expense) return;

    const adjustedForm = form;

    const supportingDocList = [];

    for (const field in adjustedForm) {
      if (field.startsWith('supporting_doc_') && !!adjustedForm[field]) {
        supportingDocList.push(stripImageNameFromS3URL(adjustedForm[field]));
      }
    }

    const payload = {
      company: expense.company,
      receipt_key: stripImageNameFromS3URL(adjustedForm.receipt_key),
      id: expense.id,
      status: expense.status,
      date_of_expense: form.date_of_expense,
      amount_of_expense: form.amount_of_expense,
      perk_category: form.perk_category,
      description: form.description,
      vendor_name: form.vendor,
      supporting_document_keys: supportingDocList,
      allotment_id: expense.allotments[0].id,
    } as unknown as ExpenseReview;

    updateExpenseReview({
      body: payload,
    }).then((results) => {
      if ('error' in results) {
        if ('data' in results.error) {
          triggerCustomToast(
            'error',
            'There was a problem updating expense',
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            results.error.data?.errors.join(' '),
          );
        } else {
          triggerCustomToast('error', 'There was a problem updating expense');
        }
        console.error('Error updating expense in review', results.error);
        return;
      }

      formMethods.reset();
      triggerCustomToast('success', 'Successfully updated expense');
      setEditMode && setEditMode(false);
    });
  }

  return (
    <ComptSidePanel className="max-w-[920px]" open={!!props.expenseId}>
      <ComptSidePanel.Header
        title="Review submission"
        setOpen={clearSelectedExpense}
        headerIcon={{id: 'file-icon-blue'}}
        statusPill={<RequestStatusPill expense={expense} />}
      />
      <ComptSidePanel.Content className="sm:overflow-y-hidden">
        <ComptLoadingIndicator
          isLoading={expenseReviewQuery.isLoading || expenseReviewQuery.isFetching}
        >
          <div className="h-full flex flex-col">
            <form className="grow">
              <fieldset className="flex h-full grow" disabled={formMethods.formState.isSubmitting}>
                <div className="flex flex-col w-full md:flex-row">
                  <div className="flex justify-center bg-[#FAF6F1] sm:overflow-y-clip">
                    <div className={'flex flex-col w-full sm:w-[480px] items-end p-6'}>
                      {expense?.receipt_image && !editMode && (
                        <ComptReceiptDisplayCarousel
                          key={`receipt-display-for-${props.expenseId}`}
                          documents={[expense.receipt_image, ...expense.supporting_documents]}
                        />
                      )}
                      {expense?.receipt_image && editMode && (
                        <ComptReceiptFormCarousel
                          key={`receipt-form-for-${props.expenseId}`}
                          formMethods={formMethods}
                          receiptLabel="Uploaded receipt"
                          userId={session.data?.user_id}
                          initialReceiptValue={expense?.receipt_image}
                          initialSupportingDocs={expense?.supporting_documents}
                          expenseId={props.expenseId}
                          additionalParams={{for_user: expense?.user}}
                        />
                      )}
                    </div>
                  </div>
                  <div ref={contentRef} className="w-full py-6 px-6 sm:overflow-y-auto">
                    {expense?.rejection_reason && (
                      <ComptRejectionReason rejectionReason={expense.rejection_reason} />
                    )}
                    <>
                      <ComptTextField
                        name="full_name"
                        label="Full name"
                        data-testid="full-name-field"
                        initialValue={expense?.full_name}
                        control={formMethods.control}
                        register={formMethods.register}
                        readOnly
                        disabled
                      />
                      <ComptDropDownField
                        name="allotment"
                        label="Selected stipend"
                        placeholder="Choose Stipend"
                        options={expense?.allotments ? [...expense.allotments] : []}
                        getKey={(option) => option.id}
                        getDisplayText={(option) => option?.cycle?.name}
                        validation={{required: true}}
                        register={formMethods.register}
                        control={formMethods.control}
                        errors={formMethods.formState.errors.allotment}
                        disabled={true} // Reviewers should not change allotments for employees
                        readOnly={!editMode}
                      />
                      <ComptDropDownField
                        name="perk_category"
                        label="Category"
                        placeholder="Choose Category"
                        options={expense?.allotments[0]?.perk_categories ?? []}
                        getKey={(option) => option.id}
                        getDisplayText={(option) => option.name}
                        validation={{required: true}}
                        register={formMethods.register}
                        control={formMethods.control}
                        errors={formMethods.formState.errors.perk_category}
                        disabled={!editMode}
                        readOnly={!editMode}
                        by="id"
                      />

                      <ComptFormDivider />
                      <ComptDatePickerField
                        name="date_of_expense"
                        label="Receipt date"
                        control={formMethods.control}
                        register={formMethods.register}
                        validation={{required: true}}
                        errors={formMethods.formState.errors.date_of_expense}
                        disabled={!editMode}
                        readOnly={!editMode}
                        startDate={
                          getUTCDateFromString(watchedAllotment?.earliest_receipt_date) || null
                        }
                      />
                      <ComptFormDivider />
                      <ComptTextField
                        name="vendor"
                        label="Vendor"
                        control={formMethods.control}
                        register={formMethods.register}
                        validation={{
                          required: true,
                          maxLength: {
                            value: DEFAULT_CHAR_FIELD_MAX_LENGTH,
                            message: `Vendor has max ${DEFAULT_CHAR_FIELD_MAX_LENGTH} characters`,
                          },
                        }}
                        disabled={!editMode}
                        readOnly={!editMode}
                        errors={formMethods.formState.errors.vendor}
                        maxLength={DEFAULT_CHAR_FIELD_MAX_LENGTH}
                        watchedValue={formMethods.watch('vendor')}
                      />
                      <ComptTextAreaField
                        name="description"
                        label="Description"
                        placeholder="Describe the claim purchase"
                        validation={{
                          required: true,
                          maxLength: {
                            value: DEFAULT_CHAR_FIELD_MAX_LENGTH,
                            message: `Description has max ${DEFAULT_CHAR_FIELD_MAX_LENGTH} characters`,
                          },
                        }}
                        register={formMethods.register}
                        disabled={!editMode}
                        readOnly={!editMode}
                        errors={formMethods.formState.errors.description}
                        maxLength={DEFAULT_CHAR_FIELD_MAX_LENGTH}
                        watchedValue={formMethods.watch('description')}
                      />
                      <ComptCurrencyField
                        name="amount_of_expense"
                        label={editMode ? 'Amount spent' : 'Amount'}
                        control={formMethods.control}
                        register={formMethods.register}
                        errors={formMethods.formState.errors.amount_of_expense}
                        validation={{
                          required: true,
                          min: 0.01,
                        }}
                        placeholder="0.00"
                        defaultCurrencyCode={session.data?.country}
                        // givenCurrency={watchedAllotment?.currency || props.stipendCurrency}
                        disabled={!editMode}
                        readOnly={!editMode}
                        tooltipMessage={`Please enter the cost of the item(s) that you're claiming,
                   as shown on the receipt/invoice/statement, rather than what's remaining in
                   your balance. This helps with the review process.`}
                        tooltipPlacement={ComptTooltipPlacement.TOP}
                      />
                    </>
                    {expense?.amount_claimed && (
                      <p className="body3 text-color-body2 -mt-4">
                        Amount covered:{' '}
                        {formatCurrencyFromCountryCode(
                          expense?.amount_claimed,
                          session.data?.country,
                          expense?.currency,
                        )}
                      </p>
                    )}
                    {expense?.formatted_history && allPerkCategories && (
                      <ClaimEditHistory
                        formattedHistory={expense?.formatted_history}
                        allPerkCategories={allPerkCategories}
                        rejectionReason={expense?.rejection_reason}
                      />
                    )}
                  </div>
                </div>
              </fieldset>
            </form>
          </div>
        </ComptLoadingIndicator>
      </ComptSidePanel.Content>
      <ComptSidePanel.Footer>
        <ReviewStipendFooter
          expense={expense}
          expenseIdList={props.expenseReviewIdList}
          onSubmitEdit={formMethods.handleSubmit(onSubmitEdit)}
        />
      </ComptSidePanel.Footer>
    </ComptSidePanel>
  );
};

const RequestStatusPill = ({expense}: {expense?: ExpenseReview}) => {
  if (!expense) return null;

  const statusFormat = StatusFormats[statusMap(expense.status)];

  if (!statusFormat) return null;

  return (
    <>
      <h2 className="text-color-body1">&nbsp; Stipend status</h2>
      <ComptPill
        className="ml-2"
        pillSize={ComptPillSize.MEDIUM}
        pillType={statusFormat?.statusStyle}
      >
        {statusFormat?.statusName}
      </ComptPill>
    </>
  );
};

import React from 'react';
import {useFilterContext} from '@compt/common/compt-filter-sidebar/filter-context';
import {ComptSvgIcon} from '@compt/common/compt-svg-icon/compt-svg-icon';
import {useGetAdminReviewingStatusRequestQuery} from '@compt/app/services/api/learning-development-slice';
import {ReviewingStatusCount} from '@compt/types/learning-development/learning-development-program';

interface FilterWarningBannerProps {
  filterKey: string;
  shouldShowWarning: (filterValues: string[], reviewingCount: ReviewingStatusCount) => boolean;
  messageText?: string;
  onClearFilters?: () => void;
}

export const FilterWarningBanner: React.FC<FilterWarningBannerProps> = ({
  filterKey,
  shouldShowWarning,
  messageText = 'items',
  onClearFilters,
}) => {
  const {filters, setFilters} = useFilterContext();
  const {data: reviewingStatusCount} = useGetAdminReviewingStatusRequestQuery();

  const filterValues = Array.isArray(filters[filterKey]) ? (filters[filterKey] as string[]) : [];

  const showWarning =
    filterValues.length > 0 &&
    reviewingStatusCount !== undefined &&
    shouldShowWarning(filterValues, reviewingStatusCount);

  const handleClearFilters =
    onClearFilters ||
    (() => {
      setFilters((prevFilters) => ({
        ...prevFilters,
        [filterKey]: [],
      }));
    });

  if (!showWarning) {
    return null;
  }

  return (
    <div className="flex justify-between p-3 mb-3 border border-yellow-300 rounded-md">
      <div className="flex items-center">
        <div
          className="flex justify-center items-center w-10 h-10 p-2.5 mr-3 rounded-lg border 
        border-yellow-300 bg-[#FEF6EE]"
        >
          <ComptSvgIcon iconName="alert-triangle-warning-icon" ariaLabel="alert-triangle-warning" />
        </div>
        <p className="body1 text-color-body2">{`Current filters applied may be hiding some ${messageText}
        that require your attention from view.`}</p>
      </div>
      <button type="button" onClick={handleClearFilters}>
        <p className="body3 text-[#3B7E95] ml-1">Clear filters</p>
      </button>
    </div>
  );
};

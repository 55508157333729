import {Dispatch, SetStateAction, useEffect, useState} from 'react';

// RTK queries
import {useGetSessionQuery} from '@compt/app/services/api/api-slice';
import {useCreateBusinessExpenseMutation} from '@compt/app/services/api/business-expenses-slice';
import {useGetCompanyQuery} from '@compt/app/services/api/company-slice';
import {skipToken} from '@reduxjs/toolkit/dist/query';

// Hooks and methods
import {triggerCustomToast} from '@compt/common/compt-toaster/compt-toaster';
import {useReceiptUploadContext} from '@compt/common/forms/compt-receipt-upload/receipt-upload-context';
import {
  expenseTypeOptions,
  formatExpenseForm,
  getExpenseTypeOption,
} from '@compt/utils/business-expense-helpers';
import {useForm} from 'react-hook-form';
import {EmployeeBusinessExpenseFormController as controller} from './employee-business-expense-form.controller';

// Types
import {BusinessExpenseType} from '@compt/types/business-expenses/business-expense';
import {EmployeeBusinessExpenseFormFieldValues} from './employee-business-expense-form.types';

// Components
import {
  ComptButton,
  ComptButtonSize,
  ComptButtonType,
} from '@compt/common/compt-button/compt-button';
import {ComptReceiptFormCarousel} from '@compt/common/compt-receipt-carousel/compt-receipt-form-carousel';
import {ComptSidePanel} from '@compt/common/compt-side-panel/compt-side-panel';
import {ComptDropDownField} from '@compt/common/forms/compt-dropdown-field/compt-dropdown-field';
import {BusinessExpenseReport} from '@compt/types/business-expenses/business-expense-report';
import {MileageExpenseForm} from './mileage-expense-form';
import {PerDiemExpenseForm} from './per-diem-expense-form';
import {StandardBusinessExpenseForm} from './standard-business-expense-form';

interface EmployeeBusinessExpenseFormSidePanelProps {
  open: boolean;
  setOpen: React.Dispatch<SetStateAction<boolean>>;
  reportInfo: BusinessExpenseReport;
  setOpenCreateExpenseSidePanel: Dispatch<SetStateAction<boolean>>;
}

export const EmployeeBusinessExpenseFormSidePanel = (
  props: EmployeeBusinessExpenseFormSidePanelProps,
) => {
  const {open, setOpen} = props;
  const [expenseType] = useState(BusinessExpenseType.BUSINESS_EXPENSE);
  const [receiptKeyNumber, setReceiptKeyNumber] = useState(0);

  const {receiptUploading, setReceiptUploading} = useReceiptUploadContext();

  const sessionQuery = useGetSessionQuery();
  const userId = sessionQuery.data?.user_id;
  const assignedCostCenter = sessionQuery.data?.cost_center;

  const companyQuery = useGetCompanyQuery(userId ?? skipToken);
  const mileageRate = companyQuery.data?.mileage_rate;
  const businessConfig = companyQuery.data?.business_expense_configuration;

  const [createBusinessExpense, {isLoading: isCreating}] = useCreateBusinessExpenseMutation();

  const formMethods = useForm<EmployeeBusinessExpenseFormFieldValues>({});

  const watchedExpenseType = formMethods.watch('expense_type');

  function onSubmit(form: EmployeeBusinessExpenseFormFieldValues, callback: () => void) {
    if (!mileageRate && form.expense_type.id === BusinessExpenseType.MILEAGE) {
      triggerCustomToast(
        'error',
        'There was a problem saving your business expense.',
        'You do not have your mileage rate set. Please contact your program admin or support@compt.io.',
      );
      return;
    }

    const supportingDocList = [];

    for (const field in form) {
      if (field.startsWith('supporting_doc_') && !!form[field]) {
        supportingDocList.push(form[field]);
      }
    }

    const adjustedForm = formatExpenseForm(form, mileageRate);
    const payload = {
      ...adjustedForm,
      report_id: props.reportInfo.id,
      supporting_document_keys: supportingDocList,
    };

    createBusinessExpense(payload).then((data) => {
      if ('error' in data) {
        triggerCustomToast('error', 'There was a problem saving your business expense');
        return;
      }

      callback();
    });
  }

  function onSaveButtonCallback() {
    formMethods.reset();
    // Ensure receipts and supporting documents are reset as well
    formMethods.reset({
      receipt_key: '',
      supporting_doc_0: '',
      supporting_doc_1: '',
      supporting_doc_2: '',
    });
    setOpen(false);
    triggerCustomToast(
      'success',
      'Expense added successfully!',
      `An expense has been added to ${props.reportInfo.title}.
       If there are no other expenses to add, submit the report for approval to be reimbursed!`,
      '',
      'Add another',
      () => props.setOpenCreateExpenseSidePanel(true),
      () => {},
      40000, // 40 seconds
    );
  }

  function onSaveAndAddAnotherCallback() {
    // Specify default values for these fields so the new form starts fresh.
    formMethods.reset({
      receipt_key: '',
      supporting_doc_0: '',
      supporting_doc_1: '',
      supporting_doc_2: '',
    });

    // Force re-render of receipt carousel (i.e., clear receipts).
    setReceiptKeyNumber((prevState) => (prevState += 1));
  }

  // Syncs form with initially selected expense type
  useEffect(() => {
    if (!expenseType) {
      return;
    }

    const initialExpenseTypeOption = getExpenseTypeOption(expenseType);

    if (!initialExpenseTypeOption) {
      return;
    }

    formMethods.setValue('expense_type', initialExpenseTypeOption);
  }, [expenseType, formMethods]);

  if (!companyQuery.data) {
    return null;
  }

  return (
    <ComptSidePanel
      open={open}
      className="max-w-[920px]"
      data-testid="employee-business-expense-form-side-panel"
    >
      <ComptSidePanel.Header
        title="Add expense"
        setOpen={setOpen}
        headerIcon={{id: 'receipt-icon'}}
        formMethods={formMethods}
      />
      <ComptSidePanel.Content>
        <div className="h-full flex flex-col">
          <form className="grow">
            <fieldset className="flex h-full grow" disabled={formMethods.formState.isSubmitting}>
              <div className="flex flex-col w-full md:flex-row">
                <div className="flex justify-center bg-[#FAF6F1]">
                  <div className="flex flex-col w-full sm:w-[480px] items-end p-6">
                    <ComptReceiptFormCarousel
                      key={`${receiptKeyNumber}-receiptKey`}
                      formMethods={formMethods}
                      userId={userId}
                      receiptLabel="Upload receipt"
                      initialSupportingDocs={[]}
                      required={controller.getExpenseReceiptRequired(
                        watchedExpenseType?.id,
                        businessConfig,
                      )}
                      receiptDomain="business_expenses"
                      supportingDocDomain="be_supporting_document"
                      receiptTestDataId="receipt-key-field"
                    />
                  </div>
                </div>
                <div
                  className={`w-full pt-6 px-6 ${
                    watchedExpenseType?.id === BusinessExpenseType.BUSINESS_EXPENSE ? 'pb-20' : ''
                  }`}
                >
                  <ComptDropDownField
                    name="expense_type"
                    data-testid="expense-type-field"
                    label="Expense type"
                    options={expenseTypeOptions}
                    getKey={(option) => option.id}
                    getDisplayText={(option) => option.label}
                    validation={{required: 'Expense type is required'}}
                    register={formMethods.register}
                    control={formMethods.control}
                    errors={formMethods.formState.errors.expense_type}
                  />
                  {watchedExpenseType?.id === BusinessExpenseType.BUSINESS_EXPENSE && (
                    <StandardBusinessExpenseForm
                      formMethods={formMethods}
                      company={companyQuery.data}
                      assignedCostCenter={assignedCostCenter}
                      isCreateForm
                      isEditable
                    />
                  )}
                  {watchedExpenseType?.id === BusinessExpenseType.MILEAGE && (
                    <MileageExpenseForm
                      formMethods={formMethods}
                      company={companyQuery.data}
                      assignedCostCenter={assignedCostCenter}
                      isCreateForm
                      isEditable
                    />
                  )}
                  {watchedExpenseType?.id === BusinessExpenseType.PER_DIEM && (
                    <PerDiemExpenseForm
                      formMethods={formMethods}
                      company={companyQuery.data}
                      assignedCostCenter={assignedCostCenter}
                      isCreateForm
                      isEditable
                    />
                  )}
                </div>
              </div>
            </fieldset>
          </form>
        </div>
      </ComptSidePanel.Content>
      <ComptSidePanel.Footer>
        <div
          className={`flex flex-col sm:flex-row gap-y-3 sm:space-x-4
          justify-between sm:justify-start items-center w-full
          `}
        >
          <ComptButton
            data-testid="business-expense-submit-button"
            buttonType={ComptButtonType.PRIMARY}
            size={ComptButtonSize.SMALL}
            disabled={isCreating || receiptUploading || !formMethods.formState.isValid}
            onClick={formMethods.handleSubmit((form) => onSubmit(form, onSaveButtonCallback))}
            onKeyDown={(e: React.KeyboardEvent<HTMLButtonElement>) => {
              e.key === 'Enter' && e.preventDefault();
            }}
            className="w-full sm:w-fit"
          >
            Save
          </ComptButton>
          <ComptButton
            size={ComptButtonSize.SMALL}
            buttonType={ComptButtonType.SECONDARY}
            disabled={isCreating || receiptUploading || !formMethods.formState.isValid}
            onClick={formMethods.handleSubmit((form) =>
              onSubmit(form, onSaveAndAddAnotherCallback),
            )}
            onKeyDown={(e: React.KeyboardEvent<HTMLButtonElement>) => {
              e.key === 'Enter' && e.preventDefault();
            }}
            className="w-full sm:w-fit"
          >
            Save and create another
          </ComptButton>
          <ComptButton
            buttonType={ComptButtonType.BORDERLESS}
            size={ComptButtonSize.SMALL}
            disabled={isCreating}
            onClick={() => {
              formMethods.reset();
              setOpen(false);
              setReceiptUploading(() => false);
            }}
            className="w-full sm:w-fit pl-2"
          >
            Cancel
          </ComptButton>
        </div>
      </ComptSidePanel.Footer>
    </ComptSidePanel>
  );
};

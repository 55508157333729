import {
  BusinessExpense,
  BusinessExpenseType,
  expenseTypeFormats,
} from '@compt/types/business-expenses/business-expense';
import {dateToISODateString} from './date-helpers';
import {DateTime} from 'luxon';

export type ExpenseTypeOption = {label: string; id: BusinessExpenseType};
export const expenseTypeOptions: ExpenseTypeOption[] = [
  {
    label: expenseTypeFormats[BusinessExpenseType.BUSINESS_EXPENSE],
    id: BusinessExpenseType.BUSINESS_EXPENSE,
  },
  {
    label: expenseTypeFormats[BusinessExpenseType.MILEAGE],
    id: BusinessExpenseType.MILEAGE,
  },
  {
    label: expenseTypeFormats[BusinessExpenseType.PER_DIEM],
    id: BusinessExpenseType.PER_DIEM,
  },
];

export function getExpenseTypeOption(expenseType: BusinessExpenseType | undefined) {
  return expenseTypeOptions.find((type) => type.id === expenseType);
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function formatExpenseForm(form: any, mileageRate?: string): Partial<BusinessExpense> {
  const payloadForm: Partial<BusinessExpense> = {
    expense_type: form.expense_type.id,
    cost_center_id: form.cost_center.id,
    category_id: form.expense_category?.id,
    description: form.description,
    receipt_key: form.receipt_key,
  };

  const expenseType = form['expense_type'];

  if (expenseType.id === BusinessExpenseType.BUSINESS_EXPENSE) {
    payloadForm['amount_of_expense'] = form.amount;
    payloadForm['receipt_date'] = form.receipt_date;
    payloadForm['vendor'] = form.vendor;
  }

  if (expenseType.id === BusinessExpenseType.PER_DIEM) {
    const totalDays = calculateTotalDays(form.start_date, form.end_date);
    const totalRate = calculateTotalFromRatePerDiem(totalDays, form.per_diem_rate);
    payloadForm['amount_of_expense'] = totalRate.toFixed(2);
    payloadForm['origin'] = form.origin;
    payloadForm['destination'] = form.destination;
    payloadForm['start_date'] = form.start_date;
    payloadForm['end_date'] = form.end_date;
    payloadForm['per_diem_rate'] = form.per_diem_rate;
  }

  if (expenseType.id === BusinessExpenseType.MILEAGE && mileageRate) {
    const formattedDistance = parseFloat(form.distance).toFixed(1);
    payloadForm['distance'] = formattedDistance;

    const totalRate = calculateTotalFromRateMileage(formattedDistance, mileageRate);
    payloadForm['amount_of_expense'] = totalRate.toFixed(2);
    payloadForm['origin'] = form.origin;
    payloadForm['destination'] = form.destination;
    payloadForm['start_date'] = form.start_date;
    payloadForm['end_date'] = form.end_date;
  }

  // When deleting the receipt key, need to set to empty string for the API (null value is ignored)
  if (form.receipt_key === null) {
    payloadForm['receipt_key'] = '';
  }
  return payloadForm;
}

export function calculateTotalFromRatePerDiem(days: number, perDiemRate: number | string) {
  perDiemRate = typeof perDiemRate === 'string' ? parseFloat(perDiemRate) : perDiemRate;

  return days && perDiemRate ? Math.round(days * perDiemRate * 100 + Number.EPSILON) / 100 : 0;
}

export function calculateTotalDays(
  startDate: Date | string | null | undefined,
  endDate: Date | string | null | undefined,
) {
  if (!startDate || !endDate) {
    return 0;
  }
  const startDateAsStr = dateToISODateString(startDate);
  const endDateAsStr = dateToISODateString(endDate);

  const start = DateTime.fromFormat(startDateAsStr, 'yyyy-MM-dd');
  const end = DateTime.fromFormat(endDateAsStr, 'yyyy-MM-dd');
  const totalDays = end.diff(start, 'days').days;

  if (totalDays > 0) {
    return totalDays;
  }

  return 1;
}

export function calculateTotalFromRateMileage(
  distance: number | string,
  mileageRate: number | string,
) {
  const distanceToUse = typeof distance === 'string' ? parseFloat(distance) : distance;
  const mileageRateToUse = typeof mileageRate === 'string' ? parseFloat(mileageRate) : mileageRate;

  return distance && mileageRate
    ? Math.round(distanceToUse * mileageRateToUse * 100 + Number.EPSILON) / 100
    : 0;
}

export function formatWithThousandsSeparator(value: number, decimalPlaces = 2) {
  return value.toLocaleString('en-US', {
    minimumFractionDigits: decimalPlaces,
    maximumFractionDigits: decimalPlaces,
  });
}

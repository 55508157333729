import React, {Dispatch, SetStateAction} from 'react';

// Hooks and methods
import {useDateRangePickerContext} from './date-range-picker-context';

// Types
import {DateString} from '@compt/types/common/date-string';
import {DATE_FORMAT_OPTION, formattedDate} from '@compt/utils/date-helpers';

// Components
import {ComptButton, ComptButtonSize, ComptButtonType} from '../compt-button/compt-button';

interface Props {
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  handleChange: (startDate: DateString, endDate: DateString) => void;
  dateStringFormat?: DATE_FORMAT_OPTION;
}

export const DateRangeFooter = (props: Props) => {
  const {
    startDate,
    initialStartDateObject,
    setStartDate,
    initialEndDateObject,
    endDate,
    setEndDate,
  } = useDateRangePickerContext();

  function handleFormattedChange(startDate: Date | null, endDate: Date | null) {
    const dateFormat = props.dateStringFormat
      ? props.dateStringFormat
      : DATE_FORMAT_OPTION['yyyy-mm-dd'];

    const formattedStartDate = startDate === null ? '' : formattedDate(startDate, dateFormat);
    const formattedEndDate = endDate === null ? '' : formattedDate(endDate, dateFormat);

    props.handleChange(formattedStartDate, formattedEndDate);
  }

  return (
    <div className="flex border-t justify-between align-middle py-4">
      <ComptButton
        buttonType={ComptButtonType.BORDERLESS}
        size={ComptButtonSize.SMALL}
        onClick={() => {
          setStartDate(null);
          setEndDate(null);
          props.handleChange('', '');
          props.setIsOpen(false);
        }}
      >
        Reset
      </ComptButton>
      <div className="flex">
        <ComptButton
          buttonType={ComptButtonType.BORDERLESS}
          size={ComptButtonSize.SMALL}
          onClick={() => {
            setStartDate(initialStartDateObject);
            setEndDate(initialEndDateObject);
            props.setIsOpen(false);
          }}
        >
          Cancel
        </ComptButton>
        <ComptButton
          className="mr-4 max-w-min"
          size={ComptButtonSize.SMALL}
          onClick={() => {
            handleFormattedChange(startDate, endDate);
            props.setIsOpen(false);
          }}
        >
          Apply
        </ComptButton>
      </div>
    </div>
  );
};

import React from 'react';

// Hooks and methods
import {DndContext, closestCenter} from '@dnd-kit/core';
import {CSS} from '@dnd-kit/utilities';
import {SortableContext, useSortable, verticalListSortingStrategy} from '@dnd-kit/sortable';

// Types
import {RequestField} from '@compt/types/learning-development/learning-development-program';

// Components
import {ComptSvgIcon} from '@compt/common/compt-svg-icon/compt-svg-icon';
import {ComptCheckboxField} from '@compt/common/forms/compt-checkbox-field/compt-checkbox-field';
import {Switch} from '@headlessui/react';
import {SimpleActionMenu} from '@compt/common/compt-table/simple-action-menu';

interface SortableItemProps {
  field: RequestField;
  disableDrag: boolean;
  onDelete?: (field: RequestField) => void;
  onEdit?: (field: RequestField) => void;
  readOnly?: boolean;
  onActiveToggle?: (field: RequestField) => void;
  onRequiredToggle?: (field: RequestField) => void;
}

const SortableItem = (props: SortableItemProps) => {
  const {field, disableDrag, onDelete, onEdit, readOnly, onRequiredToggle, onActiveToggle} = props;

  const isDraggable = field.is_editable && !disableDrag && !readOnly;

  const {attributes, listeners, setNodeRef, transform, transition} = useSortable({
    id: `${field.field_name}-${field.id}-${field.is_editable}`,
    // Disable sorting for non-draggable fields and for only one editable field
    disabled: !isDraggable,
  });

  const style = {
    transform: isDraggable ? CSS.Transform.toString(transform) : undefined,
    transition: isDraggable ? transition : undefined,
  };

  const onActiveClicked = () => {
    if (onActiveToggle) {
      onActiveToggle(field);
    }
  };

  const onSwitchClicked = () => {
    if (onRequiredToggle) {
      onRequiredToggle(field);
    }
  };

  const onActionMenuClicked = (field: RequestField, action: 'VIEW' | 'EDIT' | 'DELETE') => {
    switch (action) {
      case 'VIEW':
        // Fields are disabled based on is_default property
        if (onEdit) {
          onEdit(field);
        }
        break;
      case 'EDIT':
        if (onEdit) {
          onEdit(field);
        }
        break;
      case 'DELETE':
        if (onDelete) {
          onDelete(field);
        }
        break;
    }
  };

  const renderActionSelector = (
    requestField: RequestField,
    onActionMenuClicked: (requestField: RequestField, action: 'EDIT' | 'VIEW' | 'DELETE') => void,
  ) => {
    const _onMenuClicked = (requestField: RequestField, action: 'VIEW' | 'EDIT' | 'DELETE') => {
      onActionMenuClicked(requestField, action);
    };

    const actions = [];

    if (requestField.is_editable && !readOnly) {
      actions.push({
        label: 'Edit',
        onClick: () => _onMenuClicked(requestField, 'EDIT'),
      });
      actions.push({
        label: 'Delete',
        onClick: () => _onMenuClicked(requestField, 'DELETE'),
        textColor: 'text-red-600',
      });
    } else {
      actions.push({
        label: 'View',
        onClick: () => _onMenuClicked(requestField, 'VIEW'),
      });
    }

    return <SimpleActionMenu relatedActionItem={requestField} actions={actions} />;
  };

  return (
    <li
      ref={setNodeRef}
      style={style}
      {...(isDraggable ? attributes : {})}
      className="flex items-center justify-between gap-x-12 py-4"
    >
      <div className="flex min-w-0 gap-x-2">
        <button className="flex items-start" {...(isDraggable ? listeners : {})}>
          <ComptSvgIcon iconName="menu-drag-icon" className="pt-0.5" />
        </button>
        <div className="min-w-0 flex-auto" onClick={onActiveClicked}>
          <ComptCheckboxField
            name={`selectField-${field.sequence}`}
            value={true}
            initialValue={field.is_active}
            label={field.field_name}
            className={`label3 text-color-body1 ${field.is_default ? 'cursor-not-allowed' : ''}`}
            disabled={field.is_default || readOnly}
          />
          <p className="ml-6 text-color-tertiary">{field.supporting_text}</p>
        </div>
      </div>
      <div>
        <span className="flex flex-grow flex-col">
          <Switch.Group>
            <div className="flex items-center justify-end">
              <Switch
                name={`requiredToggle-${field.sequence}`}
                defaultChecked={field.is_required}
                checked={field.is_required}
                onChange={onSwitchClicked}
                disabled={field.is_default || readOnly}
              >
                {({checked}) => (
                  <button
                    className={`${
                      field.is_default
                        ? 'bg-gray-200 cursor-not-allowed'
                        : checked
                        ? 'bg-stroke-primary'
                        : 'bg-gray-200'
                    } relative inline-flex h-6 w-11 items-center rounded-full`}
                  >
                    <span
                      className={`${
                        field.is_default
                          ? 'translate-x-6'
                          : checked
                          ? 'translate-x-6'
                          : 'translate-x-1'
                      } inline-block h-4 w-4 transform rounded-full bg-white transition-transform`}
                    />
                  </button>
                )}
              </Switch>
              <Switch.Label className="ml-2 mr-1 label4 text-color-body1" passive>
                Required
              </Switch.Label>
              {renderActionSelector(field, onActionMenuClicked)}
            </div>
          </Switch.Group>
        </span>
      </div>
    </li>
  );
};

export interface RequestFieldsProps {
  onDelete?: (field: RequestField) => void;
  onEdit?: (field: RequestField) => void;
  onReorder: (fields: RequestField[]) => void;
  onActiveToggle?: (field: RequestField) => void;
  onRequiredToggle?: (field: RequestField) => void;
  requestFields: RequestField[];
  readOnly?: boolean;
}

export const RequestFields = (props: RequestFieldsProps) => {
  const {onDelete, onEdit, onReorder, requestFields, readOnly, onActiveToggle, onRequiredToggle} =
    props;

  const editableFields = requestFields.filter((field) => field.is_editable);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleDragEnd = ({active, over}: {active: any; over: any}) => {
    if (!over || active.id === over.id || editableFields.length <= 1) return;

    const [, activeId] = active.id.split('-');
    const [, overId] = over.id.split('-');

    const oldIndex = requestFields.findIndex((field) => `${field.id}` === activeId);
    const newIndex = requestFields.findIndex((field) => `${field.id}` === overId);

    const updatedFields = [...requestFields];
    const [movedField] = updatedFields.splice(oldIndex, 1);
    updatedFields.splice(newIndex, 0, movedField);
    const reorderedFields = updatedFields.map((field, index) => ({
      ...field,
      sequence: index,
    }));

    onReorder(reorderedFields);
  };

  return (
    <DndContext
      collisionDetection={closestCenter}
      onDragEnd={handleDragEnd}
      modifiers={editableFields.length > 1 || !readOnly ? undefined : []} // Disable dragging if only one editable field
    >
      <SortableContext
        items={editableFields.map(
          (field) => `${field.field_name}-${field.id}-${field.is_editable}`,
        )}
        strategy={verticalListSortingStrategy}
      >
        <ul className="divide-y divide-gray-100">
          {requestFields
            .filter((field) => !field.is_editable)
            .map((field) => (
              <SortableItem
                key={field.id}
                field={field}
                disableDrag={true}
                onDelete={onDelete}
                onEdit={onEdit}
                onActiveToggle={onActiveToggle}
                onRequiredToggle={onRequiredToggle}
              />
            ))}
          {editableFields.map((field) => (
            <SortableItem
              key={field.id}
              field={field}
              disableDrag={editableFields.length <= 1}
              onDelete={onDelete}
              onEdit={onEdit}
              readOnly={readOnly}
              onActiveToggle={onActiveToggle}
              onRequiredToggle={onRequiredToggle}
            />
          ))}
        </ul>
      </SortableContext>
    </DndContext>
  );
};

import {useGetSessionQuery} from '@compt/app/services/api/api-slice';
import {useGetCompanyQuery} from '@compt/app/services/api/company-slice';
import {skipToken} from '@reduxjs/toolkit/dist/query';

export const useGetBundledQueries = () => {
  const session = useGetSessionQuery();
  const company = useGetCompanyQuery(session.data?.user_id ?? skipToken);

  return {session, company};
};

import React, {ReactNode} from 'react';
import {twMerge} from 'tailwind-merge';

interface TabButtonProps {
  isActive: boolean;
  onClick: () => void;
  children: ReactNode;
  className?: string;
}

export const ComptTabButton = (props: TabButtonProps) => (
  <button
    className={twMerge(
      `py-2 text-lg font-medium ${
        props.isActive
          ? 'border-b-2 border-[var(--Icon-icon-link,#3B7E95)] text-primary'
          : 'text-gray-500'
      } ${props.className}`,
    )}
    onClick={props.onClick}
  >
    {props.children}
  </button>
);

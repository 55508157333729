import React, {Dispatch, SetStateAction, useEffect} from 'react';
import {BusinessExpense} from '@compt/types/business-expenses/business-expense';
import {EditBusinessExpenseForm} from './edit-business-expense-form';
import {ComptSidePanel} from '@compt/common/compt-side-panel/compt-side-panel';
import {
  BusinessExpenseStatus,
  businessExpenseStatusFormat,
} from '@compt/types/business-expenses/business-expense-statuses';
import {ComptButton, ComptButtonType} from '@compt/common/compt-button/compt-button';
import {ComptPill, ComptPillSize} from '@compt/common/forms/compt-pill/compt-pill';

interface ExistingBusinessExpenseSidePanelProps {
  open: boolean;
  setOpen: React.Dispatch<SetStateAction<boolean>>;
  selectedExpense: BusinessExpense | null;
  setSelectedExpense: Dispatch<SetStateAction<BusinessExpense | null>>;
  expenseListResults: BusinessExpense[];
  setExpenseToDelete: Dispatch<SetStateAction<BusinessExpense | null>>;
  editMode: boolean;
  setEditMode: Dispatch<SetStateAction<boolean>>;
}

export const ExistingBusinessExpenseSidePanel = (props: ExistingBusinessExpenseSidePanelProps) => {
  const {selectedExpense, expenseListResults, setSelectedExpense} = props;

  const currentSelectedExpenseIndex = expenseListResults.findIndex(
    (expense) => expense.id === selectedExpense?.id,
  );

  const previousExpense = expenseListResults[currentSelectedExpenseIndex - 1];
  const nextExpense = expenseListResults[currentSelectedExpenseIndex + 1];

  const modalTitle = !props.editMode ? 'View business expense' : 'Edit business expense';

  /**
   * Keep view expense modal in sync with the list of business expenses.
   * Handles what happens when the expense is deleted, and when the expense is updated.
   */
  useEffect(() => {
    if (!selectedExpense) {
      return;
    }

    const selectedExpenseInExpenseList = expenseListResults.find(
      (expense) => expense.id === selectedExpense.id,
    );

    if (!selectedExpenseInExpenseList) {
      setSelectedExpense(null);
    }

    setSelectedExpense(selectedExpenseInExpenseList as BusinessExpense);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [expenseListResults, setSelectedExpense]);

  function handlePreviousOrNextButton(expenseToNavigate: BusinessExpense) {
    if (!expenseToNavigate) return;

    setSelectedExpense(() => expenseToNavigate);
  }

  const BusinessExpenseStatusPill = () => {
    if (!selectedExpense) return null;

    const expenseStatus =
      selectedExpense.status === BusinessExpenseStatus.APPROVED_BY_MANAGER
        ? BusinessExpenseStatus.SUBMITTED
        : selectedExpense.status;

    const requestStatusFormat = businessExpenseStatusFormat[expenseStatus];

    return (
      <ComptPill
        className="ml-2"
        pillSize={ComptPillSize.MEDIUM}
        pillType={requestStatusFormat.statusStyle}
      >
        {requestStatusFormat.statusName}
      </ComptPill>
    );
  };
  return (
    <ComptSidePanel open={props.open} className="max-w-[920px]">
      <ComptSidePanel.Header
        title={modalTitle}
        setOpen={() => {
          props.setOpen(false);
          props.setEditMode(false);
        }}
        statusPill={<BusinessExpenseStatusPill />}
        headerIcon={{id: 'receipt-icon'}}
      />

      <EditBusinessExpenseForm
        expenseInfo={selectedExpense}
        editMode={props.editMode}
        setEditMode={props.setEditMode}
      />
      {!props.editMode && (
        <ComptSidePanel.Footer>
          <div className="flex flex-col sm:flex-row sm:justify-between items-center w-full gap-2">
            <div className="flex space-x-3">
              {selectedExpense?.status === BusinessExpenseStatus.OPEN && (
                <>
                  <ComptButton
                    buttonType={ComptButtonType.SECONDARY}
                    onClick={() => props.setEditMode(true)}
                  >
                    Edit
                  </ComptButton>
                  <ComptButton
                    buttonType={ComptButtonType.DESTRUCTIVE}
                    onClick={() => props.setExpenseToDelete(selectedExpense)}
                  >
                    Delete
                  </ComptButton>
                </>
              )}
            </div>
            <div className="flex space-x-3">
              <ComptButton
                buttonType={ComptButtonType.OUTLINED}
                disabled={!previousExpense}
                onClick={() => handlePreviousOrNextButton(previousExpense)}
              >
                Previous
              </ComptButton>
              <ComptButton
                buttonType={ComptButtonType.OUTLINED}
                disabled={!nextExpense}
                onClick={() => handlePreviousOrNextButton(nextExpense)}
              >
                Next
              </ComptButton>
            </div>
          </div>
        </ComptSidePanel.Footer>
      )}
    </ComptSidePanel>
  );
};

import React, {HTMLAttributes, SetStateAction} from 'react';
import {Allotment} from '@compt/types/allotments';
import {ComptCategoryCard} from '@compt/common/compt-category-card/compt-category-card';
import {ComptLink} from '@compt/common/compt-link/compt-link';
import {ComptSvgIcon} from '@compt/common/compt-svg-icon/compt-svg-icon';
import {SupportedCountriesType} from '@compt/utils/international-helpers';
import {CardExpirationText} from '@compt/common/compt-card/expiration-text';
import {ComptCard} from '@compt/common/compt-card/compt-card';
import {PerkCategory, PerkCategoryCustomizations} from '@compt/types/perk-category';
import {getAccrualDescription, getAllotmentCycleTypeName} from '@compt/utils/allotment-helpers';
import {ComptSpentCard} from '@compt/common/compt-card/compt-spent-card';
import {CardExpirationIndicator} from '@compt/common/compt-card/expiration-indicator';
import DOMPurify from 'dompurify';

export interface AllotmentCardProps extends HTMLAttributes<HTMLElement> {
  allotment: Allotment;
  categoryCustomizations: PerkCategoryCustomizations[] | undefined;
  userCountryCode?: SupportedCountriesType;
  onFullDescriptionClicked?: (allotment: Allotment) => void;
  onCategoryClick: (category: PerkCategory, allotment: Allotment) => void;
  setSelectedCategory: React.Dispatch<SetStateAction<PerkCategory | undefined>>;
  setSelectedAllotment: React.Dispatch<SetStateAction<Allotment | undefined>>;
}

export const AllotmentCard = (props: AllotmentCardProps) => {
  const renderCategories = () => {
    const sortedCategories = [...props.allotment.perk_categories]?.sort((a, b) => {
      if (a.name < b.name) {
        return -1;
      }
      if (a.name > b.name) {
        return 1;
      }
      return 0;
    });

    return sortedCategories?.length
      ? sortedCategories.map((category) => (
          <ComptCategoryCard
            key={category.id}
            userCountryCode={props.userCountryCode}
            allotment={props.allotment}
            perkCategory={category}
            categoryCustomizations={props.categoryCustomizations}
            onCategoryClick={() => props.onCategoryClick(category, props.allotment)}
            data-testid={`compt-category-card-${category.id}`}
          />
        ))
      : null;
  };

  return (
    <ComptCard>
      <div className="flex justify-between m-400">
        <h2 className="text-color-body1">{props.allotment.cycle.name}</h2>
        <CardExpirationIndicator allotment={props.allotment} />
      </div>
      <div className="flex flex-row h-[348px] border-t border-gray-300">
        <div className="px-400 pt-400 w-1/2 border-r border-gray-300 flex flex-col">
          <div>
            <ComptSpentCard
              amountTotal={props.allotment.maximum_balance}
              amountSpent={props.allotment.amount_spent}
              currency={props.allotment.currency}
              userCountryCode={props.userCountryCode}
              reversed
            />
            <CardExpirationText
              expirationDate={props.allotment.last_date}
              cycleTypeDescription={getAllotmentCycleTypeName(
                props.allotment.cycle.type,
                props.allotment.cycle.months,
                props.allotment.cycle.sub_type,
              )}
              accrualDescription={getAccrualDescription(props.allotment.cycle)}
            />
          </div>
          <div className="flex flex-col">
            <p
              className="body3 text-color-body1 w-10/12 mt-100 line-clamp-2 overflow-hidden inner-html"
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(props.allotment.cycle.description),
              }}
            />
            {props.allotment.cycle.description && (
              <div className="flex justify-start">
                <ComptLink
                  link="#"
                  textClassName="text-color-link"
                  className="mt-300 mb-600"
                  onClick={(event) => {
                    event.preventDefault();
                    if (props.onFullDescriptionClicked) {
                      props.onFullDescriptionClicked(props.allotment);
                    }
                  }}
                >
                  <p className="flex label3 text-color-link place-content-start">
                    View details
                    <ComptSvgIcon className="flex-row pt-0 sm:pt-0" iconName="chevron-right-icon" />
                  </p>
                </ComptLink>
              </div>
            )}
          </div>
        </div>
        <div className="w-1/2 flex flex-col h-full">
          <p className="heading2 text-color-body1 px-600 pt-400 pb-100 overflow-hidden">
            Submit a claim for:
          </p>
          <ul className="divide-y divide-gray-200 flex-1 overflow-y-scroll px-10">
            {renderCategories()}
          </ul>
        </div>
      </div>
    </ComptCard>
  );
};

import {useState} from 'react';

// RTK queries
import {useUpdateExpenseReviewMutation} from '@compt/app/services/api/admin-stipends-slice';

// Hooks and methods
import {useFilterContext} from '@compt/common/compt-filter-sidebar/filter-context';
import {triggerCustomToast} from '@compt/common/compt-toaster/compt-toaster';
import {useLeanComptTableContext} from '@compt/common/lean-compt-table/lean-compt-table-context';
import {useForm} from 'react-hook-form';
import {useSearchParams} from 'react-router-dom';
import {useFormatFilterQuery, usePaginationQuery} from '../format-query-helper';

// Types
import {MAX_50_PAGE_LIMIT} from '@compt/constants';
import {RejectionReasonForm} from '@compt/types/common/rejection-reason-form';
import {ExpenseStatus} from '@compt/types/stipend-expense';
import {ExpenseReview} from '@compt/types/stipend-expenses/expense-review';

// Components
import {ComptButton, ComptButtonType} from '@compt/common/compt-button/compt-button';
import {ComptRejectionModal} from '@compt/common/compt-rejection-modal/compt-rejection-modal';

export const ReviewStipendFooter = (props: {
  expense?: ExpenseReview;
  expenseIdList: number[];
  onSubmitEdit: () => void;
}) => {
  const [, setSearchParams] = useSearchParams();
  const {editMode, setEditMode} = useLeanComptTableContext();

  if (editMode) {
    return (
      <div className="flex w-full justify-between">
        <div className="flex gap-x-2">
          <ComptButton buttonType={ComptButtonType.PRIMARY} onClick={props.onSubmitEdit}>
            Save
          </ComptButton>
          <ComptButton
            buttonType={ComptButtonType.SECONDARY}
            onClick={() => {
              setEditMode && setEditMode(false);
            }}
          >
            Cancel
          </ComptButton>
        </div>
        <PreviousAndNextButtons expenseIdList={props.expenseIdList} />
      </div>
    );
  }

  if (
    props.expense?.status === ExpenseStatus.Open ||
    props.expense?.status === ExpenseStatus.InReview
  ) {
    return <ApproveRejectEditButtons expense={props.expense} expenseIdList={props.expenseIdList} />;
  }

  return (
    <div className="flex w-full justify-between">
      <ComptButton
        buttonType={ComptButtonType.OUTLINED}
        onClick={() => setSearchParams({})}
        className="text-color"
      >
        Close
      </ComptButton>
      <PreviousAndNextButtons expenseIdList={props.expenseIdList} />
    </div>
  );
};

const ApproveRejectEditButtons = (props: {expense?: ExpenseReview; expenseIdList: number[]}) => {
  const [openRejectModal, setOpenRejectModal] = useState(false);
  const {setEditMode} = useLeanComptTableContext();
  const {setShowRefreshButton} = useFilterContext();

  const formattedFilterQuery = useFormatFilterQuery();
  const paginationQuery = usePaginationQuery(MAX_50_PAGE_LIMIT);

  const [updateExpenseReview, {isLoading: isUpdating}] = useUpdateExpenseReviewMutation();

  const formMethods = useForm<RejectionReasonForm>();

  function onApprove() {
    if (!props.expense) return;

    const submission = {
      id: props.expense?.id,
      company: props.expense?.company,
      allotment_id: props.expense?.allotments[0].id,
      status: ExpenseStatus.Approved,
    };

    updateExpenseReview({
      body: submission,
      filter: {
        ...formattedFilterQuery,
        ...paginationQuery,
        limit: MAX_50_PAGE_LIMIT,
        ordering: 'created_on',
      },
    }).then((results) => {
      if ('error' in results) {
        triggerCustomToast(
          'error',
          'There was a problem approving this expense',
          'Please try again',
        );
        return;
      }

      setShowRefreshButton(true);
      triggerCustomToast('success', 'Successfully approved this expense');
    });
  }

  function onSubmitRejection(form: RejectionReasonForm) {
    if (!props.expense) return;

    const submission = {
      id: props.expense?.id,
      company: props.expense?.company,
      allotment_id: props.expense?.allotments[0].id,
      status: ExpenseStatus.Rejected,
      rejection_reason: form.rejection_reason,
    };

    updateExpenseReview({body: submission}).then((results) => {
      if ('error' in results) {
        triggerCustomToast(
          'error',
          'There was a problem rejecting this expense',
          'Please try again',
        );
        return;
      }

      triggerCustomToast('success', 'Successfully rejected this expense');
      setOpenRejectModal(false);
    });
  }

  return (
    <>
      <ComptRejectionModal
        formMethods={formMethods}
        modalTitle="Reject expense"
        modalText="Please give a reason for rejecting this expense."
        isOpen={openRejectModal}
        setIsOpen={setOpenRejectModal}
        isUpdating={isUpdating}
        onSubmitRejection={onSubmitRejection}
      />
      <div className="flex w-full justify-between">
        <div className="flex gap-x-2">
          <ComptButton buttonType={ComptButtonType.PRIMARY} onClick={onApprove}>
            Approve
          </ComptButton>
          <ComptButton
            buttonType={ComptButtonType.SECONDARY}
            onClick={() => {
              setEditMode && setEditMode(true);
            }}
          >
            Edit
          </ComptButton>
          <ComptButton
            buttonType={ComptButtonType.DESTRUCTIVE}
            onClick={() => setOpenRejectModal(true)}
          >
            Reject
          </ComptButton>
        </div>
        <PreviousAndNextButtons expenseIdList={props.expenseIdList} />
      </div>
    </>
  );
};

const PreviousAndNextButtons = (props: {expenseIdList: number[]}) => {
  const {expenseIdList} = props;

  const [searchParams, setSearchParams] = useSearchParams();
  const expenseId = searchParams.get('expense_id');

  function getPreviousAndNextExpenseId(
    currentId: string | null,
    expenseIdList: number[] | undefined,
  ) {
    if (!currentId || !expenseIdList) return;

    const currentRequestId = parseInt(currentId);
    const currentIndex = expenseIdList.indexOf(currentRequestId);

    const prevExpenseId = expenseIdList[currentIndex - 1] || null;
    const nextExpenseId = expenseIdList[currentIndex + 1] || null;

    return {prevExpenseId, nextExpenseId};
  }

  const previousDisabled = !getPreviousAndNextExpenseId(expenseId, expenseIdList)?.prevExpenseId;
  const nextDisabled = !getPreviousAndNextExpenseId(expenseId, expenseIdList)?.nextExpenseId;

  function onPreviousClick() {
    const previousRequestId = getPreviousAndNextExpenseId(expenseId, expenseIdList)?.prevExpenseId;
    if (!previousRequestId) return;

    setSearchParams({expense_id: `${previousRequestId}`});
  }

  function onNextClick() {
    const nextExpenseId = getPreviousAndNextExpenseId(expenseId, expenseIdList)?.nextExpenseId;
    if (!nextExpenseId) return;

    setSearchParams({expense_id: `${nextExpenseId}`});
  }

  return (
    <div className="flex">
      <ComptButton
        textClassName={`text-color-link ${
          previousDisabled && 'text-color-body1 cursor-not-allowed'
        }`}
        buttonType={ComptButtonType.BORDERLESS}
        disabled={previousDisabled}
        onClick={onPreviousClick}
        className="text-color"
      >
        Previous
      </ComptButton>
      <ComptButton
        textClassName={`text-color-link ${nextDisabled && 'text-color-body1 cursor-not-allowed'}`}
        disabled={nextDisabled}
        buttonType={ComptButtonType.BORDERLESS}
        onClick={onNextClick}
      >
        Next
      </ComptButton>
    </div>
  );
};

export enum IndustryMapping {
  'Accounting' = 1,
  'Banking and investments' = 2,
  'Biotech and pharma' = 3,
  'Food and beverage / hospitality' = 4,
  'Higher education' = 5,
  'Law practice' = 6,
  'Management consulting' = 7,
  'Marketing and advertising' = 8,
  'Medicine' = 9,
  'Software and internet' = 10,
  'Staffing and recruiting' = 11,
  'Travel and leisure' = 12,
  'Benefit broker' = 13,
}

export const industryFromId = (id: number | string | undefined): string | undefined => {
  if (!id) return undefined;
  if (typeof id === 'string') return IndustryMapping[parseInt(id)] || undefined;

  return IndustryMapping[id] || undefined;
};

export const INDUSTRY_OPTIONS = Object.entries(IndustryMapping)
  .filter(([key]) => isNaN(Number(key))) // Filter out reverse mappings
  .map(([label, id]) => ({
    id,
    label,
  }));

import React, {useState, useRef, useEffect, useCallback} from 'react';

// Hooks and methods
import {
  formatDateString,
  isValidDate,
  dateToISODateString,
  DATE_FORMAT_OPTION,
  formattedDate,
} from '@compt/utils/date-helpers';
import {twMerge} from 'tailwind-merge';

// Types
import {ComptFormControlBaseProps} from '@compt/types/form/compt-forms';

// Components
import {ComptButtonIcon} from '../../compt-button/compt-button';
import {ComptSvgIcon} from '../../compt-svg-icon/compt-svg-icon';

/** TODO: Sync date-picker display value to react-hook-form form value when the latter is cleared
 * in COMPT-4199
 */
export interface ComptDatePickerProps extends ComptFormControlBaseProps<Date | string> {
  ['data-testid']?: string;
  allowFutureDates?: boolean;
  startDate?: Date | string | null;
  endDate?: Date | string | null;
  datePickerClassName?: string;
  showClearButton?: boolean;
  propagateClear?: boolean;
}

export enum MonthChangeParam {
  PREVIOUS = 'PREVIOUS',
  NEXT = 'NEXT',
}

export const ComptDatePicker = (props: ComptDatePickerProps) => {
  const datePickerRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const [isOpen, setIsOpen] = useState(false);
  const [savedDate, setSavedDate] = useState<Date | string | null | undefined>(
    props.initialValue || null,
  );
  const [currentSelection, setCurrentSelection] = useState<Date | string | null>();
  const [currentMonth, setCurrentMonth] = useState(new Date());
  const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent);

  useEffect(() => {
    if (props.value) {
      const existingDate = new Date(props.value);
      const utcDate = new Date(
        existingDate.getUTCFullYear(),
        existingDate.getUTCMonth(),
        existingDate.getUTCDate(),
      );
      setSavedDate(utcDate);
      setCurrentMonth(utcDate);
    }

    if (props.value === '' && props.propagateClear) {
      setSavedDate('');
    }
  }, [props.value]);

  const handleMonthChange = (
    event: React.MouseEvent<HTMLButtonElement>,
    previousNext: MonthChangeParam,
  ) => {
    event.preventDefault();
    setCurrentMonth(
      (prevMonth) =>
        new Date(
          prevMonth.getFullYear(),
          prevMonth.getMonth() + (previousNext === MonthChangeParam.PREVIOUS ? -1 : 1),
        ),
    );
  };

  const onChange = (newDate: string) => {
    if (props.onChange) {
      props.onChange(newDate);
    }
  };

  const handleSave = useCallback(() => {
    if (!currentSelection) return;
    setSavedDate(currentSelection);
    onChange(currentSelection ? dateToISODateString(currentSelection) : '');
  }, [currentSelection, props, onChange]);

  const handleDayClick = (date: Date) => {
    if (currentSelection && dateToISODateString(date) === dateToISODateString(currentSelection)) {
      setCurrentSelection(null);
    } else {
      setCurrentSelection(date);
      setIsOpen(false);
    }
  };

  useEffect(() => {
    handleSave();
  }, [currentSelection]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        datePickerRef.current &&
        !datePickerRef.current.contains(event.target as Node) &&
        inputRef.current &&
        !inputRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  // eslint-disable-next-line max-len
  const renderDay = (
    currentDate: Date,
    days: React.ReactElement[],
    keyPrefix: string,
    i: number,
    day?: number,
  ) => {
    const isPrevDay = keyPrefix === 'prev-day';
    const isNextDay = keyPrefix === 'next-day';
    const currentDay = isPrevDay ? day : i;
    const monthOffset = isPrevDay ? -1 : keyPrefix === 'next-day' ? 1 : 0;

    const date = new Date(
      currentMonth.getFullYear(),
      currentMonth.getMonth() + monthOffset,
      currentDay,
    );

    const isCurrentDate = dateToISODateString(date) === dateToISODateString(currentDate);
    const isSavedDate = savedDate && dateToISODateString(date) === dateToISODateString(savedDate);
    const isCurrentSelection =
      currentSelection && dateToISODateString(date) === dateToISODateString(currentSelection);
    const isAfterToday = date > currentDate;

    const isBeforeStartDate = props.startDate && date < new Date(props.startDate);
    const isAfterEndDate = props.endDate && date > new Date(props.endDate);
    const isOutsideRange = isBeforeStartDate || isAfterEndDate;
    const isOutsideCurrentMonth = isPrevDay || isNextDay;
    const isDisabled = (isAfterToday && !props.allowFutureDates) || isOutsideRange;
    const hoverBackground = isCurrentSelection ? 'hover:bg-base-300' : 'hover:bg-gray-200';

    days.push(
      <div
        key={`${keyPrefix}-${i}`}
        className={`
        flex items-center justify-center w-8 h-8 rounded-full
        ${isOutsideCurrentMonth ? 'text-gray-400' : ''}
        ${
          isCurrentSelection || (isSavedDate && !currentSelection)
            ? 'bg-base-500 text-color-light'
            : ''
        }
        ${
          isDisabled
            ? 'text-gray-400 cursor-not-allowed hover:bg-white'
            : `cursor-pointer ${hoverBackground}`
        }
        ${isCurrentDate ? 'border-solid border-base-500 border-2' : ''}
    `}
        onClick={(e) => {
          if ((isAfterToday && !props.allowFutureDates) || isOutsideRange) {
            e.preventDefault();
          } else {
            handleDayClick(date);
          }
        }}
      >
        {currentDay}
      </div>,
    );
  };

  const renderCalendar = () => {
    const daysInLastMonth = new Date(
      currentMonth.getFullYear(),
      currentMonth.getMonth(),
      0,
    ).getDate();
    const daysInMonth = new Date(
      currentMonth.getFullYear(),
      currentMonth.getMonth() + 1,
      0,
    ).getDate();
    const firstDay = new Date(currentMonth.getFullYear(), currentMonth.getMonth(), 1).getDay();
    const lastDay = new Date(currentMonth.getFullYear(), currentMonth.getMonth() + 1, 0).getDay();
    const days: React.ReactElement[] = [];
    const currentDate = new Date();
    currentDate.setHours(0, 0, 0, 0);

    // Render days from the previous month
    for (let i = firstDay - 1, day = daysInLastMonth - i; i >= 0; i--, day++) {
      renderDay(currentDate, days, 'prev-day', i, day);
    }

    // Render days of the current month
    for (let i = 1; i <= daysInMonth; i++) {
      renderDay(currentDate, days, 'day', i);
    }

    // Render days from the next month
    for (let i = 1; i < 7 - lastDay; i++) {
      renderDay(currentDate, days, 'next-day', i);
    }

    return (
      <div className="grid grid-cols-7 gap-1 p-1">
        <div className="text-center text-color-tertiary">S</div>
        <div className="text-center text-color-tertiary">M</div>
        <div className="text-center text-color-tertiary">T</div>
        <div className="text-center text-color-tertiary">W</div>
        <div className="text-center text-color-tertiary">T</div>
        <div className="text-center text-color-tertiary">F</div>
        <div className="text-center text-color-tertiary">S</div>
        {days}
      </div>
    );
  };

  const _formatDateString = (date: string | Date) => {
    if (typeof date === 'string') {
      return formatDateString(date);
    }

    return formatDateString(dateToISODateString(date));
  };

  const suppressIOSInputChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    e.target.value = '';
  };

  const showDatePicker = () => {
    if (!isOpen) {
      setCurrentSelection(savedDate);
    }
    setIsOpen(true);
  };

  const handleOnChange: React.ChangeEventHandler<HTMLInputElement> = isIOS
    ? suppressIOSInputChange
    : (e) => {
        onChange(e.target.value);
        const [year, month, day] = e.target.value.split('-').map((element) => Number(element));
        let date = null;
        if (year.toString().length === 4) {
          date = new Date(year, month - 1, day);
        }
        if (date && isValidDate(date)) {
          setSavedDate(date);
          setCurrentSelection(date);
        }
      };
  const formatDateFieldValue = (date: string | Date) =>
    isIOS ? formattedDate(savedDate, DATE_FORMAT_OPTION['mm/dd/yyyy']) : _formatDateString(date);

  return (
    <div className="relative">
      <div ref={inputRef}>
        <div className="relative rounded-md shadow-sm overflow-hidden">
          <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
            <ComptSvgIcon iconName="calendar" labelHidden={false} ariaLabel="calendar-icon" />
          </div>
          <input
            ref={props.inputRef as React.Ref<HTMLInputElement>}
            name={props.name || props.id}
            placeholder={props.placeholder || 'mm/dd/yyyy'}
            type={isIOS ? 'text' : 'date'}
            data-testid={props['data-testid']}
            disabled={props.disabled || isOpen}
            value={
              (props.value && formatDateFieldValue(props.value)) ||
              (savedDate && formatDateFieldValue(savedDate)) ||
              ''
            }
            onClick={(e) => {
              e.preventDefault();
              showDatePicker();
              if (isOpen === false) {
                setCurrentSelection(savedDate);
              }
              setIsOpen(true);
            }}
            onChange={handleOnChange}
            className={`
              block w-full rounded-md border-0 py-100 pl-10 text-color-body2 body1
              ring-1 ring-inset placeholder:text-gray-300 sm:leading-6 select-none
              focus:ring-2 disabled:bg-surface-disabled disabled:text-disabled-on-light
              ${
                props.invalid
                  ? 'ring-stroke-critical focus:ring-stroke-critical'
                  : 'ring-stroke-tertiary focus:ring-stroke-focus'
              }
            `}
            onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
              (e.key === 'Enter' || e.key === ' ') && e.preventDefault();
            }}
            onFocus={(e) => {
              e.preventDefault();
              showDatePicker();
            }}
          />
          {props.showClearButton && savedDate && (
            <button
              className={`pointer-cursor absolute px-2 inset-y-0 right-0 flex 
              items-center hover:bg-surface-secondary-tint`}
              onClick={() => {
                if (props.onChange) {
                  props.onChange('');
                }
                setIsOpen(false);
              }}
            >
              <ComptSvgIcon iconName="x-icon" ariaLabel="clear-date" svgProp={{width: 20}} />
            </button>
          )}
        </div>
      </div>
      <div
        ref={datePickerRef}
        className={twMerge(`
          absolute z-10 top-12 bg-white border border-gray-300
          shadow-md rounded-md transform transition-opacity duration-300
          opacity-${isOpen ? '100' : '0 pointer-events-none'} ${
            // Allow date picker position to be redefined while keeping legacy behavior
            props.datePickerClassName ? props.datePickerClassName : 'left-0'
          }
        `)}
      >
        <div className="flex justify-between p-2">
          <button onClick={(e) => handleMonthChange(e, MonthChangeParam.PREVIOUS)}>
            <ComptSvgIcon iconName={ComptButtonIcon.CHEVRON_LEFT} />
          </button>
          <div className="text-lg font-bold">
            {currentMonth.toLocaleString('default', {
              month: 'short',
              year: 'numeric',
            })}
          </div>
          <button onClick={(e) => handleMonthChange(e, MonthChangeParam.NEXT)}>
            <ComptSvgIcon iconName={ComptButtonIcon.CHEVRON_RIGHT} />
          </button>
        </div>
        {renderCalendar()}
      </div>
    </div>
  );
};

import {DateString} from '@compt/types/common/date-string';
import {LearningRequestStatus} from '@compt/types/learning-development/learning-request-status';

export const LearningStatusFormats: Record<
  LearningRequestStatus,
  {label: string; statusStyle: string}
> = {
  [LearningRequestStatus.PRE_APPROVAL_REQUESTED]: {
    label: 'Pre-approval requested',
    statusStyle: 'secondary',
  },
  [LearningRequestStatus.PARTIALLY_APPROVED]: {
    label: 'Partially approved',
    statusStyle: 'primary',
  },
  [LearningRequestStatus.PRE_APPROVAL_REJECTED]: {
    label: 'Pre-approval rejected',
    statusStyle: 'destructive',
  },
  [LearningRequestStatus.PRE_APPROVED]: {
    label: 'Pre-approved',
    statusStyle: 'primary',
  },
  [LearningRequestStatus.REIMBURSEMENT_REQUESTED]: {
    label: 'Reimbursement requested',
    statusStyle: 'secondary',
  },
  [LearningRequestStatus.REIMBURSEMENT_REJECTED]: {
    label: 'Reimbursement rejected',
    statusStyle: 'destructive',
  },
  [LearningRequestStatus.REIMBURSEMENT_APPROVED]: {
    label: 'Reimbursement approved',
    statusStyle: 'primary',
  },
  [LearningRequestStatus.REIMBURSED]: {
    label: 'Reimbursed',
    statusStyle: 'success',
  },
};

export interface ReviewLndPayload {
  id: number;
  user: {
    full_name: string;
    email: string;
  };
  request_information: {
    request_number: string;
    amount: number;
    program: string;
    last_updated: DateString;
    status: string;
  };
}

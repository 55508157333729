import React from 'react';

// Hooks and methods
import {twMerge} from 'tailwind-merge';
import {formatCurrency} from '@compt/utils/international-helpers';
import {calculateOverBudget, getAsNumber} from '@compt/utils/lnd-budget-helpers';

// Types
import {PreApprovalRequest} from '@compt/types/learning-development/pre-approval-request';

interface ReviewLndComputedSectionProps {
  request: PreApprovalRequest;
  showOverBudget: boolean;
  overBudgetErrorMessage: string;
  watchedAmount?: number;
}

export const ReviewLndComputedSection = (props: ReviewLndComputedSectionProps) => {
  const {request} = props;
  const requestAmountCurrency = request.program_budget.program_funding_rule.currency;
  const amountAvailable =
    getAsNumber(request.program_budget.amount) -
    getAsNumber(request.program_budget.amount_committed);

  const overBudgetCalculation = calculateOverBudget(amountAvailable, props.watchedAmount);
  const isOverBudget = overBudgetCalculation > 0;

  return (
    <div className="grid gap-y-2 mb-8">
      <div className="flex justify-between">
        <p>Available budget</p>
        <p className="label1">{formatCurrency(amountAvailable, requestAmountCurrency)}</p>
      </div>
      {props.showOverBudget && (
        <div className="flex justify-between">
          <p>Over budget</p>
          <p className={twMerge(`label1 ${isOverBudget && 'text-color-error'}`)}>
            {formatCurrency(overBudgetCalculation, requestAmountCurrency)}
          </p>
        </div>
      )}
      {isOverBudget && <div className="body2 text-color-error">{props.overBudgetErrorMessage}</div>}
    </div>
  );
};

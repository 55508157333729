import React, {ReactNode} from 'react';

// Hooks and methods
import {generateLocalStorageKey, useCacheableObjectState} from '@compt/utils/local-storage-helper';

// Components
import {LndDropDown} from '@compt/pages/admin-pages/admin-review-programs/professional-development/components/review-lnd-dropdown';

interface ComposableCollapsibleProps {
  collapsibleId: string;
  appliedFilterCount: number;
  label: string;
  testId?: string;
  children: ReactNode;
}

export const ComposableCollapsible = (props: ComposableCollapsibleProps) => {
  const [collapsibleState, setCollapsibleState] = useCacheableObjectState(
    generateLocalStorageKey(props.collapsibleId, 'filter-sidebar-dropdown'),
    {isOpen: true},
    false,
  );

  return (
    <div className="pb-3" key={props.collapsibleId}>
      <LndDropDown
        id={`sidebar-${props.collapsibleId}-filter`}
        data-testid={props.testId}
        appliedFilterCount={props.appliedFilterCount}
        value={{id: props.collapsibleId, label: props.label}}
        onClick={() => setCollapsibleState((prevState) => ({isOpen: !prevState.isOpen}))}
        isSelected={collapsibleState.isOpen}
      />
      {collapsibleState.isOpen && (
        <div className="flex flex-col my-3 space-y-2">{props.children}</div>
      )}
    </div>
  );
};

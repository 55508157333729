import React, {useEffect, useState, useRef, useCallback} from 'react';

interface ButtonPosition {
  bottom: number;
  left?: string;
  transform?: string;
}

export const TableFloatingButton = ({
  selectedCount,
  onApprove,
  tableRef,
  mobilePaginationRef,
  desktopPaginationRef,
}: {
  selectedCount: number;
  onApprove: () => void;
  tableRef: React.RefObject<HTMLDivElement>;
  mobilePaginationRef?: React.RefObject<HTMLDivElement>;
  desktopPaginationRef?: React.RefObject<HTMLDivElement>;
}) => {
  const [position, setPosition] = useState<ButtonPosition>({bottom: 20});
  const buttonRef = useRef<HTMLDivElement>(null);

  // Define calculatePosition as a memoized callback to ensure consistency
  const calculatePosition = useCallback(() => {
    const tableContainer = tableRef.current;
    if (!tableContainer) return;

    const tableRect = tableContainer.getBoundingClientRect();
    const pagination =
      (mobilePaginationRef && mobilePaginationRef.current) ||
      (desktopPaginationRef && desktopPaginationRef.current);

    let bottomPosition = 50;
    if (pagination) {
      const paginationRect = pagination.getBoundingClientRect();
      // If pagination is in view, position above it
      if (paginationRect.top < window.innerHeight) {
        const distanceFromBottom = window.innerHeight - paginationRect.top;
        bottomPosition = distanceFromBottom + 30;
      }
    }

    const left = tableRect.left + tableRect.width / 2;
    setPosition({
      bottom: bottomPosition,
      left: `${left}px`,
      transform: 'translateX(-50%)',
    });
  }, [tableRef, mobilePaginationRef, desktopPaginationRef]);

  // Set up scroll monitoring with requestAnimationFrame for smoother performance
  useEffect(() => {
    let ticking = false;
    let rafId: number | null = null;

    const handleScroll = () => {
      if (!ticking) {
        rafId = window.requestAnimationFrame(() => {
          calculatePosition();
          ticking = false;
        });
        ticking = true;
      }
    };

    // Initial calculation
    calculatePosition();
    window.addEventListener('scroll', handleScroll, {passive: true});

    // Add scroll listening to parent elements too
    if (tableRef.current) {
      let parent = tableRef.current.parentElement;
      while (parent) {
        parent.addEventListener('scroll', handleScroll, {passive: true});
        parent = parent.parentElement;
      }
    }

    const observer = new IntersectionObserver(
      (entries) => {
        calculatePosition();
      },
      {
        rootMargin: '0px',
        threshold: [0, 0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1],
      },
    );

    if (tableRef.current) {
      observer.observe(tableRef.current);
      const pagination =
        (mobilePaginationRef && mobilePaginationRef.current) ||
        (desktopPaginationRef && desktopPaginationRef.current);
      if (pagination) {
        observer.observe(pagination);
      }
    }

    // Interval fallback to periodically check position
    const intervalId = setInterval(calculatePosition, 300);

    return () => {
      if (rafId) window.cancelAnimationFrame(rafId);
      window.removeEventListener('scroll', handleScroll);
      if (tableRef.current) {
        let parent = tableRef.current.parentElement;
        while (parent) {
          parent.removeEventListener('scroll', handleScroll);
          parent = parent.parentElement;
        }
      }
      observer.disconnect();
      clearInterval(intervalId);
    };
  }, [calculatePosition, tableRef, mobilePaginationRef, desktopPaginationRef]);

  if (selectedCount <= 0) return null;
  const itemText = selectedCount === 1 ? 'item' : 'items';

  return (
    <div
      ref={buttonRef}
      className="fixed text-white shadow-xl px-4 py-2 z-50
      border border-base-500 bg-base-500 rounded-[100px]"
      style={{
        bottom: `${position.bottom}px`,
        left: position.left,
        transform: position.transform,
      }}
    >
      <button onClick={onApprove} className="flex items-center">
        Approve {selectedCount} selected {itemText}
      </button>
    </div>
  );
};

import React from 'react';

// Types
import {INDUSTRY_OPTIONS} from '@compt/types/industry';

// Hooks and methods
import {useFilterContext} from '@compt/common/compt-filter-sidebar/filter-context';

// Components
import {MultiOptionCollapsible} from '@compt/common/compt-filter-sidebar/multi-option-collapsible';
import {
  FilterSideBar,
  handleSingleOptionSelection,
} from '@compt/common/compt-filter-sidebar/compt-filter-sidebar';

export enum CommunityFilter {
  INDUSTRY = 'authors_industry__in',
  COMPANY = 'only_company_reviews',
  SEARCH = 'user_entered_keywords',
}

export const CommunityFeedSidebar = () => {
  const {filters, setFilters} = useFilterContext();

  return (
    <FilterSideBar disableCollapse>
      <div className="flex flex-col mt-4 space-y-2">
        <MultiOptionCollapsible
          id="community-feed-industry"
          label="Industry"
          filterKey={CommunityFilter.INDUSTRY}
          options={INDUSTRY_OPTIONS as {id: string; label: string}[]}
          getOptionKey={(option) => option.id}
          getOptionLabel={(option) => option.label}
        />
      </div>
      <hr className="my-4 mr-4 border-gray-300" />
      <label key="my-company-only-community-feed" className="flex items-center space-x-1">
        <input
          type="checkbox"
          className={`h-4 w-4 rounded border-gray-300 text-color-link checked:bg-base-500
              focus:ring-base-500 cursor-pointer mr-100 disabled:bg-gray-400 
              disabled:hover:bg-gray-400`}
          checked={filters?.[CommunityFilter.COMPANY] === 'true'}
          onChange={(e) =>
            handleSingleOptionSelection(CommunityFilter.COMPANY, e.target.checked, setFilters)
          }
        />
        <span className="text-md text-gray-700">Only my company</span>
      </label>
    </FilterSideBar>
  );
};

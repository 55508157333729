import React, {createContext, ReactNode, useContext, useState} from 'react';

// Types
import {DateString} from '@compt/types/common/date-string';

interface ContextProps {
  startDate: Date | null;
  setStartDate: React.Dispatch<React.SetStateAction<Date | null>>;
  initialStartDateObject: Date | null;
  endDate: Date | null;
  setEndDate: React.Dispatch<React.SetStateAction<Date | null>>;
  initialEndDateObject: Date | null;
}

export const DateRangePickerContext = createContext<ContextProps>({
  startDate: null,
  endDate: null,
  setStartDate: () => {},
  setEndDate: () => {},
  initialStartDateObject: null,
  initialEndDateObject: null,
});

interface Props {
  initialStartDate: DateString;
  initialEndDate: DateString;
  children: ReactNode;
}

export const DateRangePickerContextProvider: React.FC<Props> = ({
  initialStartDate,
  initialEndDate,
  children,
}) => {
  const initialStartDateObject = initialStartDate === '' ? null : new Date(initialStartDate);
  const initialEndDateObject = initialEndDate === '' ? null : new Date(initialEndDate);

  const [startDate, setStartDate] = useState<Date | null>(initialStartDateObject);
  const [endDate, setEndDate] = useState<Date | null>(initialEndDateObject);

  return (
    <DateRangePickerContext.Provider
      value={{
        startDate,
        endDate,
        setStartDate,
        setEndDate,
        initialStartDateObject,
        initialEndDateObject,
      }}
    >
      {children}
    </DateRangePickerContext.Provider>
  );
};

export const useDateRangePickerContext = (): ContextProps => {
  const context = useContext(DateRangePickerContext);
  if (!context) {
    throw new Error(
      'useDateRangePickerContext must be used within a DateRangePickerContextProvider',
    );
  }
  return context;
};

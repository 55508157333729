import React, {Dispatch, SetStateAction, useState} from 'react';

// Hooks and methods
import {useForm} from 'react-hook-form';
import {useNavigate} from 'react-router-dom';
import {useFormStepperContext} from '@compt/common/compt-form-stepper/compt-form-stepper';
import {triggerCustomToast} from '@compt/common/compt-toaster/compt-toaster';

// RTK queries
import {
  useCreateReimbursementRequestFieldMutation,
  useDeleteReimbursementRequestFieldMutation,
  useGetReimbursementRequestFieldsQuery,
  useUpdateLearningDevelopmentProgramMutation,
  useUpdateReimbursementRequestFieldMutation,
} from '@compt/app/services/api/learning-development-slice';

// Components
import {ComptSvgIcon} from '@compt/common/compt-svg-icon/compt-svg-icon';
import {ProgramFormFooter} from '@compt/pages/admin-pages/admin-learning-development/components/form-footer';
import {RequestFields} from '@compt/pages/admin-pages/admin-learning-development/components/program-creation-request-fields/request-fields';
import {
  RequestFormFieldValues,
  RequestFormSidePanel,
} from '@compt/pages/learning-page/components/request-form-side-panel/request-form-side-panel';
import {LearningDevelopmentStepper} from '../learning-development-stepper';

// Types
import {StepStatus} from '@compt/common/compt-progress-stepper/compt-progress-stepper';
import {LnDProgramForms} from '@compt/pages/admin-pages/admin-learning-development/learning-development.types';
import {
  LearningDevelopmentProgram,
  RequestField,
  RequestFieldType,
  RequestType,
} from '@compt/types/learning-development/learning-development-program';
import {CreateCustomFieldSidePanel} from '@compt/pages/admin-pages/admin-learning-development/components/program-creation-request-fields/create-request-field-side-panel';
import {
  ComptButton,
  ComptButtonIcon,
  ComptButtonType,
} from '@compt/common/compt-button/compt-button';
import {UpdateCustomFieldSidePanel} from '@compt/pages/admin-pages/admin-learning-development/components/program-creation-request-fields/update-request-field-side-panel';
import {useConfirmationModal} from '@compt/utils/confirmation-modal-helper';
import {ModalType} from '@compt/common/compt-confirmation-modal/compt-confirmation.modal';

interface ReimbursementRequestFieldValues {
  field_name: string;
  supporting_text: string;
  field_type: RequestFieldType;
  sequence: number;
}

interface ReimbursementRequestFormProps {
  existingData: LearningDevelopmentProgram;
  readOnly: boolean;
  setUpdated: Dispatch<SetStateAction<boolean>>;
}

export const ProgramCreationReimbursementRequestForm = (props: ReimbursementRequestFormProps) => {
  const navigate = useNavigate();
  const [showReimbursementRequestForm, setShowReimbursementRequestForm] = useState(false);
  const [showCustomFieldForm, setShowCustomFieldForm] = useState(false);
  const [fieldData, setFieldData] = useState<RequestField | null>(null);

  const formMethods = useForm<ReimbursementRequestFieldValues>();
  const previewFormMethods = useForm<RequestFormFieldValues>();

  const {dirtyFields} = formMethods.formState;
  const [deleteField] = useDeleteReimbursementRequestFieldMutation();
  const {modal: confirmDeleteModal, showModal: showDeleteModal} = useConfirmationModal(
    'Are you sure you want to delete this request field? This action cannot be undone.',
    'Confirm delete',
    ModalType.DESTRUCTIVE,
    'Delete',
  );

  const {existingData} = props;

  const [updateProgram, {isLoading: isUpdating}] = useUpdateLearningDevelopmentProgramMutation();
  const [createRequestField] = useCreateReimbursementRequestFieldMutation();
  const [updateRequestField] = useUpdateReimbursementRequestFieldMutation();

  const customFieldListQuery = useGetReimbursementRequestFieldsQuery(
    {companyId: existingData.company?.id, programId: existingData.id},
    {skip: !existingData.company?.id || !existingData.id},
  );

  const customFields = customFieldListQuery.data?.results
    ? [...customFieldListQuery.data.results].sort((a, b) => a.sequence - b.sequence)
    : [];

  const defaultFields = [...existingData.reimbursement_configuration.request_fields].sort(
    (a, b) => a.sequence - b.sequence,
  );

  const requestFields = [...defaultFields, ...customFields];
  const {setStepStatusMap, stepStatusBase} = useFormStepperContext();

  function onReimbursementRequestFieldActiveToggle(field: RequestField) {
    const submission = {
      programId: existingData.id,
      companyId: existingData.company.id,
      fieldId: field.id,
      body: {
        ...field,
        is_active: !field.is_active,
      },
    };

    updateRequestField(submission).then((results) => {
      if ('error' in results) {
        triggerCustomToast('error', 'There was a problem creating your custom field');
        return;
      }
    });
  }

  function onReimbursementRequestFieldRequiredToggle(field: RequestField) {
    const submission = {
      programId: existingData.id,
      companyId: existingData.company.id,
      fieldId: field.id,
      body: {
        ...field,
        is_required: !field.is_required,
      },
    };

    updateRequestField(submission).then((results) => {
      if ('error' in results) {
        triggerCustomToast('error', 'There was a problem creating your custom field');
        return;
      }
    });
  }

  function onReimbursementRequestUpdate(
    form: ReimbursementRequestFieldValues,
    buttonCallback: (updatedData: LearningDevelopmentProgram) => void,
  ) {
    const updatedSubmission = {
      id: existingData.id,
      name: existingData.name,
      field_name: form.field_name,
      supporting_text: form.supporting_text,
      field_type: form.field_type,
      company: {
        id: existingData.company.id,
        name: existingData.company.name,
      },
    };

    updateProgram(updatedSubmission).then((results) => {
      if ('error' in results) {
        triggerCustomToast(
          'error',
          'There was a problem updating your program',
          'Please try again.',
        );
        return;
      }

      buttonCallback(results.data);
    });
  }

  async function onReimbursementRequestFieldDelete(field: RequestField) {
    const confirmDelete = await showDeleteModal();
    if (!confirmDelete) return;

    const submission = {
      companyId: existingData.company.id,
      programId: existingData.id,
      fieldId: field.id,
    };

    deleteField(submission).then((results) => {
      if ('error' in results) {
        triggerCustomToast(
          'error',
          `Failed to update field: ${field.field_name}`,
          'Please try again.',
        );
        return;
      }
    });
  }

  function onReimbursementRequestFieldEdit(field: RequestField) {
    setFieldData(() => field);
  }
  function onReimbursementRequestFieldUpdate(field: RequestField) {
    const updatedFieldData = {
      field_name: field.field_name,
      field_type: field.field_type,
      sequence: field.sequence,
    };

    if (field.is_editable) {
      updateRequestField({
        companyId: existingData.company.id,
        programId: existingData.id,
        fieldId: field.id,
        body: updatedFieldData,
      }).then((results) => {
        if ('error' in results) {
          triggerCustomToast(
            'error',
            'There was a problem updating the reimbursement request fields.',
          );
          return;
        }
      });
    }
  }

  function onPreviousClick() {
    setStepStatusMap(() => ({
      ...stepStatusBase,
      [LnDProgramForms.PRE_APPROVAL_REQUEST]: StepStatus.CURRENT,
    }));
  }

  function onNextClick() {
    props.setUpdated(true);
    if (Object.keys(dirtyFields).length === 0) {
      setStepStatusMap((prevState) => ({
        ...prevState,
        [LnDProgramForms.REIMBURSEMENT_REQUEST]: StepStatus.COMPLETE,
        [LnDProgramForms.APPROVER_REVIEWER]: StepStatus.CURRENT,
      }));
      return;
    }

    formMethods.handleSubmit((form) =>
      onReimbursementRequestUpdate(form, () => {
        setStepStatusMap((prevState) => ({
          ...prevState,
          [LnDProgramForms.REIMBURSEMENT_REQUEST]: StepStatus.COMPLETE,
          [LnDProgramForms.APPROVER_REVIEWER]: StepStatus.CURRENT,
        }));
      }),
    )();
  }

  function onSaveDraftClick() {
    if (Object.keys(dirtyFields).length === 0) {
      if (existingData.is_draft) {
        triggerCustomToast('success', 'Program saved as a draft');
      }
      return;
    }

    formMethods.handleSubmit((form) => {
      onReimbursementRequestUpdate(form, (updatedData: LearningDevelopmentProgram) => {
        if (updatedData.is_draft) {
          triggerCustomToast('success', 'Program saved as a draft');
        }
      });
    })();
  }

  return (
    <>
      {confirmDeleteModal}
      <CreateCustomFieldSidePanel
        open={showCustomFieldForm}
        setOpen={setShowCustomFieldForm}
        programData={existingData}
        configurationId={existingData.reimbursement_configuration.id}
        createRequestField={createRequestField}
        requestFieldsLength={requestFields.length}
      />
      <UpdateCustomFieldSidePanel
        fieldData={fieldData}
        setFieldData={setFieldData}
        programData={existingData}
        readOnly={props.readOnly}
        configurationId={existingData.reimbursement_configuration.id}
        updateRequestField={updateRequestField}
      />
      <RequestFormSidePanel
        id="admin-reimbursement-request-preview-form"
        title="Preview - Employee request form"
        requestType={RequestType.REIMBURSEMENT}
        open={showReimbursementRequestForm}
        setOpen={setShowReimbursementRequestForm}
        onSubmit={() => null}
        formMethods={previewFormMethods}
        program={props.existingData}
        requestFields={props.existingData.reimbursement_configuration.request_fields}
        isPreview
        showCommentsTab={false}
      />
      <div className="flex flex-col h-full pb-900 justify-between">
        <LearningDevelopmentStepper isFormDirty={Object.keys(dirtyFields).length > 0}>
          <div className="grid gap-y-2">
            <h3 className="text-color-body1">Reimbursement request form</h3>
            <p className="body1 text-color-body2">
              Define what information an employee will need to submit to make a request for
              reimbursement.
            </p>
          </div>
          <div className="grid pt-6 gap-y-4">
            <p className="body2 text-color-body1">
              Select which fields employees will need to input in order to request a reimbursement.
            </p>
            <p
              className="flex label3 text-color-link place-content-start items-center cursor-pointer"
              onClick={() => setShowReimbursementRequestForm(true)}
            >
              <ComptSvgIcon className="pr-2" iconName="eye-icon-link" />
              Preview employee form
            </p>
          </div>
          <div className="request-fields">
            {requestFields && (
              <RequestFields
                requestFields={requestFields}
                onDelete={onReimbursementRequestFieldDelete}
                onEdit={onReimbursementRequestFieldEdit}
                onActiveToggle={onReimbursementRequestFieldActiveToggle}
                onRequiredToggle={onReimbursementRequestFieldRequiredToggle}
                onReorder={(fields) => {
                  fields.forEach((field) => onReimbursementRequestFieldUpdate(field));
                }}
                readOnly={props.readOnly}
              />
            )}
          </div>
          {!props.readOnly && (
            <ComptButton
              buttonType={ComptButtonType.SECONDARY}
              iconId={ComptButtonIcon.PLUS_BLUE}
              onClick={() => setShowCustomFieldForm(true)}
              className="mb-10"
            >
              Add field
            </ComptButton>
          )}
        </LearningDevelopmentStepper>
        <ProgramFormFooter
          className="flex flex-shrink-0"
          isUpdating={isUpdating}
          readOnly={props.readOnly}
          onCloseCancel={() => {
            formMethods.reset();
            navigate('/manage-programs');
          }}
          onPreviousClick={onPreviousClick}
          onSaveDraftClick={onSaveDraftClick}
          onNextClick={onNextClick}
        />
      </div>
    </>
  );
};

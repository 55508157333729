import React, {useEffect, useState} from 'react';
import {Allotment, AllotmentCycleFundingMode} from '@compt/types/allotments';
import {useGetAllotmentsQuery} from '@compt/app/services/api/allotments-slice';
import {skipToken} from '@reduxjs/toolkit/query';
import {comptApiSlice, ComptRTKTags, useGetSessionQuery} from '@compt/app/services/api/api-slice';
import {GiveTeamRecognitionForm} from './components/give-team-recognition-form/give-team-recognition-form.container';
import {useSearchParams} from 'react-router-dom';
import {FullAllotmentDescriptionModal} from '@compt/pages/employee-stipends/components/full-allotment-description-modal';
import {Error404Page} from '@compt/pages/404-error-page/error-404-page';
import {USER_ROLES} from '@compt/utils/user-roles-helper';
import {useAppDispatch} from '@compt/app/hooks';
import {Analytics} from '@compt/utils/analytics/analytics-helpers';
import {EVENT_CATEGORY, TEAM_RECOGNITION_ACTIONS_NAMES} from '@compt/utils/analytics/types';
import {MobileTeamRecognitionLayout} from './components/layouts/mobile-team-recognition-layout';
import {TeamRecognitionLayout} from './components/layouts/team-recognition-layout';
import {triggerCustomToast} from '@compt/common/compt-toaster/compt-toaster';
import {ComptLoadingIndicator} from '@compt/common/compt-loading/compt-loading';

const ALLOTMENT_QUERY_KEY = 'from_stipend';

export const TeamRecognitionPage = () => {
  const sessionQuery = useGetSessionQuery();
  const hasRequiredRole = sessionQuery.data?.roles.includes(USER_ROLES.stipendEligible);

  const userId = sessionQuery.data?.user_id;
  const allotmentQuery = useGetAllotmentsQuery(userId ?? skipToken);
  const teamBonusAllotments = allotmentQuery.data?.team_bonus_allotments;
  const [searchParams, setSearchParams] = useSearchParams();
  const [showRecognitionForm, setShowRecognitionForm] = useState(false);
  const [allotmentToDescribe, setAllotmentToDescribe] = useState<Allotment | null>(null);

  useEffect(() => {
    const allotmentId = searchParams.get(ALLOTMENT_QUERY_KEY);

    if (allotmentId) {
      setShowRecognitionForm(true);
    }
  }, [searchParams]);

  function handleSelectAllotment(allotment: Allotment) {
    if (
      allotment.cycle.funding_mode !== AllotmentCycleFundingMode.NoFunding &&
      allotment.balance_amount === 0
    ) {
      triggerCustomToast('error', 'You do not have any amount remaining');
      return;
    }

    setSearchParams(
      (prev) => {
        prev.set(ALLOTMENT_QUERY_KEY, `${allotment.id}`);
        return prev;
      },
      {replace: true},
    );
  }

  function hideForm(allotment?: Allotment) {
    setShowRecognitionForm(false);
    setSearchParams(
      (prev) => {
        prev.delete(ALLOTMENT_QUERY_KEY);
        return prev;
      },
      {replace: true},
    );
    dispatch(comptApiSlice.util.invalidateTags([ComptRTKTags.TEAM_RECOGNITION_LIST]));
    allotmentQuery.refetch();

    if (allotment) {
      Analytics.sendEvent({
        action:
          allotment.cycle.funding_mode === AllotmentCycleFundingMode.NoFunding
            ? TEAM_RECOGNITION_ACTIONS_NAMES.GAVE_TEAM_RECOGNITION
            : TEAM_RECOGNITION_ACTIONS_NAMES.GAVE_SHOUT_OUT,
        category: EVENT_CATEGORY.TEAM_RECOGNITION,
        expirationDate: allotment.last_date,
      });
    }
  }

  const dispatch = useAppDispatch();

  if (!hasRequiredRole) {
    return <Error404Page />;
  }

  return (
    <ComptLoadingIndicator
      isLoading={sessionQuery.isLoading || allotmentQuery.isLoading}
      className="h-full"
    >
      <GiveTeamRecognitionForm
        open={showRecognitionForm}
        hideForm={hideForm}
        openForm={() => setShowRecognitionForm(true)}
        searchParams={searchParams}
        teamBonusAllotments={teamBonusAllotments}
        queryKey={ALLOTMENT_QUERY_KEY}
      />
      {allotmentToDescribe && (
        <FullAllotmentDescriptionModal
          open={true}
          allotment={allotmentToDescribe}
          setOpen={() => setAllotmentToDescribe(null)}
        />
      )}
      <div className="flex h-full">
        <TeamRecognitionLayout
          handleSelectAllotment={handleSelectAllotment}
          setAllotmentToDescribe={setAllotmentToDescribe}
          setShowRecognitionForm={setShowRecognitionForm}
        />
        <MobileTeamRecognitionLayout
          handleSelectAllotment={handleSelectAllotment}
          setAllotmentToDescribe={setAllotmentToDescribe}
          setShowRecognitionForm={setShowRecognitionForm}
        />
      </div>
    </ComptLoadingIndicator>
  );
};

import React from 'react';

// Types
import {BusinessExpenseReport} from '@compt/types/business-expenses/business-expense-report';

import {REPORT_ID_PREFIX} from '@compt/constants';
import {expenseReportStatusFormats} from '@compt/types/business-expenses/business-expense-report-statuses';

// Hooks and methods
import {formatCurrencyFromCountryCode} from '@compt/utils/international-helpers';
import {DATE_FORMAT_OPTION, formattedDate} from '@compt/utils/date-helpers';

// Components
import {ComptPill} from '@compt/common/forms/compt-pill/compt-pill';

export class ReviewBEReportsTableController {
  static getColumnDefinition(onActionClicked: (expenseReport: BusinessExpenseReport) => void) {
    const columnDefinition = {
      employee_name: {
        order: 1,
        id: 'employee_name',
        name: 'Employee name',
        selector: (expenseReport: BusinessExpenseReport) => <p>{expenseReport.full_name}</p>,
        grow: 0.5,
        width: '200px',
      },
      report_id: {
        order: 2,
        id: 'report_id',
        name: 'Report #',
        selector: (expenseReport: BusinessExpenseReport) => (
          <p data-tag="allowRowEvents">{`${REPORT_ID_PREFIX}-${expenseReport.id}`}</p>
        ),
        grow: 0.5,
        width: '100px',
      },
      report_total: {
        order: 3,
        id: 'report_total',
        name: 'Report total',
        selector: (expenseReport: BusinessExpenseReport) => (
          <p data-tag="allowRowEvents">
            {formatCurrencyFromCountryCode(expenseReport.total || 0, 'US')}
          </p>
        ),
        grow: 0.5,
        width: '150px',
      },
      date_submitted: {
        order: 4,
        id: 'date_submitted',
        name: 'Date submitted',
        selector: (expenseReport: BusinessExpenseReport) => (
          <p data-tag="allowRowEvents">
            {expenseReport.submitted_date
              ? formattedDate(expenseReport.submitted_date, DATE_FORMAT_OPTION['month dd yyyy'])
              : ''}
          </p>
        ),
        grow: 0.5,
        width: '200px',
      },
      title: {
        order: 5,
        id: 'title',
        name: 'Title',
        selector: (expenseReport: BusinessExpenseReport) => (
          <p data-tag="allowRowEvents">{expenseReport.title}</p>
        ),
        grow: 0.5,
        wdith: '300px',
      },
      summary: {
        order: 6,
        id: 'summary',
        name: 'Report summary',
        selector: (expenseReport: BusinessExpenseReport) => (
          <p data-tag="allowRowEvents">{expenseReport.description}</p>
        ),
        format: (expenseReport: BusinessExpenseReport) => {
          if (expenseReport.description.length < 50) {
            return expenseReport.description;
          }

          return `${expenseReport.description.slice(0, 50)}...`;
        },
        grow: 1,
      },
      status: {
        order: 7,
        id: 'status',
        name: 'Status',
        selector: (expenseReport: BusinessExpenseReport) => {
          const statusFormat = expenseReportStatusFormats[expenseReport.status];

          return (
            <ComptPill pillType={statusFormat.statusStyle} data-tag="allowRowEvents">
              {statusFormat.statusName}
            </ComptPill>
          );
        },
        grow: 0.75,
        width: '200px',
      },
      action: {
        order: 8,
        id: 'action',
        ignoreRowClick: true,
        compact: true,
        selector: (expenseReport: BusinessExpenseReport) => (
          <p
            className="text-color-link cursor-pointer"
            onClick={() => onActionClicked(expenseReport)}
          >
            View
          </p>
        ),
        grow: 0,
        disableRemoval: true,
      },
    };

    return columnDefinition;
  }
}

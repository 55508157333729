import React, {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useContext,
  useState,
} from 'react';
import {generateLocalStorageKey, useCacheableObjectState} from '@compt/utils/local-storage-helper';

interface FilterContextProps {
  filters: Record<string, string[] | string>;
  setFilters: Dispatch<SetStateAction<Record<string, string[] | string>>>;
  showRefreshButton: boolean;
  setShowRefreshButton: Dispatch<SetStateAction<boolean>>;
}

const FilterContext = createContext<FilterContextProps>({
  filters: {},
  setFilters: () => null,
  showRefreshButton: false,
  setShowRefreshButton: () => null,
});

interface FilterContextProviderProps {
  filterId: string;
  initialValues: Record<string, string[] | string>;
  children: ReactNode;
}

export const FilterContextProvider: React.FC<FilterContextProviderProps> = (props) => {
  const [filters, setFilters] = useCacheableObjectState<Record<string, string[] | string>>(
    generateLocalStorageKey(props.filterId, 'filter-values'),
    props.initialValues,
  );
  const [showRefreshButton, setShowRefreshButton] = useState(false);

  return (
    <FilterContext.Provider value={{filters, setFilters, showRefreshButton, setShowRefreshButton}}>
      {props.children}
    </FilterContext.Provider>
  );
};

export const useFilterContext = (): FilterContextProps => {
  const context = useContext(FilterContext);
  if (!context) {
    throw new Error('useFilterContext must be used within a FilterContextProvider');
  }
  return context;
};

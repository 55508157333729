import React, {Dispatch, SetStateAction} from 'react';

// Hooks and methods
import {DATE_FORMAT_OPTION, formattedDate} from '@compt/utils/date-helpers';
import {FeatureFlags, featureEnabled} from '@compt/utils/feature-flags-helper';
import {formatCurrencyFromCountryCode} from '@compt/utils/international-helpers';
import {selectionOptionValuesChangeHandler} from '@compt/common/compt-filter-bar/compt-filter-bar.utils';

// Types
import {BusinessExpenseReport} from '@compt/types/business-expenses/business-expense-report';
import {
  FilterValues,
  FormattedFilterObject,
} from '@compt/common/compt-filter-bar/compt-filter-bar.types';
import {MAX_BUSINESS_EXPENSE_REPORTS_PER_PAGE, REPORT_ID_PREFIX} from '@compt/constants';
import {
  BusinessExpenseReportStatus,
  expenseReportStatusFormats,
} from '@compt/types/business-expenses/business-expense-report-statuses';

// Components
import {ComptPill} from '@compt/common/forms/compt-pill/compt-pill';
import {SearchFilter} from '@compt/common/compt-filter-bar/compt-search-filter';
import {Pagination} from '@compt/common/compt-table/compt-table.types';
import {SelectFilter} from '@compt/common/compt-filter-bar/compt-select-filter';
import {SimpleAction, SimpleActionMenu} from '@compt/common/compt-table/simple-action-menu';

export class BusinessExpenseReportsTableController {
  getFilterConfiguration() {
    const statusOptions = Object.keys(BusinessExpenseReportStatus)
      .filter((key) => key !== BusinessExpenseReportStatus.REVIEWED_BY_MANAGER) // Hide reviewed by manager filter from employees
      .map((key) => {
        const id = BusinessExpenseReportStatus[key as keyof typeof BusinessExpenseReportStatus];
        const name = expenseReportStatusFormats[id].statusName;
        return {id, name};
      });

    const filterConfiguration = {
      textSearch: {
        filterType: SearchFilter,
        label: 'Business Expense Reports Table Search',
        options: [
          {
            id: 1,
            name: 'Business Expense Reports Table Search',
            placeholder: 'Search by title',
          },
        ],
        nonCollapsible: true,
      },
      status: {
        filterType: SelectFilter,
        label: 'Filter',
        options: statusOptions,
        valuesChangeHandler: selectionOptionValuesChangeHandler,
        getKey: (object: unknown) => object,
      },
    };

    return filterConfiguration;
  }

  static getInitialFilterValues() {
    const initialFilterValues = {
      textSearch: [''],
      status: [],
    };

    return initialFilterValues;
  }

  updateQueryParamsOnValuesChanged(
    companyId: number | undefined,
    setReportsQueryValues: Dispatch<SetStateAction<FormattedFilterObject | undefined>>,
    setFiltersApplied: Dispatch<SetStateAction<boolean>>,
    pagination?: Pagination | null,
    filterValues?: FilterValues,
    ordering?: string | null,
  ) {
    if (!companyId) {
      return;
    }

    const formattedFilter = formatFilterValuesOptions(filterValues, pagination, ordering);

    setReportsQueryValues(formattedFilter);
    if (
      Array.isArray(filterValues?.status) &&
      filterValues?.status.length &&
      filterValues.status.length > 0
    ) {
      setFiltersApplied(true);
    } else if (
      filterValues?.textSearch &&
      filterValues?.textSearch?.length > 0 &&
      filterValues?.textSearch[0].length > 0
    ) {
      setFiltersApplied(true);
    } else {
      setFiltersApplied(false);
    }
  }

  getColumnDefinition(
    onActionMenuClicked: (
      expense: BusinessExpenseReport,
      action: 'EDIT' | 'VIEW' | 'DELETE',
    ) => void,
  ) {
    const columnDefinition = {
      report_id: {
        id: 'report_id',
        name: 'Report number',
        selector: (expenseReport: BusinessExpenseReport) => (
          <p data-tag="allowRowEvents">{`${REPORT_ID_PREFIX}-${expenseReport.id}`}</p>
        ),
        grow: 0.5,
        order: 1,
      },
      title: {
        id: 'title',
        name: 'Title',
        selector: (expenseReport: BusinessExpenseReport) => (
          <p data-tag="allowRowEvents">{expenseReport.title}</p>
        ),
        grow: 0.5,
        order: 2,
      },
      report_total: {
        id: 'report_total',
        name: 'Report total',
        selector: (expenseReport: BusinessExpenseReport) => (
          <p data-tag="allowRowEvents">
            {formatCurrencyFromCountryCode(expenseReport.total || 0, 'US')}
          </p>
        ),
        grow: 0.5,
        order: 3,
      },
      status: {
        id: 'status',
        name: 'Status',
        selector: (expenseReport: BusinessExpenseReport) => {
          // Hide reviewed by manager status from employees
          const reportStatus =
            expenseReport.status === BusinessExpenseReportStatus.REVIEWED_BY_MANAGER
              ? BusinessExpenseReportStatus.SUBMITTED
              : expenseReport.status;
          const statusFormat = expenseReportStatusFormats[reportStatus];

          return (
            <ComptPill pillType={statusFormat.statusStyle} data-tag="allowRowEvents">
              {statusFormat.statusName}
            </ComptPill>
          );
        },
        grow: 0.75,
        order: 4,
      },
      date_submitted: {
        id: 'date_submitted',
        name: 'Date submitted',
        selector: (expenseReport: BusinessExpenseReport) => (
          <p data-tag="allowRowEvents">
            {expenseReport.submitted_date
              ? formattedDate(expenseReport.submitted_date, DATE_FORMAT_OPTION['month dd yyyy'])
              : ''}
          </p>
        ),
        grow: 0.5,
        order: 5,
      },
      summary: {
        id: 'summary',
        name: 'Report summary',
        selector: (expenseReport: BusinessExpenseReport) => (
          <p data-tag="allowRowEvents">{expenseReport.description}</p>
        ),
        format: (expenseReport: BusinessExpenseReport) => {
          if (expenseReport.description.length < 50) {
            return expenseReport.description;
          }

          return `${expenseReport.description.slice(0, 50)}...`;
        },
        grow: 1,
        order: 6,
        omit: featureEnabled(FeatureFlags.BUSINESS_EXPENSE_OMIT_SUMMARY_COLUMN),
      },
      action: {
        id: 'action',
        ignoreRowClick: true,
        compact: true,
        selector: (expenseReport: BusinessExpenseReport) =>
          this.renderActionSelector(expenseReport, onActionMenuClicked),
        grow: 0,
        order: 7,
      },
    };

    return columnDefinition;
  }

  renderActionSelector(
    report: BusinessExpenseReport,
    onActionMenuClicked: (
      report: BusinessExpenseReport,
      action: 'EDIT' | 'VIEW' | 'DELETE',
    ) => void,
  ) {
    const _onMenuClicked = (report: BusinessExpenseReport, action: 'VIEW' | 'EDIT' | 'DELETE') => {
      onActionMenuClicked(report, action);
    };
    const actions: Array<SimpleAction<BusinessExpenseReport>> = [
      {
        label: 'View',
        onClick: () => _onMenuClicked(report, 'VIEW'),
      },
    ];

    if (report.status === BusinessExpenseReportStatus.DRAFT) {
      actions.push({
        label: 'Edit',
        onClick: () => _onMenuClicked(report, 'EDIT'),
      });
      actions.push({
        label: 'Delete',
        onClick: () => _onMenuClicked(report, 'DELETE'),
        textColor: 'text-red-600',
      });
    }

    return <SimpleActionMenu relatedActionItem={report} actions={actions} />;
  }
}

const formatFilterValuesOptions = (
  filterValues?: FilterValues,
  pagination?: Pagination | null,
  ordering?: string | null,
) => {
  const formattedFilter: FormattedFilterObject = {};

  if (
    Array.isArray(filterValues?.status) &&
    filterValues?.status.length &&
    filterValues.status.length > 0
  ) {
    formattedFilter['status__in'] = filterValues?.status.map((status) => status.id).join(',');
  }

  if (filterValues?.textSearch && filterValues?.textSearch?.length > 0) {
    [formattedFilter['search']] = filterValues.textSearch;
  }

  formattedFilter.limit = MAX_BUSINESS_EXPENSE_REPORTS_PER_PAGE;

  if (pagination) {
    formattedFilter.offset =
      MAX_BUSINESS_EXPENSE_REPORTS_PER_PAGE * Math.max(pagination.page - 1, 0);
    formattedFilter.page = pagination.page;
  } else {
    formattedFilter.page = 1;
    formattedFilter.offset = 0;
  }

  if (ordering) {
    formattedFilter.ordering = ordering;
  }

  return formattedFilter;
};

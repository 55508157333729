import React, {useState} from 'react';

// Hooks and methods
import {
  Control,
  Controller,
  ControllerRenderProps,
  FieldPathByValue,
  FieldValues,
  UseFormClearErrors,
  UseFormGetValues,
  UseFormSetError,
} from 'react-hook-form';
import {useConfirmationModal} from '@compt/utils/confirmation-modal-helper';
import {twMerge} from 'tailwind-merge';

// Types
import {ComptFormControlFieldBaseProps} from '@compt/types/form/compt-forms';
import {ComptTagInputType} from '@compt/utils/compt-tag-input-helper';
import {AdditionalEmail} from '@compt/types/account';
import {ModalType} from '@compt/common/compt-confirmation-modal/compt-confirmation.modal';

// Components
import {ComptFormField} from '@compt/common/forms/compt-form-field/compt-form-field';
import {
  ComptButton,
  ComptButtonIcon,
  ComptButtonType,
} from '@compt/common/compt-button/compt-button';

export interface ComptTagInputProps<
  TFieldValues extends FieldValues,
  TName extends FieldPathByValue<TFieldValues, string>,
> extends ComptFormControlFieldBaseProps<string, TFieldValues, TName> {
  'data-testid'?: string;
  name: TName;
  label?: string;
  helperText?: string;
  errors: FieldValues | undefined;
  setError: UseFormSetError<TFieldValues>;
  clearError: UseFormClearErrors<TFieldValues>;
  getValues: UseFormGetValues<TFieldValues>;
  disabled?: boolean;
  control?: Control<TFieldValues>;
  tagType?: ComptTagInputType;
  headingText?: string;
  separator?: string;
  isEmailVerified?: boolean;
  maxTags?: number;
  onAddTag?: (tagValue: string) => void;
  onResendVerification?: (email: string) => void;
  onRemoveAdditionalEmail?: (email: string) => void;
  additionalEmails?: Array<AdditionalEmail>;
  emailValidationRegex?: RegExp;
}

const EmailRow = ({
  email,
  isVerified = false,
  onResend,
  onRemove,
  disabled = false,
}: {
  email: string;
  isVerified?: boolean;
  onResend?: (email: string) => void;
  onRemove?: () => void;
  disabled?: boolean;
}) => (
  <tr className="border-b border-gray-200">
    <td className="py-3">
      <div>
        <span>
          {email} {!isVerified && '(Unverified)'}
        </span>
        {!isVerified && (
          <div className="flex text-sm">
            <p className="body3 text-color-body1">Verification link expires after 24 hours.</p>
            {onResend && (
              <button type="button" onClick={() => onResend(email)}>
                <p className="body3 text-[#3B7E95] ml-1">Resend</p>
              </button>
            )}
          </div>
        )}
      </div>
    </td>
    <td className="py-3 text-right">
      {!disabled && onRemove && (
        <button type="button" onClick={onRemove} aria-label={`Remove email ${email}`}>
          <p className="body-2 text-color-error">Remove</p>
        </button>
      )}
    </td>
  </tr>
);

export const ComptEmailTagInput = <
  TFieldValues extends FieldValues,
  TName extends FieldPathByValue<TFieldValues, string>,
>(
  props: ComptTagInputProps<TFieldValues, TName>,
) => {
  const [currentInput, setCurrentInput] = useState<string>('');
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [emailToRemove, setEmailToRemove] = useState<{tag: string; field: any} | null>(null);

  const {modal: confirmDeleteModal, showModal: showDeleteModal} = useConfirmationModal(
    `Are you sure you want to remove ${emailToRemove?.tag}?`,
    'Confirm',
    ModalType.DESTRUCTIVE,
    'Remove',
    'Cancel',
  );

  const tagType = ComptTagInputType.EMAIL;
  const headingText = props.headingText || `Verified ${tagType}s`;
  const maxTags = props.maxTags || 3;

  const isValidEmail = (email: string): boolean =>
    props.emailValidationRegex ? props.emailValidationRegex?.test(email) : false;

  const handleAddTag = (field: ControllerRenderProps<TFieldValues, TName>) => {
    const currentTags = props.getValues(props.name) || [];
    const trimmedInput = currentInput.trim();

    if (!trimmedInput) {
      props.setError(props.name, {
        type: 'manual',
        message: `Please enter an ${tagType}.`,
      });
      return;
    }

    // Check if we've reached the maximum allowed tags
    if (maxTags && currentTags.length >= maxTags) {
      props.setError(props.name, {
        type: 'manual',
        message: `Maximum of ${maxTags} ${tagType}s allowed.`,
      });
      return;
    }

    if (!isValidEmail(trimmedInput)) {
      props.setError(props.name, {
        type: 'manual',
        message: 'Please enter a valid email address.',
      });
      return;
    }

    const newTags = [trimmedInput];
    const duplicateTags: string[] = [];

    const validTags = newTags.filter((tag) => {
      if (!tag) {
        props.setError(props.name, {
          type: 'manual',
          message: `Empty ${tagType}s are not allowed.`,
        });
        return false;
      }

      // Check for duplicates in current tags
      if (currentTags.some((existing: string) => existing.toLowerCase() === tag.toLowerCase())) {
        duplicateTags.push(tag);
        return false;
      }

      // Check for duplicates in additional emails
      if (
        props.additionalEmails?.some(
          (additionalEmail) => additionalEmail.email.toLowerCase() === tag.toLowerCase(),
        )
      ) {
        duplicateTags.push(tag);
        return false;
      }
      return true;
    });

    if (duplicateTags.length > 0) {
      props.setError(props.name, {
        type: 'manual',
        message: `Duplicate ${
          duplicateTags.length > 1 ? `${tagType}s` : tagType
        }: "${duplicateTags.join('", "')}"`,
      });
    }

    if (validTags.length > 0) {
      const updatedTags = [...currentTags, ...validTags];
      field.onChange(updatedTags);
      setCurrentInput('');
      props.clearError(props.name);
      if (props.onAddTag) {
        validTags.forEach((tag) => {
          props.onAddTag?.(tag);
        });
      }
    }
  };

  const confirmDeleteTag = async (
    tagToRemove: string,
    field: ControllerRenderProps<TFieldValues, TName>,
  ) => {
    setEmailToRemove({tag: tagToRemove, field});
    const confirmed = await showDeleteModal();
    if (confirmed) {
      handleRemoveTag(tagToRemove, field);
    }
    setEmailToRemove(null);
  };

  const handleRemoveTag = (
    tagToRemove: string,
    field: ControllerRenderProps<TFieldValues, TName>,
  ) => {
    // For form-controlled emails
    const currentTags = props.getValues(props.name) || [];
    const updatedTags = currentTags.filter(
      (tag: string) => tag.toLowerCase() !== tagToRemove.toLowerCase(),
    );
    field.onChange(updatedTags);

    // For existing emails from props - notify parent component
    if (
      props.additionalEmails?.some((e) => e.email === tagToRemove) &&
      props.onRemoveAdditionalEmail
    ) {
      props.onRemoveAdditionalEmail(tagToRemove);
    }

    props.clearError(props.name);
  };

  const handleInputBlur = () => {
    if (currentInput.trim() === '') {
      props.clearError(props.name);
    }
  };

  const currentTagCount =
    (props.getValues(props.name)?.length || 0) + (props.additionalEmails?.length || 0);
  const isMaxTagsReached = maxTags !== undefined && currentTagCount >= maxTags;

  return (
    <Controller
      name={props.name}
      control={props.control}
      rules={props.validation}
      render={({field}) => (
        <>
          {confirmDeleteModal}
          <ComptFormField
            id={props.name as string}
            field={
              <div>
                <div className="flex">
                  <input
                    type={tagType === 'email' ? tagType : 'text'}
                    value={currentInput}
                    placeholder={props.placeholder || `Type a ${tagType} and press Enter`}
                    disabled={props.disabled || isMaxTagsReached}
                    onChange={(e) => setCurrentInput(e.target.value)}
                    onBlur={handleInputBlur}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter') {
                        e.preventDefault();
                        handleAddTag(field);
                      }
                    }}
                    className={twMerge(
                      `block w-full rounded-md border-0 py-2 px-3 shadow-sm ring-1
                       ring-inset focus:ring-2 body1 text-color-body2
                       placeholder:text-gray-400 sm:leading-6 focus:ring-2
                       disabled:bg-surface-disabled disabled:text-disabled-on-light
                      ${
                        props.errors
                          ? 'ring-stroke-critical focus:ring-stroke-critical'
                          : 'ring-stroke-tertiary focus:ring-stroke-focus'
                      }
                      ${
                        isMaxTagsReached
                          ? 'bg-surface-disabled text-disabled-on-light cursor-not-allowed'
                          : ''
                      }`,
                    )}
                  />
                  <ComptButton
                    buttonType={ComptButtonType.PRIMARY}
                    iconId={ComptButtonIcon.PLUS}
                    data-testid="add-additional-email-button"
                    className="min-w-[40px] rounded-full ml-2"
                    onClick={() => handleAddTag(field)}
                    disabled={props.disabled || isMaxTagsReached}
                  />
                </div>

                {isMaxTagsReached && (
                  <p className="text-sm text-amber-600 mt-1">
                    {`Maximum number (${maxTags}) of emails reached.`}
                  </p>
                )}

                {props.errors ? (
                  <p className="text-sm text-red-600 mt-1 mb-3">{props.errors.message}</p>
                ) : props.helperText ? (
                  <p className="body3 text-color-body2 mt-1 mb-3">{props.helperText}</p>
                ) : null}

                {((props.additionalEmails && props.additionalEmails?.length > 0) ||
                  props.getValues(props.name)?.length > 0) && (
                  <div className="mt-4">
                    <p className="body2 text-gray-700 font-semibold mb-2">{headingText}</p>

                    <div>
                      <p className="text-sm text-gray-600 mb-3">
                        Manage emails that you have verified below.
                      </p>
                      <table className="w-full border-collapse">
                        <tbody>
                          {props
                            .getValues(props.name)
                            ?.map((email: string, index: number) => (
                              <EmailRow
                                key={`${email}-${index}`}
                                email={email}
                                isVerified={false}
                                onResend={props.onResendVerification}
                                onRemove={() => confirmDeleteTag(email, field)}
                                disabled={props.disabled}
                              />
                            ))}
                          {props.additionalEmails?.map((emailObj) => (
                            <EmailRow
                              key={emailObj.email}
                              email={emailObj.email}
                              isVerified={emailObj.verified}
                              onResend={props.onResendVerification}
                              onRemove={() => confirmDeleteTag(emailObj.email, field)}
                              disabled={props.disabled}
                            />
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                )}
              </div>
            }
            label={props.label}
            subLabel={props.subLabel}
            validation={props.validation}
            data-testid={props['data-testid']}
          />
        </>
      )}
    />
  );
};

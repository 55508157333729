const currentEnv = import.meta.env.MODE;

// Used to change the base path for the API slice
export const COMPT_API_BASE_PATH =
  currentEnv === 'test' ? 'http://localhost:3001/api/1.0' : '/api/1.0/';

// Used to define the base path for tests
export const TEST_BASE_PATH = 'http://localhost:3001/api/1.0';

export const KEEP_UNUSED_DATA_TIME = 60;

export const COMPT_APP_ROOT_PATH = currentEnv === 'test' ? 'http://localhost:3001/' : '/';

export const COMPT_PD_FILE_DOMAINS = {
  supportingDocsDomain: 'learning_and_dev_supporting_document',
  receiptsDomain: 'learning_and_dev',
};

import {ReactNode, useState} from 'react';

// RTK queries
import {useGetSessionQuery} from '@compt/app/services/api/api-slice';
import {useReleaseUserMutation} from '@compt/app/services/api/delegate-slice';

// Types
import {PortalRoot} from '../compt-portal/compt-portal';

// Hooks and methods
import {triggerCustomToast} from '@compt/common/compt-toaster/compt-toaster';
import {useIsMobileView} from '@compt/utils/mobile-helpers';
import {SideNavContext} from '../compt-side-nav/side-nav-context';
import {ComptLayoutController} from './compt-layout.controller';

// Components
import {NoPermissionsPage} from '@compt/pages/no-permissions-page/no-permissions-page';
import {Transition} from '@headlessui/react';
import {ComptSideNav} from '../compt-side-nav/compt-side-nav';
import {ComptTopBar} from '../compt-top-bar/compt-top-bar';
import {ReceiptUploadContextProvider} from '../forms/compt-receipt-upload/receipt-upload-context';
import {HijackBanner} from './compt-hijack-banner';

export type ComptLayoutProps = {
  children: ReactNode;
  onboardingRequired: boolean;
};

export const ComptLayout = ({onboardingRequired, children}: ComptLayoutProps) => {
  const controller = new ComptLayoutController();
  const session = useGetSessionQuery();
  const userData = session.data;
  const isMobile = useIsMobileView();

  const [releaseUserMutation, releaseUserMutationStatus] = useReleaseUserMutation();

  const isAdminView = false;
  const defaultSidebarOpen = isMobile ? false : true;

  const [sidebarOpen, setSidebarOpen] = useState(defaultSidebarOpen);
  const [stipendAdminOpen, setStipendAdminOpen] = useState(false);
  const [expenseAdminOpen, setExpenseAdminOpen] = useState(false);
  const [internalAdminOpen, setInternalAdminOpen] = useState(false);
  const [learningAdminOpen, setLearningAdminOpen] = useState(false);

  const eligibleNavSections = controller.getEligibleNavSectionsAndItems(
    userData,
    isAdminView,
    isMobile,
  );

  function containerClass() {
    const classNameBase = 'layout-main-content overflow-y-auto bg-white w-full';

    if (onboardingRequired) {
      return `${classNameBase} h-screen flex justify-center items-center`;
    }

    return `${classNameBase}`;
  }

  if (!eligibleNavSections || eligibleNavSections.length === 0) {
    return (
      <>
        {!onboardingRequired && <ComptTopBar />}
        {!onboardingRequired && isMobile && (
          <div className="absolute z-10 bg-white sm:flex sm:relative h-screen">
            <ComptSideNav navSections={[]} />
          </div>
        )}
        <NoPermissionsPage
          heading="Looks like you're not eligible for any benefits through Compt"
          description={
            'We recommend reaching out to your HR team for any questions or help getting set up!'
          }
        />
      </>
    );
  }

  const handleReleaseHijack = () => {
    if (session.data?.is_hijacked) {
      releaseUserMutation().then((result) => {
        if ('error' in result) {
          console.error('Error releasing user', result);
          triggerCustomToast('error', 'There was a problem releasing user', 'Please try again.');
        } else {
          triggerCustomToast('success', 'Successfully released user');
          // We want a full page refresh when this happens so use native location instead of React Router.
          window.location.replace('/');
        }
      });
    }
  };

  return (
    <SideNavContext.Provider
      value={{
        isAdminView,
        sidebarOpen,
        setSidebarOpen,
        stipendAdminOpen,
        setStipendAdminOpen,
        expenseAdminOpen,
        setExpenseAdminOpen,
        internalAdminOpen,
        setInternalAdminOpen,
        learningAdminOpen,
        setLearningAdminOpen,
      }}
    >
      <ReceiptUploadContextProvider>
        <div className="flex flex-col h-screen">
          {session.data?.is_hijacked && userData && (
            <HijackBanner
              handleReleaseHijack={handleReleaseHijack}
              userData={userData}
              loading={releaseUserMutationStatus.isLoading || releaseUserMutationStatus.isSuccess}
            />
          )}

          {!onboardingRequired && <ComptTopBar />}
          <div className={`${onboardingRequired ? '' : 'flex flex-grow overflow-hidden'}`}>
            {!onboardingRequired && (
              <div className={'absolute sm:relative z-30 sm:z-20 sm:flex'}>
                <ComptSideNav navSections={eligibleNavSections} />
              </div>
            )}
            <div className={containerClass()}>
              {/* Apply background overlay when mobile sidebar is open */}
              {isMobile && (
                <Transition
                  show={isMobile && sidebarOpen}
                  as="div"
                  enter="transition-opacity ease-linear duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="transition-opacity ease-linear duration-300"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                  className="fixed z-20"
                >
                  <div className="fixed inset-0 z-20 bg-gray-900 bg-opacity-80 transition-opacity" />
                </Transition>
              )}
              {children}
            </div>
            {/* Hide filter sidebars in mobile view */}
            {!isMobile && <div id={PortalRoot.RIGHT_NAV} />}
          </div>
        </div>
      </ReceiptUploadContextProvider>
    </SideNavContext.Provider>
  );
};

import React, {ReactNode} from 'react';

// Hooks and methods
import {twMerge} from 'tailwind-merge';

// Components
import {NavLink} from 'react-router-dom';

interface NavLinkTabProps {
  to: string;
  children: ReactNode;
  className?: string;
}

export const NavLinkTab = (props: NavLinkTabProps) => (
  <NavLink
    to={props.to}
    className={({isActive}) =>
      twMerge(
        `py-2 text-lg font-medium ${
          isActive
            ? 'border-b-2 border-[var(--Icon-icon-link,#3B7E95)] text-primary'
            : 'text-gray-500'
        } ${props.className}`,
      )
    }
  >
    {props.children}
  </NavLink>
);

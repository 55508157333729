// Types
import {comptApiSlice, ComptRTKTags} from './api-slice';
import {
  EmployeePreApprovalRequest,
  EmployeePreApprovalRequestListResults,
  EmployeePreApprovalRequestPayload,
} from '@compt/types/learning-development/pre-approval-request';
import {EmployeeLearningDevelopmentBudgetListResults} from '@compt/types/learning-development/employee-learning-development-budget-eligibility';
import {
  EmployeeReimbursementRequestPayload,
  ReimbursementRequest,
} from '@compt/types/learning-development/reimbursement-request';
import {LearningRequestStatus} from '@compt/types/learning-development/learning-request-status';

const extendedComptApiSlice = comptApiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getActiveEmployeePreApprovalRequests: builder.query<
      EmployeePreApprovalRequestListResults,
      {companyId?: number; filter: {search?: string; offset: number; limit: number}}
    >({
      query: ({companyId, filter}) => ({
        url: `learning_and_development/${companyId}/me/pre_approval_requests`,
        params: {
          ...filter,
          ordering: '-created_on,id',
          exclude_completed: 'true',
          status__in: [
            LearningRequestStatus.PRE_APPROVAL_REQUESTED,
            LearningRequestStatus.PARTIALLY_APPROVED,
            LearningRequestStatus.PRE_APPROVED,
            LearningRequestStatus.REIMBURSEMENT_REQUESTED,
            LearningRequestStatus.REIMBURSEMENT_APPROVED,
          ],
        },
        method: 'GET',
      }),
      providesTags: [ComptRTKTags.EMPLOYEE_LEARNING_DEVELOPMENT_ACTIVE_REQUEST_LIST],
    }),
    getCompletedEmployeePreApprovalRequests: builder.query<
      EmployeePreApprovalRequestListResults,
      {companyId?: number; filter: {search?: string; offset: number; limit: number}}
    >({
      query: ({companyId, filter}) => ({
        url: `learning_and_development/${companyId}/me/pre_approval_requests`,
        params: {
          ...filter,
          ordering: '-created_on,id',
          only_completed: 'true',
        },
        method: 'GET',
      }),
      providesTags: [ComptRTKTags.EMPLOYEE_LEARNING_DEVELOPMENT_COMPLETED_REQUEST_LIST],
    }),
    getAllEmployeePreApprovalRequests: builder.query<
      EmployeePreApprovalRequestListResults,
      {
        companyId?: number;
        filter: {
          search?: string;
          offset: number;
          limit: number;
          status__in: string[];
          ordering: string;
        };
      }
    >({
      query: ({companyId, filter}) => ({
        url: `learning_and_development/${companyId}/me/pre_approval_requests`,
        params: filter,
        method: 'GET',
      }),
      providesTags: [ComptRTKTags.EMPLOYEE_LEARNING_DEVELOPMENT_PRE_APPROVAL_LIST],
    }),
    getEmployeePreApprovalRequest: builder.query<
      EmployeePreApprovalRequest,
      {companyId: number | undefined; requestId: string | null}
    >({
      query: ({companyId, requestId}) =>
        `learning_and_development/${companyId}/me/pre_approval_requests/${requestId}`,
      providesTags: [ComptRTKTags.EMPLOYEE_LEARNING_DEVELOPMENT_PRE_APPROVAL_LIST],
    }),
    getAllEmployeeProgramBudgets: builder.query<
      EmployeeLearningDevelopmentBudgetListResults,
      number
    >({
      query: (companyId) => `learning_and_development/${companyId}/me/program_budget_eligibilities`,
      providesTags: [ComptRTKTags.EMPLOYEE_LEARNING_DEVELOPMENT_PROGRAM_LIST],
    }),
    createEmployeePreApprovalRequest: builder.mutation<
      EmployeePreApprovalRequest,
      {
        body: EmployeePreApprovalRequestPayload;
        companyId: number;
      }
    >({
      query({body, companyId}) {
        return {
          url: `learning_and_development/${companyId}/me/pre_approval_requests/`,
          method: 'POST',
          body,
        };
      },
      invalidatesTags: [
        ComptRTKTags.EMPLOYEE_LEARNING_DEVELOPMENT_ACTIVE_REQUEST_LIST,
        ComptRTKTags.LEARNING_DEVELOPMENT_PRE_APPROVAL_REQUEST_LIST,
        ComptRTKTags.EMPLOYEE_LEARNING_DEVELOPMENT_PROGRAM_LIST,
      ],
    }),
    updateEmployeePreApprovalRequest: builder.mutation<
      EmployeePreApprovalRequest,
      {
        body: EmployeePreApprovalRequestPayload;
        companyId: number;
        requestId: number;
      }
    >({
      query({body, companyId, requestId}) {
        return {
          url: `learning_and_development/${companyId}/me/pre_approval_requests/${requestId}/`,
          method: 'PUT',
          body,
        };
      },
      invalidatesTags: [
        ComptRTKTags.EMPLOYEE_LEARNING_DEVELOPMENT_PRE_APPROVAL_LIST,
        ComptRTKTags.LEARNING_DEVELOPMENT_PRE_APPROVAL_REQUEST_LIST,
        ComptRTKTags.EMPLOYEE_LEARNING_DEVELOPMENT_PROGRAM_LIST,
      ],
    }),
    deleteEmployeePreApprovalRequest: builder.mutation<
      unknown,
      {companyId: number; requestId: number}
    >({
      query({companyId, requestId}) {
        return {
          url: `learning_and_development/${companyId}/me/pre_approval_requests/${requestId}/`,
          method: 'DELETE',
        };
      },
      invalidatesTags: [
        ComptRTKTags.EMPLOYEE_LEARNING_DEVELOPMENT_ACTIVE_REQUEST_LIST,
        ComptRTKTags.LEARNING_DEVELOPMENT_PRE_APPROVAL_REQUEST_LIST,
      ],
    }),
    createEmployeeReimbursementRequest: builder.mutation<
      ReimbursementRequest,
      {
        body: EmployeeReimbursementRequestPayload;
        companyId: number;
      }
    >({
      query({body, companyId}) {
        return {
          url: `learning_and_development/${companyId}/me/reimbursement_requests/`,
          method: 'POST',
          body,
        };
      },
      invalidatesTags: [
        ComptRTKTags.EMPLOYEE_LEARNING_DEVELOPMENT_PRE_APPROVAL_LIST,
        ComptRTKTags.LEARNING_DEVELOPMENT_PRE_APPROVAL_REQUEST_LIST,
      ],
    }),
  }),
});

export const {
  useGetActiveEmployeePreApprovalRequestsQuery,
  useGetCompletedEmployeePreApprovalRequestsQuery,
  useGetAllEmployeePreApprovalRequestsQuery,
  useGetEmployeePreApprovalRequestQuery,
  useGetAllEmployeeProgramBudgetsQuery,
  useCreateEmployeePreApprovalRequestMutation,
  useUpdateEmployeePreApprovalRequestMutation,
  useDeleteEmployeePreApprovalRequestMutation,
  useCreateEmployeeReimbursementRequestMutation,
} = extendedComptApiSlice;

import React, {useEffect, useMemo, useState} from 'react';

// RTK queries
import {
  useCreateEmployeeReimbursementRequestMutation,
  useDeleteEmployeePreApprovalRequestMutation,
  useUpdateEmployeePreApprovalRequestMutation,
} from '@compt/app/services/api/employee-learning-development-slice';

// Hooks and methods
import {twMerge} from 'tailwind-merge';
import {FieldValues, useForm} from 'react-hook-form';
import {useSearchParams} from 'react-router-dom';
import {formatCurrency} from '@compt/utils/international-helpers';
import {triggerCustomToast} from '../compt-toaster/compt-toaster';
import {DATE_FORMAT_OPTION, formattedDate, isExpiredDate} from '@compt/utils/date-helpers';
import {useConfirmationModal} from '@compt/utils/confirmation-modal-helper';
import {featureEnabled, FeatureFlags} from '@compt/utils/feature-flags-helper';
import {cleanSubmission} from '@compt/utils/learning-form-helpers';

// Types
import {DateString} from '@compt/types/common/date-string';
import {EmployeeReimbursementRequestPayload} from '@compt/types/learning-development/reimbursement-request';
import {
  EmployeePreApprovalRequest,
  getCustomFieldURLKey,
  PreApprovalRequest,
} from '@compt/types/learning-development/pre-approval-request';
import {LearningRequestStatus} from '@compt/types/learning-development/learning-request-status';
import {RequestType} from '@compt/types/learning-development/learning-development-program';
import {Company} from '@compt/types/company';
import {ExpenseStatus} from '@compt/types/stipend-expense';
import {ModalType} from '../compt-confirmation-modal/compt-confirmation.modal';
import {RequestDataFieldKeys} from '@compt/types/learning-development/learning-type-consts';
import {SupportingDocument} from '@compt/types/supporting-document';

// Components
import {ComptSvgIcon} from '../compt-svg-icon/compt-svg-icon';
import {
  RequestFormFieldValues,
  RequestFormSidePanel,
} from '@compt/pages/learning-page/components/request-form-side-panel/request-form-side-panel';
import {ReviewFormSidePanel} from '@compt/pages/learning-page/components/review-forms/review-form-side-panel';
import {ObjectWithSupportingDocument} from '@compt/utils/file-helpers';

interface ComptReimbursementCardProps {
  request: EmployeePreApprovalRequest;
  company?: Company;
  currency?: string;
  onUpdateRequest?: (updatedRequest: PreApprovalRequest) => void;
}

interface StepType {
  label: string;
  key: LearningRequestStatus;
  date: string;
}

// TODO(COMPT-5758) eventually this should not be the default.
// Professional Development
const DEFAULT_CATEGORY_ID = 4;

export const getReimbursementStatus = (
  request: PreApprovalRequest,
): LearningRequestStatus | null => {
  if (!request?.expense) {
    return null;
  }

  if ([ExpenseStatus.Open, ExpenseStatus.Approved].includes(request.expense.status)) {
    return LearningRequestStatus.REIMBURSEMENT_REQUESTED;
  }

  if (request.expense.status === ExpenseStatus.Approved) {
    return LearningRequestStatus.REIMBURSEMENT_APPROVED;
  }

  if (request.expense.status === ExpenseStatus.Processed) {
    return LearningRequestStatus.REIMBURSED;
  }

  if (request.expense.status === ExpenseStatus.Rejected) {
    return LearningRequestStatus.REIMBURSEMENT_REJECTED;
  }

  return null;
};

export const ComptReimbursementCard = (props: ComptReimbursementCardProps) => {
  const {request, company, onUpdateRequest, currency} = props;
  const [openRequestPanel, setOpenRequestPanel] = useState(false);
  const [openReimbursementRequestPanel, setOpenReimbursementRequestPanel] = useState(false);
  const [openReviewPanel, setOpenReviewPanel] = useState(false);

  const formMethods = useForm<RequestFormFieldValues>();
  const [, setSearchParams] = useSearchParams();

  const [updatePreApprovalRequest, {isLoading}] = useUpdateEmployeePreApprovalRequestMutation();
  const [createReimbursementRequest, createReimbursementRequestStatus] =
    useCreateEmployeeReimbursementRequestMutation();
  const [deletePreApprovalRequest] = useDeleteEmployeePreApprovalRequestMutation();

  const customReimbursementFields = request?.program?.custom_expense_fields ?? [];
  // TO-DO: Remove in COMPT-6183 when expenses are divorced from Professional Development
  const defaultReimbursementFields = request.program?.reimbursement_configuration
    ? [...request.program.reimbursement_configuration.request_fields].sort(
        (a, b) => a.sequence - b.sequence,
      )
    : [];

  const {modal: confirmDeleteModal, showModal: showDeleteModal} = useConfirmationModal(
    // eslint-disable-next-line max-len
    'Are you sure you want to delete this pre-approval request? This action cannot be undone.',
    'Confirm request deletion',
    ModalType.DESTRUCTIVE,
    'Delete',
  );

  useEffect(() => {
    if (!request) return;

    const initialFormState: ObjectWithSupportingDocument & FieldValues = {
      receipt_key: request.request_data['Upload document(s)'],
      description: request.request_data['Description and reason'],
      vendor: request.request_data['Vendor name'],
      amount: request.request_data.Amount,
      expected_completion_date: request.request_data['Expected completion date'],
    };

    if (request.request_data[RequestDataFieldKeys.SupportingDocuments]) {
      request.request_data[RequestDataFieldKeys.SupportingDocuments].forEach((value, index) => {
        initialFormState[`supporting_doc_${index}`] = value;
      });
    }

    formMethods.reset(initialFormState);
  }, [request]);

  const getStepStatuses = (requestStatus: LearningRequestStatus) => {
    const steps: StepType[] = [
      {
        label: 'Approved',
        key: LearningRequestStatus.PRE_APPROVED,
        date: formattedDate(request?.reviewed_on, DATE_FORMAT_OPTION['month dd yyyy']),
      },
      {
        label: 'Submitted',
        key: LearningRequestStatus.REIMBURSEMENT_REQUESTED,
        date: formattedDate(request?.expense?.created_on, DATE_FORMAT_OPTION['month dd yyyy']),
      },
    ];

    steps.unshift({
      label: 'Requested',
      key:
        requestStatus === LearningRequestStatus.PARTIALLY_APPROVED
          ? requestStatus
          : LearningRequestStatus.PRE_APPROVAL_REQUESTED,
      date: formattedDate(request?.requested_on, DATE_FORMAT_OPTION['month dd yyyy']),
    });

    if (requestStatus === LearningRequestStatus.PRE_APPROVAL_REJECTED) {
      steps.push({
        label: 'Rejected',
        key: LearningRequestStatus.PRE_APPROVAL_REJECTED,
        date: formattedDate(request.reviewed_on, DATE_FORMAT_OPTION['month dd yyyy']),
      });
    } else if (requestStatus === LearningRequestStatus.REIMBURSEMENT_REJECTED) {
      steps.push({
        label: 'Rejected',
        key: LearningRequestStatus.REIMBURSEMENT_REJECTED,
        date: formattedDate(
          request.reimbursement_request?.reviewed_on,
          DATE_FORMAT_OPTION['month dd yyyy'],
        ),
      });
    } else {
      steps.push({
        label: 'Reimbursed',
        key: LearningRequestStatus.REIMBURSED,
        date: formattedDate(
          props?.request?.expense?.closed_on,
          DATE_FORMAT_OPTION['month dd yyyy'],
        ),
      });
    }

    return steps.map((step, index) => {
      const isComplete =
        (requestStatus === LearningRequestStatus.PRE_APPROVED && index <= 1) ||
        (reimbursementStatus === LearningRequestStatus.REIMBURSEMENT_REQUESTED && index <= 2) ||
        reimbursementStatus === LearningRequestStatus.REIMBURSED ||
        requestStatus === step.key;

      return {
        ...step,
        isComplete,
      };
    });
  };

  function onUpdatePreApprovalSubmit(form: RequestFormFieldValues) {
    if (!company?.id || !request || !form['Expected completion date']) {
      triggerCustomToast('error', 'An error occurred updating a pre-approval request');
      return;
    }

    if (!form['Expected completion date']) {
      console.error('Expected completion date is missing.');
      triggerCustomToast('error', 'An error occurred updating a pre-approval request');
      return;
    }

    updatePreApprovalRequest({
      body: cleanSubmission(request.program_id, form, LearningRequestStatus.PRE_APPROVAL_REQUESTED),
      companyId: company.id,
      requestId: request.id,
    }).then((results) => {
      if ('error' in results) {
        console.error('Error updating pre-approval request', results);
        triggerCustomToast('error', 'An error occurred updating a pre-approval request');
        return;
      }

      if (onUpdateRequest) {
        onUpdateRequest(results.data);
      }

      triggerCustomToast('success', 'Successfully updated a pre-approval request!');
      setOpenRequestPanel(false);
      formMethods.reset();
      formMethods.reset({'Expected completion date': undefined});
    });
  }

  function onCreateReimbursementRequestSubmit(form: RequestFormFieldValues) {
    if (!company?.id || !request || !form['Expected completion date']) {
      triggerCustomToast('error', 'An error occurred submitting reimbursement request');
      return;
    }

    const requestData = {
      'Vendor name': form['Vendor name'],
      Amount: form['Amount'],
      'Receipt date': form['Expected completion date'],
      'Description and reason': form['Description and reason'],
      'Upload document(s)': form.receipt_key,
      Category: DEFAULT_CATEGORY_ID,
      ...(customReimbursementFields.length > 0
        ? customReimbursementFields.reduce(
            (acc, field) => ({
              ...acc,
              [field.field_name]: form[field.field_name],
            }),
            {},
          )
        : {}),
    };

    const submission: EmployeeReimbursementRequestPayload = {
      program_id: request.program_id,
      pre_approval_request_id: request.id,
      status: 'PENDING_APPROVAL', // Non-LearningRequestStatus required to send for expense object
      request_data: {
        ...requestData,
      },
    };

    createReimbursementRequest({
      body: submission,
      companyId: company.id,
    }).then((results) => {
      if ('error' in results) {
        triggerCustomToast('error', 'An error occurred submitting reimbursement request');
        return;
      }

      if (onUpdateRequest) {
        const newRequest: PreApprovalRequest = {
          ...request,
          reimbursement_request: results.data,
          expense: results.data?.expense,
        };

        onUpdateRequest(newRequest);
      }

      setOpenReimbursementRequestPanel(false);
      formMethods.reset();
      formMethods.reset({'Expected completion date': undefined});

      if (featureEnabled(FeatureFlags.COMMUNITY_FEED)) {
        setOpenReviewPanel(true);
      }
    });
  }

  async function onRequestDelete() {
    if (!company?.id || !request) {
      triggerCustomToast('error', 'An error occurred deleting pre-approval request');
      return;
    }

    if (request.status !== LearningRequestStatus.PRE_APPROVAL_REQUESTED) {
      triggerCustomToast(
        'error',
        'This pre-approval request is already in review and unable to be deleted',
      );
      return;
    }

    const confirmDelete = await showDeleteModal();
    if (!confirmDelete) return;

    deletePreApprovalRequest({companyId: company.id, requestId: request.id}).then((results) => {
      if ('error' in results) {
        triggerCustomToast('error', 'An error occurred deleting pre-approval request');
        return;
      }

      triggerCustomToast('success', 'Successfully deleted your pre-approval request');
      setOpenRequestPanel(false);
    });
  }

  const {isExpired, targetDate} = isExpiredDate(request.allotment?.expiration_date);

  const renderReimbursementButton = () => {
    if (request.status === LearningRequestStatus.PRE_APPROVED) {
      if (!request.expense && !isExpired) {
        return (
          <button
            className="label3 mr-4 text-color-link"
            onClick={() => setOpenReimbursementRequestPanel(true)}
          >
            Request reimbursement
          </button>
        );
      }

      if (props?.request?.expense?.status === ExpenseStatus.Open) {
        // TODO(COMPT-5757) - Need to implement support for updates
        // return (
        //   <button
        //     className="label3 mr-4 text-color-link"
        //     onClick={() => setOpenReimbursementRequestPanel(true)}
        //   >
        //     Edit Claim
        //   </button>
        // );
      }
    }
    return null;
  };
  const reimbursementStatus = useMemo(() => getReimbursementStatus(request), [request]);
  const steps = useMemo(() => getStepStatuses(request.status), [request]);

  let preApprovalRequestSupportingDocumentsObjects: SupportingDocument[] = [];
  if (
    props.request?.request_data &&
    props.request?.request_data[getCustomFieldURLKey(RequestDataFieldKeys.SupportingDocuments)]
  ) {
    const documentsUrls =
      props.request.request_data[getCustomFieldURLKey(RequestDataFieldKeys.SupportingDocuments)];

    const documentsFileKeys = props.request.request_data[RequestDataFieldKeys.SupportingDocuments];

    if (Array.isArray(documentsUrls)) {
      preApprovalRequestSupportingDocumentsObjects = documentsUrls.map((url: string, index) => ({
        document_image: url,
        file_key: documentsFileKeys[index],
      }));
    }
  }

  return (
    <>
      {confirmDeleteModal}
      <ReviewFormSidePanel
        open={openReviewPanel}
        setOpen={setOpenReviewPanel}
        request={request}
        companyId={company?.industry}
      />
      <RequestFormSidePanel
        id="lnd-request-form"
        title="Request form"
        subtitle={request.program.name}
        open={openRequestPanel}
        requestType={RequestType.PRE_APPROVAL}
        mutationLoading={isLoading}
        onSubmit={onUpdatePreApprovalSubmit}
        formMethods={formMethods}
        setOpen={setOpenRequestPanel}
        program={request.program}
        preApprovalRequest={request}
        onRequestDelete={onRequestDelete}
        programCurrency={currency}
        requestFields={request.pre_approval_configuration.request_fields}
        supportingDocumentsObjects={preApprovalRequestSupportingDocumentsObjects}
      />
      <RequestFormSidePanel
        id="lnd-request-form"
        title="Request reimbursement"
        subtitle={request.program.name}
        open={openReimbursementRequestPanel}
        requestType={RequestType.REIMBURSEMENT}
        mutationLoading={createReimbursementRequestStatus.isLoading}
        onSubmit={onCreateReimbursementRequestSubmit}
        formMethods={formMethods}
        setOpen={setOpenReimbursementRequestPanel}
        program={request.program}
        preApprovalRequest={request}
        programCurrency={currency}
        maxAmount={request.request_data.Amount}
        requestFields={[...defaultReimbursementFields, ...customReimbursementFields]}
      />
      <div className="flex flex-col rounded-3xl border border-stroke-divider-2 p-400">
        <div className="flex justify-between">
          <div className="flex">
            <p className="label2 text-color-heading mr-1">{request.request_data['Vendor name']}</p>
            <p className="label2 text-color-heading">• {request.program.name}</p>
          </div>
          <p className="label2 text-color-heading">
            {formatCurrency(
              request.expense?.amount_claimed ?? request.request_data.Amount,
              request.currency,
            )}
          </p>
        </div>

        <p className="body3 text-color-body1 mt-2">
          {request.request_data['Description and reason']}
        </p>

        <div className="flex justify-center w-full py-400">
          {steps.map((step, index) => (
            <Step
              key={index}
              status={reimbursementStatus || request.status}
              label={step.label}
              date={step.date}
              isComplete={step.isComplete}
              nextStepComplete={steps[index + 1]?.isComplete}
              isFirstStep={index === 0}
              isLastStep={index === steps.length - 1}
            />
          ))}
        </div>
        <div className="flex justify-between">
          <div className="flex cursor-pointer">
            {request.status === LearningRequestStatus.PRE_APPROVAL_REQUESTED && (
              <button
                className="label3 mr-4 text-color-link cursor-pointer"
                onClick={() => setOpenRequestPanel(true)}
              >
                Edit request
              </button>
            )}
            {renderReimbursementButton()}
            <button
              className="flex flex-row items-center cursor-pointer"
              onClick={() => setSearchParams({pre_approval_id: `${request.id}`})}
            >
              <p className="label3 text-color-body1 cursor-pointer">View details</p>
              <ComptSvgIcon
                iconName="chevron-right-icon-gray-700"
                svgProp={{width: '18px', height: '18px'}}
              />
            </button>
          </div>
          {request?.allotment &&
            (request.status === LearningRequestStatus.PRE_APPROVAL_REQUESTED ||
              request.status === LearningRequestStatus.PRE_APPROVED ||
              request.status === LearningRequestStatus.PARTIALLY_APPROVED) && (
              <div className="flex items-center">
                <ComptSvgIcon iconName="ellipse-circle-icon" />
                <p className="label4 text-gray-700 ml-2">
                  {`Expire${isExpired ? 'd' : 's'} on ${targetDate}`}
                </p>
              </div>
            )}
        </div>
      </div>
    </>
  );
};

interface StepProps {
  label: string;
  date?: DateString;
  isComplete: boolean;
  nextStepComplete: boolean;
  status: LearningRequestStatus;
  isFirstStep: boolean;
  isLastStep: boolean;
}

const Step = (props: StepProps) => {
  function getLineColor(status: LearningRequestStatus, isComplete: boolean) {
    if (
      status === LearningRequestStatus.REIMBURSEMENT_REJECTED ||
      status === LearningRequestStatus.PRE_APPROVAL_REJECTED
    )
      return 'bg-gray-400';
    if (status === LearningRequestStatus.REIMBURSED) return 'bg-green-500';
    if (isComplete) return 'bg-brand-700';
    return 'bg-gray-300';
  }

  function getLineAfterColor(status: LearningRequestStatus, nextStepComplete: boolean) {
    if (
      status === LearningRequestStatus.REIMBURSEMENT_REJECTED ||
      status === LearningRequestStatus.PRE_APPROVAL_REJECTED
    )
      return 'bg-gray-400';
    if (status === LearningRequestStatus.REIMBURSED) return 'bg-green-500';
    if (nextStepComplete) return 'bg-brand-700';
    return 'bg-gray-300';
  }

  function getDotColor(status: LearningRequestStatus, isComplete: boolean, isLastStep: boolean) {
    if (
      status === LearningRequestStatus.REIMBURSEMENT_REJECTED ||
      (status === LearningRequestStatus.PRE_APPROVAL_REJECTED && isLastStep)
    )
      return 'bg-stroke-critical';
    if (status === LearningRequestStatus.REIMBURSED) return 'bg-green-500';
    if (isComplete) return 'bg-brand-700';
    return 'bg-gray-300';
  }

  const lineBeforeColor = getLineColor(props.status, props.isComplete);
  const lineAfterColor = getLineAfterColor(props.status, props.nextStepComplete);

  const dotColor = getDotColor(props.status, props.isComplete, props.isLastStep);

  const Dot = ({className}: {className?: string}) => (
    <div className={twMerge(`w-6 h-3 rounded-full ${dotColor} ${className}`)} />
  );

  const Line = ({className}: {className?: string}) => (
    <div className={twMerge(`grow h-px w-full ${className}`)} />
  );

  const DotLabel = () => (
    <div className="flex flex-col items-center">
      <span className={'label4 text-color-body1 mt-2'}>{props.label}</span>
      {props.date && <span className="text-xs text-gray-500">{props.date}</span>}
    </div>
  );

  return (
    <div className="flex flex-col w-full">
      {props.isFirstStep && (
        <div className="w-full flex flex-col">
          <div className="flex w-full items-center">
            <div className="h-px w-full" />
            <Dot />
            <Line className={lineAfterColor} />
          </div>
          <DotLabel />
        </div>
      )}

      {!props.isFirstStep && !props.isLastStep && (
        <div className="w-full flex flex-col">
          <div className="flex w-full items-center">
            <Line className={lineBeforeColor} />
            <Dot />
            <Line className={lineAfterColor} />
          </div>
          <DotLabel />
        </div>
      )}

      {props.isLastStep && (
        <div className="w-full flex flex-col">
          <div className="flex w-full items-center">
            <Line className={lineAfterColor} />
            <Dot className="items-center" />
            <div className="h-px w-full" />
          </div>
          <DotLabel />
        </div>
      )}
    </div>
  );
};

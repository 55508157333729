import React, {useEffect, useState} from 'react';
import {ComptFormField} from '@compt/common/forms/compt-form-field/compt-form-field';
import {
  SupportedCountriesType,
  formatCurrencySymbolFromCountryCode,
} from '@compt/utils/international-helpers';
import {Controller, ControllerRenderProps, FieldPathByValue, FieldValues} from 'react-hook-form';
import {ComptFormControlFieldBaseProps} from '@compt/types/form/compt-forms';
import comptColors from '@compt/styles/compt-colors';
import {ComptTooltipPlacement} from '@compt/common/compt-tooltip/compt-tooltip.controller';

export interface ComptCurrencyFieldProps<
  TFieldValues extends FieldValues,
  TName extends FieldPathByValue<TFieldValues, number>,
> extends Omit<ComptFormControlFieldBaseProps<number, TFieldValues, TName>, 'initialValue'> {
  initialValue?: number | null;
  placeholder?: string;
  defaultCurrencyCode?: SupportedCountriesType;
  givenCurrency?: string;
  'data-testid'?: string;
  subLabel?: string;
  onChange?: () => void;
  readOnly?: boolean;
  tooltipMessage?: React.ReactNode;
  tooltipPlacement?: ComptTooltipPlacement;
}

export const ComptCurrencyField = <
  TFieldValues extends FieldValues,
  TName extends FieldPathByValue<TFieldValues, number>,
>(
  props: ComptCurrencyFieldProps<TFieldValues, TName>,
) => {
  const [selection, setSelection] = useState<SupportedCountriesType | undefined>();

  /**Automatically set selection to given currency if it's passed as a property */
  useEffect(() => {
    if (props.givenCurrency) {
      setSelection(props.givenCurrency as SupportedCountriesType);
    }
  }, [props.givenCurrency]);

  const handleOnBlur = (
    e: React.FocusEvent<HTMLInputElement>,
    field: ControllerRenderProps<TFieldValues, TName>,
  ) => {
    let {value} = e.target;
    if (!value) return;

    value = parseFloat(value).toFixed(2);

    props.onChange?.();
    field.onChange(value);
    field.onBlur();
  };

  const field = (
    <Controller
      name={props.name}
      control={props.control}
      rules={props.validation}
      render={({field}) => (
        <div
          className={`flex relative align-middle rounded-md shadow-sm
          items-center py-100 px-050 border focus-within:ring-2
         ${
           props.errors
             ? 'border-stroke-critical focus-within:ring-stroke-critical focus-within:border-0'
             : 'border-stroke-tertiary focus-within:ring-stroke-focus'
         }
       `}
          //  Needed since tailwind bg utility classses aren't applying
          style={{
            backgroundColor: props.disabled ? comptColors.gray['300'] : comptColors.gray['000'],
          }}
        >
          <span className="ml-2 text-color-tertiary sm:text-sm">
            {formatCurrencySymbolFromCountryCode(props.defaultCurrencyCode, selection)}
          </span>
          <input
            type="number"
            step="0.01"
            id={props.id || 'price'}
            placeholder="0.00"
            aria-describedby="price-currency"
            disabled={props.disabled}
            min="0.01"
            {...field}
            onWheel={(e) => e.currentTarget.blur()}
            onChange={(e) => {
              field.onChange(e.target.value);
              props.onChange?.();
            }}
            ref={
              (props.register && props.name ? props.register(props.name)?.ref : field.ref) ||
              field.ref
            }
            onBlur={(e) => handleOnBlur(e, field)}
            data-testid={props['data-testid']}
            onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
              e.key === 'Enter' && e.preventDefault();
            }}
            className={`border-0 w-full h-6 border-transparent bg-surface-background
          focus:border-transparent focus:ring-0 pl-1 body1 text-color-body2
          disabled:bg-surface-disabled disabled:text-disabled-on-light`}
            value={field.value || ''}
          />
        </div>
      )}
    />
  );

  return (
    <ComptFormField
      id={props.id}
      field={field}
      label={props.label}
      subLabel={props.subLabel}
      validation={props.validation}
      errors={props.errors}
      data-testid={props['data-testid']}
      readOnly={props.readOnly}
      tooltipMessage={props.tooltipMessage}
      tooltipPlacement={props.tooltipPlacement}
    />
  );
};

import React, {useEffect} from 'react';

interface useInfiniteScrollObserverProps {
  targetRef: React.RefObject<HTMLDivElement>;
  enabled: boolean;
  onIntersect: () => void;
}

/**
 * A custom React hook for observing infinite scrolling behavior.
 *
 * This hook triggers a callback function (`onIntersect`) whenever the target element intersects with the viewport.
 *
 * @example
 * ```tsx
 * import React, { useRef } from 'react';
 * import { useInfiniteScrollObserver } from './infinite-scroll-helper';
 *
 * const InfiniteScrollComponent = () => {
 *   const targetRef = useRef(null);
 *
 *   const handleIntersect = () => {
 *     console.log('Load more content');
 *   };
 *
 *   useInfiniteScrollObserver({
 *     targetRef,
 *     onIntersect: handleIntersect,
       enabled: isEnabled
 *   });
 *
 *   return (
 *     <div>
 *       <div>Content above</div>
 *       <div ref={targetRef} style={{ height: 50, backgroundColor: 'lightgray' }}>
 *         Observer Target
 *       </div>
 *     </div>
 *   );
 * };
 *
 * export default InfiniteScrollComponent;
 * ```
 */
export function useInfiniteScrollObserver({
  targetRef,
  onIntersect,
  enabled = true,
}: useInfiniteScrollObserverProps) {
  useEffect(() => {
    if (!enabled || !targetRef?.current) return;

    const observer = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting) {
        onIntersect();
      }
    });

    observer.observe(targetRef.current);

    return () => {
      if (targetRef?.current) {
        observer.unobserve(targetRef.current);
      }
    };
  }, [enabled, targetRef, onIntersect]);
}

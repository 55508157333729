import React, {useState} from 'react';

// Hooks and methods
import {twMerge} from 'tailwind-merge';
import {useFilterContext} from '../compt-filter-sidebar/filter-context';

// Types
import {ComptDropDownProps} from '../forms/compt-dropdown/compt-dropdown';

// Components
import {Float} from '@headlessui-float/react';
import {Listbox} from '@headlessui/react';
import {ComptSvgIcon} from '../compt-svg-icon/compt-svg-icon';

interface Props<TOptionType, TName = string> extends ComptDropDownProps<TOptionType, TName> {
  columnFilterKey: string;
  searchFilterKey: string;
  className?: string;
}

export const ColumnSelectSearchFilter = <TOptionType, TName = string>(
  props: Props<TOptionType, TName>,
) => {
  const {filters, setFilters} = useFilterContext();

  const initialOption = props.options.find(
    (option) => props.getKey(option) === filters[props.columnFilterKey],
  );

  const [searchValue, setSearchValue] = useState<string>(filters[props.searchFilterKey] as string);
  const [selectedColumn, setSelectedColumn] = useState<TOptionType>(initialOption as TOptionType);

  function handleSearch(search: string) {
    setSearchValue(search);
    setFilters((prevState) => ({
      ...prevState,
      [props.searchFilterKey]: search,
    }));
  }

  function handleColumnChange(selectedColumn: TOptionType) {
    setSelectedColumn(selectedColumn);
    setFilters((prevState) => ({
      ...prevState,
      [props.columnFilterKey]: props.getKey(selectedColumn) as string,
    }));
  }

  return (
    <div>
      <div className="flex relative rounded-md shadow-sm">
        <input
          className={twMerge(`block w-full rounded-md border-0 pl-200 text-gray-900
                    ring-1 ring-inset ring-stroke-tertiary placeholder:text-gray-400
                    sm:text-sm sm:leading-6 focus:ring-stroke-primary focus:ring-2
                    ${props.className}`)}
          type="text"
          placeholder=""
          onKeyUp={(e) => (e.key === 'Enter' ? handleSearch(e.currentTarget.value) : null)}
          value={searchValue}
          onBlur={(e) => handleSearch(e.target.value)}
          onChange={(e) => handleSearch(e.target.value)}
          autoComplete="off"
        />
        <span
          className={`z-10 absolute flex pr-4 right-0
        top-1/2 justify-end transform -translate-y-1/2`}
        >
          <Listbox value={selectedColumn} onChange={handleColumnChange}>
            <Float portal flip placement={'bottom-start'}>
              <Listbox.Button className="flex">
                <p className="body1 mr-2">{props.getDisplayText(selectedColumn)}</p>
                <ComptSvgIcon
                  iconName="chevron-down-icon"
                  svgProp={{width: '24px', height: '24px'}}
                />
              </Listbox.Button>
              <Listbox.Options
                className={`max-h-60 rounded-md z-10 absolute right
                 bg-surface-background py-1 min-w-full shadow-lg ring-1 ring-black 
                 ring-opacity-5 focus:outline-none sm:text-sm overflow-auto`}
              >
                {props.options &&
                  props.options.length > 0 &&
                  props.options.map((item) => (
                    <Listbox.Option
                      key={props.getKey(item)}
                      className={({active}) =>
                        twMerge(`
                          relative cursor-default select-none w-full py-2 pl-3 pr-9 
                          ${active ? ' bg-gray-200 cursor-pointer' : ''}
                          `)
                      }
                      value={item}
                    >
                      {props.getDisplayText(item)}
                    </Listbox.Option>
                  ))}
              </Listbox.Options>
            </Float>
          </Listbox>
        </span>
      </div>
    </div>
  );
};

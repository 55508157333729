import React, {Dispatch, Fragment, SetStateAction, useEffect} from 'react';

// Types
import {UseFormReturn} from 'react-hook-form';
import {DEFAULT_CHAR_FIELD_MAX_LENGTH} from '@compt/constants';
import {RejectionReasonForm} from '@compt/types/common/rejection-reason-form';

// Components
import {Dialog, Transition} from '@headlessui/react';
import {ComptSvgIcon} from '../compt-svg-icon/compt-svg-icon';
import {ComptButton, ComptButtonIcon, ComptButtonType} from '../compt-button/compt-button';
import {ComptTextAreaField} from '../forms/compt-text-area-field/compt-text-area-field';

export interface Props {
  isOpen: boolean;
  modalTitle: string;
  modalText: string;
  formMethods: UseFormReturn<RejectionReasonForm, undefined>;
  isUpdating: boolean;
  onSubmitRejection: (form: RejectionReasonForm) => void;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  'data-testid'?: string;
  buttonDataTestId?: string;
}

export const ComptRejectionModal = (props: Props) => {
  const {isOpen} = props;
  const watchedRejectionReason = props.formMethods.watch('rejection_reason');

  // Reset rejection form when it is closed
  useEffect(() => {
    if (isOpen) return;
    props.formMethods.resetField('rejection_reason');
  }, [isOpen]);

  return (
    <>
      <Transition.Root show={props.isOpen} as={Fragment} data-testid={props['data-testid']}>
        <Dialog as="div" className="relative z-50" onClose={() => {}}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 backdrop-blur-sm bg-opacity-75 bg-white transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div
              className={`
            flex min-h-full items-end justify-center p-6 text-center sm:items-center sm:p-0
          `}
            >
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel
                  className={`
                relative transform bg-white
                overflow-hidden rounded-xl
                backdrop:blur-sm px-4 pb-4 pt-5
                text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6 border-2
              `}
                >
                  <div className="compt-modal flex">
                    <div className="w-full">
                      <div className="flex justify-between mb-8 ">
                        <div className="pr-4">
                          <ComptSvgIcon iconName="red-circle-trash-icon" />
                        </div>
                        <div className="flex flex-col space-y-2">
                          <p className="body1">{props.modalTitle}</p>
                          <p className="body3 text-color-body2">{props.modalText}</p>
                        </div>
                        <button className="flex pl-2 h-0" onClick={() => props.setIsOpen(false)}>
                          <ComptSvgIcon iconName={ComptButtonIcon.X} />
                        </button>
                      </div>
                      <ComptTextAreaField
                        name="rejection_reason"
                        control={props.formMethods.control}
                        register={props.formMethods.register}
                        validation={{
                          required: 'Rejection reason is required',
                          maxLength: {
                            value: DEFAULT_CHAR_FIELD_MAX_LENGTH,
                            message: `Your rejection reason must be under
                             ${DEFAULT_CHAR_FIELD_MAX_LENGTH} characters.`,
                          },
                        }}
                        errors={props.formMethods.formState.errors.rejection_reason}
                        data-testid="rejection-reason-field"
                        maxLength={DEFAULT_CHAR_FIELD_MAX_LENGTH}
                        watchedValue={watchedRejectionReason}
                      />
                      <div className="flex justify-end">
                        <ComptButton
                          buttonType={ComptButtonType.OUTLINED}
                          disabled={props.isUpdating}
                          onClick={() => props.setIsOpen(false)}
                        >
                          Cancel
                        </ComptButton>
                        <ComptButton
                          buttonType={ComptButtonType.DESTRUCTIVE}
                          disabled={props.isUpdating}
                          onClick={props.formMethods.handleSubmit(props.onSubmitRejection)}
                          data-testid={props.buttonDataTestId}
                        >
                          Reject
                        </ComptButton>
                      </div>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
};

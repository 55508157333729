import React, {useState} from 'react';

// Types
import {ReviewTabType} from '@compt/types/stipend-expenses/expense-review';

// Hooks and Methods
import {formatCurrency} from '@compt/utils/international-helpers';

// Components
import {ReviewScoreButton} from './review-score-button';

interface ReviewCardProps {
  title: string;
  total_amount: number;
  remaining_amount: number;
  spent_amount: number;
  currency: string;
}

export const ReviewScoreCard = ({
  title,
  total_amount,
  remaining_amount,
  spent_amount,
  currency,
}: ReviewCardProps) => {
  const [activeTab, setActiveTab] = useState<ReviewTabType>(ReviewTabType.REMAINING);

  const amountToRender =
    activeTab === ReviewTabType.REMAINING
      ? remaining_amount
      : activeTab === ReviewTabType.SPENT
      ? spent_amount
      : total_amount;

  return (
    <div
      className="flex flex-col items-start p-4 gap-3 flex-1 rounded-xl 
      border border-gray-300 bg-white mb-4 min-w-[240px]"
    >
      <p className="label4 text-color-body2">{title}</p>
      <h3 className="heading1 text-color-heading">{formatCurrency(amountToRender, currency)}</h3>
      <div>
        <ReviewScoreButton
          activeTab={ReviewTabType.REMAINING}
          setActiveTab={setActiveTab}
          showActiveTab={activeTab === ReviewTabType.REMAINING}
        />
        <ReviewScoreButton
          activeTab={ReviewTabType.SPENT}
          setActiveTab={setActiveTab}
          showActiveTab={activeTab === ReviewTabType.SPENT}
        />
        <ReviewScoreButton
          activeTab={ReviewTabType.TOTAL}
          setActiveTab={setActiveTab}
          showActiveTab={activeTab === ReviewTabType.TOTAL}
        />
      </div>
    </div>
  );
};

/* eslint-disable  @typescript-eslint/no-explicit-any */
import {comptApiSlice, ComptRTKTags} from '@compt/app/services/api/api-slice';

// RTK Queries
import {MutationTrigger} from '@reduxjs/toolkit/dist/query/react/buildHooks';
import {
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
  FetchBaseQueryMeta,
  MutationDefinition,
} from '@reduxjs/toolkit/query';
import {extendedComptApiSlice as learningDevelopmentSlice} from './learning-development-slice';

// Types
import {LearningRequestStatus} from '@compt/types/learning-development/learning-request-status';
import {StipendExpense, StipendExpenseListResults} from '@compt/types/stipend-expense';

export type CreateStipendBody = Partial<StipendExpense> & {
  allotment_id: number;
  receipt_key: string;
  supporting_document_keys: string[];
  draft_id?: number;
};

export type StipendMutation = MutationTrigger<
  MutationDefinition<
    Partial<CreateStipendBody>,
    BaseQueryFn<
      string | FetchArgs,
      unknown,
      FetchBaseQueryError,
      NonNullable<unknown>,
      FetchBaseQueryMeta
    >,
    ComptRTKTags,
    StipendExpense,
    'api'
  >
>;

function getUpdateExpenseUrl(expense: Partial<StipendExpense>, isTaxCategorySet: boolean) {
  if (isTaxCategorySet) return `expenses/${expense.id}?is_tax_category_set=true`;
  return `expenses/${expense.id}/`;
}

/**
 * Add endpoints for stipend expenses (AKA claims, not to be confused with business expenses)
 */
const extendedComptApiSlice = comptApiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getStipendExpenses: builder.query<
      StipendExpenseListResults,
      {userId?: number; params?: Record<string, unknown> | null}
    >({
      providesTags: [ComptRTKTags.STIPEND_EXPENSES],
      query: ({userId, params}) => ({
        url: `users/${userId}/expenses`,
        params: {...params},
      }),
    }),
    getStipendExpense: builder.query<StipendExpense, string>({
      providesTags: [ComptRTKTags.STIPEND_EXPENSE],
      query: (expenseId) => `expenses/${expenseId}`,
    }),
    createStipendExpense: builder.mutation<StipendExpense, Partial<CreateStipendBody>>({
      query: (expense: Partial<CreateStipendBody>) => ({
        url: 'expenses',
        method: 'POST',
        body: expense,
      }),
      invalidatesTags: [
        ComptRTKTags.STIPEND_ALLOTMENTS,
        ComptRTKTags.STIPEND_EXPENSES,
        ComptRTKTags.DRAFT_EXPENSE_LIST,
      ],
    }),
    updateStipendExpense: builder.mutation<
      StipendExpense,
      {expense: Partial<StipendExpense>; isTaxCategorySet?: boolean}
    >({
      query: ({expense, isTaxCategorySet}) => ({
        url: getUpdateExpenseUrl(expense, !!isTaxCategorySet),
        method: 'PUT',
        body: expense,
      }),
      invalidatesTags: [
        ComptRTKTags.STIPEND_ALLOTMENTS,
        ComptRTKTags.STIPEND_EXPENSES,
        ComptRTKTags.STIPEND_EXPENSE,
        ComptRTKTags.LEARNING_DEVELOPMENT_PRE_APPROVAL_REQUEST,
      ],
      // Note: Please see documentation: https://redux-toolkit.js.org/rtk-query/usage/manual-cache-updates
      async onQueryStarted({expense}, {dispatch, queryFulfilled, getState}) {
        const state = getState() as any;
        // Get the API queries called when a request status is updated
        const queries = Object.values(state.api.queries) as any[];

        // Identify the latest getAllAdminPreApprovalRequests query, and check if it was called
        // with status filters applied
        const lastQuery = queries
          .filter((query: any) => {
            const matches =
              query.endpointName === 'getAllAdminPreApprovalRequests' &&
              query.status === 'fulfilled';

            return matches;
          })
          .pop();

        const hasStatusFilter = lastQuery?.originalArgs.params?.status__in;

        try {
          if (!hasStatusFilter) {
            // No filters - do the update and refresh the list
            await queryFulfilled;
            dispatch(
              learningDevelopmentSlice.util.invalidateTags([
                ComptRTKTags.LEARNING_DEVELOPMENT_PRE_APPROVAL_REQUEST_LIST,
              ]),
            );
          } else {
            // Has filters - do optimistic update without refresh
            const patchResult = dispatch(
              learningDevelopmentSlice.util.updateQueryData(
                'getAllAdminPreApprovalRequests',
                lastQuery.originalArgs,
                (draft: any) => {
                  const requestToUpdate = draft.results.find(
                    (request: any) => String(request.expense?.id) === String(expense.id),
                  );
                  if (requestToUpdate) {
                    if (!expense.status) return;
                    // TO-DO: Remove once PD models are separated from expenses
                    if (expense.status === 2) {
                      requestToUpdate.status = LearningRequestStatus.REIMBURSEMENT_APPROVED;
                    } else if (expense.status === 3) {
                      requestToUpdate.status = LearningRequestStatus.REIMBURSEMENT_REJECTED;
                    }
                  }
                },
              ),
            );

            try {
              await queryFulfilled;
            } catch {
              patchResult.undo();
            }
          }
        } catch {
          console.error('Error occurred while updating the request');
        }
      },
    }),
    deleteStipendExpense: builder.mutation<StipendExpense, StipendExpense>({
      invalidatesTags: [ComptRTKTags.STIPEND_ALLOTMENTS, ComptRTKTags.STIPEND_EXPENSES],
      query: (expense: StipendExpense) => ({
        url: `expenses/${expense.id}`,
        method: 'DELETE',
      }),
    }),
  }),
});

export const {
  useGetStipendExpensesQuery,
  useGetStipendExpenseQuery,
  useCreateStipendExpenseMutation,
  useUpdateStipendExpenseMutation,
  useDeleteStipendExpenseMutation,
} = extendedComptApiSlice;

import React from 'react';

// Types
import comptColors from '@compt/styles/compt-colors';

// Hooks and methods
import {twMerge} from 'tailwind-merge';
import {useDateRangePickerContext} from './date-range-picker-context';

interface DayProps {
  day: Date;
  inCurrentMonth: boolean;
  isLeftEdge: boolean;
  isRightEdge: boolean;
}

export const CalendarDay = (props: DayProps) => {
  const {startDate, endDate, setStartDate, setEndDate} = useDateRangePickerContext();

  const isStartDate = isSameDay(props.day, startDate);
  const isEndDate = isSameDay(props.day, endDate);
  const isInRangeDate = isInRange(props.day, startDate, endDate);
  const startDateEqualsEndDate = isSameDay(startDate, endDate);
  const endDateSelected = !!endDate;

  const selected = isStartDate || isEndDate || isInRange;

  function handleDayClick(day: Date) {
    if (!startDate || (startDate && endDate)) {
      setStartDate(day);
      setEndDate(null);
    } else {
      if (day < startDate) {
        setEndDate(startDate);
        setStartDate(day);
      } else {
        setEndDate(day);
      }
    }
  }

  function getBackgroundColor() {
    if (
      startDateEqualsEndDate ||
      (isStartDate && props.isRightEdge) ||
      (isEndDate && props.isLeftEdge)
    )
      return 'transparent';

    if ((isStartDate && endDateSelected) || (isInRangeDate && props.isLeftEdge))
      return `linear-gradient(to left, ${comptColors.base[50]} 20%, ${comptColors.base.white} 100%)`;

    if (isEndDate || (isInRangeDate && props.isRightEdge)) {
      return `linear-gradient(to right, ${comptColors.base[50]} 20%, ${comptColors.base.white} 100%)`;
    }

    if (isInRangeDate) return comptColors.base[50];

    return 'transparent';
  }

  function getTextColor(selected: boolean, isCurrentMonth: boolean) {
    if (selected) return comptColors.base.white;

    return isCurrentMonth ? comptColors.gray[900] : comptColors.gray[200];
  }

  if (!props.inCurrentMonth) {
    return <div />;
  }

  return (
    <div
      className="flex justify-center"
      style={{
        color: `linear-gradient(to left, ${comptColors.base[50]} 50%, blue 100%)`,
        background: getBackgroundColor(),
      }}
    >
      <button
        className={twMerge(`label4 px-2 py-2 h-12 w-12 flex justify-center rounded-full
        items-center cursor-pointer 
        ${isInRangeDate && 'bg-surface-secondary-hover'}
        ${(isStartDate || isEndDate) && 'bg-stroke-primary'} 
        ${!selected && 'hover:bg-surface-secondary-hover'}`)}
        onClick={() => handleDayClick(props.day)}
        disabled={!props.inCurrentMonth}
        style={{
          color: getTextColor(isStartDate || isEndDate, props.inCurrentMonth),
        }}
      >
        {new Date(props.day).getDate()}
      </button>
    </div>
  );
};

function isSameDay(dayOne: Date | null, dayTwo: Date | null) {
  if (!dayOne || !dayTwo) return false;

  return (
    dayOne.getFullYear() === dayTwo.getFullYear() &&
    dayOne.getMonth() === dayTwo.getMonth() &&
    dayOne.getDate() === dayTwo.getDate()
  );
}

function isInRange(date: Date, start: Date | null, end: Date | null) {
  if (!start || !end) return false;
  return date > start && date < end;
}

import React from 'react';

// Types
import {DraftExpense} from './draft-expense.types';

// Hooks and methods
import {DATE_FORMAT_OPTION, formattedDate} from '@compt/utils/date-helpers';

// Components
import {ComptPill} from '@compt/common/forms/compt-pill/compt-pill';
import {SimpleAction, SimpleActionMenu} from '@compt/common/compt-table/simple-action-menu';

export class DraftExpensesController {
  static getColumnDefinition(
    onMenuClicked: (draft: DraftExpense, action: 'REVIEW' | 'DELETE') => void,
  ) {
    return {
      received_on: {
        id: 'received_on',
        name: 'Received on',
        selector: (draft: DraftExpense) => (
          <p data-tag="allowRowEvents">
            {formattedDate(draft.created_on, DATE_FORMAT_OPTION['month dd yyyy'])}
          </p>
        ),
        order: 1,
        grow: 1,
        omit: false,
      },
      source: {
        id: 'source',
        name: 'Source',
        selector: (draft: DraftExpense) => <p data-tag="allowRowEvents">{draft.source}</p>,
        order: 2,
        grow: 5,
        omit: false,
      },
      status: {
        id: 'status',
        name: 'Status',
        selector: () => (
          <ComptPill pillType={'gray'} data-tag="allowRowEvents">
            Draft
          </ComptPill>
        ),
        grow: 0.5,
        order: 3,
        omit: false,
      },
      action: {
        id: 'action',
        ignoreRowClick: true,
        compact: true,
        selector: (draft: DraftExpense) => {
          const actions: Array<SimpleAction<DraftExpense>> = [
            {
              label: 'Review',
              onClick: (draft) => onMenuClicked(draft, 'REVIEW'),
            },
            {
              label: 'Delete',
              onClick: (draft) => onMenuClicked(draft, 'DELETE'),
              textColor: 'text-color-error',
            },
          ];

          return <SimpleActionMenu relatedActionItem={draft} actions={actions} />;
        },
        grow: 0,
        order: 3,
        disableRemoval: true,
      },
    };
  }
}

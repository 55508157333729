import React, {Dispatch, SetStateAction, useContext} from 'react';
// RTK queries
import {useUpdateBusinessExpenseReportMutation} from '@compt/app/services/api/business-expense-reports-slice';

// Hooks and methods
import {useNavigate} from 'react-router-dom';
import {BusinessExpensePageContext} from '../business-expense-page.context';
import {triggerCustomToast} from '@compt/common/compt-toaster/compt-toaster';

// Types
import {BusinessExpenseReport} from '@compt/types/business-expenses/business-expense-report';
import {BusinessExpenseReportStatus} from '@compt/types/business-expenses/business-expense-report-statuses';

// Components
import {ComptButton, ComptButtonType} from '@compt/common/compt-button/compt-button';
import {
  ComptConfirmationModal,
  ModalType,
} from '@compt/common/compt-confirmation-modal/compt-confirmation.modal';

interface SubmitExpenseReportModalProps {
  reportInfo: BusinessExpenseReport;
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
}

export const SubmitExpenseReportModal = (props: SubmitExpenseReportModalProps) => {
  const {open, setOpen, reportInfo} = props;
  const {setErrorRows} = useContext(BusinessExpensePageContext);

  const navigate = useNavigate();

  const [updateBusinessExpenseReport] = useUpdateBusinessExpenseReportMutation();

  const handleSubmitExpenseReport = () => {
    const reportToSubmit = {
      ...reportInfo,
      status: BusinessExpenseReportStatus.SUBMITTED,
    };

    updateBusinessExpenseReport(reportToSubmit).then((response) => {
      if ('error' in response) {
        triggerCustomToast('error', 'There was a problem submitting report');

        if ('data' in response.error) {
          const errorData = response.error.data as {errors: {number: string[]}[]};
          const {errors} = errorData;
          setErrorRows(() => errors[0]);
        }

        setOpen(false);
        return;
      }
      setOpen(false);
      triggerCustomToast('success', 'Successfully submitted report');
      navigate('/business-expenses');
    });
  };

  return (
    <ComptConfirmationModal
      modalType={ModalType.PRIMARY}
      title="Submit All Expenses?"
      content="Are you sure you want to submit all expenses in this expense report for approval?
      Once you submit, you may no longer add or edit expenses."
      open={open}
      setOpen={() => setOpen(false)}
    >
      <div className="flex flex-row space-x-2 justify-end">
        <ComptButton buttonType={ComptButtonType.OUTLINED} onClick={() => setOpen(false)}>
          Cancel
        </ComptButton>
        <ComptButton buttonType={ComptButtonType.PRIMARY} onClick={handleSubmitExpenseReport}>
          Submit
        </ComptButton>
      </div>
    </ComptConfirmationModal>
  );
};

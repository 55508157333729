// The only valid statuses to send to API endpoints
export enum LearningRequestStatus {
  PRE_APPROVAL_REQUESTED = 'PRE_APPROVAL_REQUESTED',
  PARTIALLY_APPROVED = 'PARTIALLY_APPROVED',
  PRE_APPROVAL_REJECTED = 'PRE_APPROVAL_REJECTED',
  PRE_APPROVED = 'PRE_APPROVED',
  REIMBURSEMENT_REQUESTED = 'REIMBURSEMENT_REQUESTED',
  REIMBURSEMENT_REJECTED = 'REIMBURSEMENT_REJECTED',
  REIMBURSEMENT_APPROVED = 'REIMBURSEMENT_APPROVED',
  REIMBURSED = 'REIMBURSED',
}
